import React from 'react';
import PropTypes from 'prop-types';
import Linkify from 'linkifyjs/react';

export default class ChildProfileView extends React.Component {
  static propTypes = {
    child: PropTypes.object.isRequired
  }

  render () {
    return (
      <div className="c-child-profile-view">
        <div className="c-icon-child" />
        <div className="c-child-profile-view-details">
          <div className="c-child-profile-view-details__name">
            {this.props.child.firstName}
            <span className="c-child-profile-view-details__gender">{this.props.child.gender}</span>
          </div>
          <div className="c-child-profile-view-details__dob">
            {this.props.child.dateOfBirth.format('MMMM D, YYYY')}
            <span className="c-child-profile-view-details__age">{`(${this.props.child.currentAge()})`}</span>
          </div>
          <div className="c-child-profile-view-details__note">
            <Linkify>{this.props.child.note}</Linkify>
          </div>
        </div>
      </div>
    );
  }
}
