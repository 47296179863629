import {handleLoading} from 'reducers/parent/loading';
import {
  PASSWORD_CHANGE_REQUEST,
  PASSWORD_CHANGE_SUCCESS,
  PASSWORD_CHANGE_FAILURE,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAILURE
} from 'constants/session';
import {
  PROFILE_FAILURE,
  PROFILE_REQUEST,
  PROFILE_SUCCESS,
  PROFILE_OTHER_FAILURE,
  PROFILE_OTHER_REQUEST,
  PROFILE_OTHER_SUCCESS,
  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_FAILURE
} from 'constants/profile';
import {
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGIN_REQUEST
} from 'constants/login';
import {
  CONSTANTS_REQUEST,
  CONSTANTS_SUCCESS,
  CONSTANTS_FAILURE,
  LOAD_NOTIFICATION_SETTINGS_REQUEST,
  LOAD_NOTIFICATION_SETTINGS_SUCCESS,
  LOAD_NOTIFICATION_SETTINGS_FAILURE,
  SAVE_NOTIFICATION_SETTINGS_REQUEST,
  SAVE_NOTIFICATION_SETTINGS_SUCCESS,
  SAVE_NOTIFICATION_SETTINGS_FAILURE
} from 'constants/settings';
import {
  PROVIDER_DELETE_AVAILABILITY_SUCCESS,
  PROVIDER_DELETE_AVAILABILITY_REQUEST,
  PROVIDER_DELETE_AVAILABILITY_FAILURE,
  PROVIDER_ADD_AVAILABILITY_SUCCESS,
  PROVIDER_ADD_AVAILABILITY_REQUEST,
  PROVIDER_ADD_AVAILABILITY_FAILURE,
  PROVIDER_ADD_UNAVAILABILITY_SUCCESS,
  PROVIDER_ADD_UNAVAILABILITY_REQUEST,
  PROVIDER_ADD_UNAVAILABILITY_FAILURE,
  PROVIDER_COVERAGE_ZONES_SUCCESS,
  PROVIDER_COVERAGE_ZONES_REQUEST,
  PROVIDER_COVERAGE_ZONES_FAILURE,
  PROVIDER_ALL_COVERAGE_ZONES_SUCCESS,
  PROVIDER_ALL_COVERAGE_ZONES_REQUEST,
  PROVIDER_ALL_COVERAGE_ZONES_FAILURE,
  AVAILABILITIES_REQUEST,
  AVAILABILITIES_SUCCESS,
  AVAILABILITIES_FAILURE,
  UNAVAILABILITIES_REQUEST,
  UNAVAILABILITIES_SUCCESS,
  UNAVAILABILITIES_FAILURE
} from 'constants/sitter/availability';
import {
  PAST_BOOKINGS_REQUEST,
  PAST_BOOKINGS_SUCCESS,
  PAST_BOOKINGS_FAILURE,
  FUTURE_BOOKINGS_REQUEST,
  FUTURE_BOOKINGS_SUCCESS,
  FUTURE_BOOKINGS_FAILURE,
  LOAD_BOOKING_DETAILS_REQUEST,
  LOAD_BOOKING_DETAILS_SUCCESS,
  LOAD_BOOKING_DETAILS_FAILURE,
  CONFIRM_BOOKING_REQUEST,
  CONFIRM_BOOKING_SUCCESS,
  CONFIRM_BOOKING_FAILURE,
  LOAD_PARENT_SITTER_NOTES_REQUEST,
  LOAD_PARENT_SITTER_NOTES_SUCCESS,
  LOAD_PARENT_SITTER_NOTES_FAILURE,
  PARENT_PHONE_REQUEST,
  PARENT_PHONE_SUCCESS,
  PARENT_PHONE_FAILURE
} from 'constants/sitter/bookings';
import {
  INTERESTED_SEARCH_FAILURE,
  INTERESTED_SEARCH_REQUEST,
  INTERESTED_SEARCH_SUCCESS,
  RESPOND_ALL_FAILURE,
  RESPOND_ALL_REQUEST,
  RESPOND_ALL_SUCCESS,
  RESPOND_INTERESTED_FAILURE,
  RESPOND_INTERESTED_REQUEST,
  RESPOND_INTERESTED_SUCCESS,
  RESPOND_BOOKING_REQUEST,
  RESPOND_BOOKING_SUCCESS,
  RESPOND_BOOKING_FAILURE,
  RESPOND_ONE_TIME_FAILURE,
  RESPOND_ONE_TIME_REQUEST,
  RESPOND_ONE_TIME_SUCCESS,
  RESPOND_RECURRING_FAILURE,
  RESPOND_RECURRING_REQUEST,
  RESPOND_RECURRING_SUCCESS,
  LOAD_ALL_SEARCHES_FAILURE,
  LOAD_ALL_SEARCHES_REQUEST,
  LOAD_ALL_SEARCHES_SUCCESS,
  LOAD_INTERESTED_SEARCHES_FAILURE,
  LOAD_INTERESTED_SEARCHES_REQUEST,
  LOAD_INTERESTED_SEARCHES_SUCCESS,
  LOAD_ONE_TIME_SEARCHES_FAILURE,
  LOAD_ONE_TIME_SEARCHES_REQUEST,
  LOAD_ONE_TIME_SEARCHES_SUCCESS,
  LOAD_RECURRING_SEARCHES_FAILURE,
  LOAD_RECURRING_SEARCHES_REQUEST,
  LOAD_RECURRING_SEARCHES_SUCCESS
} from 'constants/sitter/search';
import {
  STREAM_TOKEN_REQUEST,
  STREAM_TOKEN_SUCCESS,
  STREAM_TOKEN_FAILURE
} from 'constants/stream';
import {
  REGISTRATION_FAILURE,
  REGISTRATION_REQUEST,
  REGISTRATION_SUCCESS,
} from 'constants/parent/signUpFlow';
import {
  LOAD_REVIEWS_REQUEST,
  LOAD_REVIEWS_SUCCESS,
  LOAD_REVIEWS_FAILURE
} from 'constants/sitter/reviews';
import {
  HYDRO,
  HYDRO_COMPLETE
} from 'constants/session';

export function getDefaultState () {
  return {
    constants: false,
    login: false,
    registration: false
  };
}

const loadingTypes = {
  hydro: {
    [HYDRO]: true,
    [HYDRO_COMPLETE]: false,
  },
  profile: {
    [PROFILE_REQUEST]: true,
    [PROFILE_SUCCESS]: false,
    [PROFILE_FAILURE]: false
  },
  profileOther: {
    [PROFILE_OTHER_REQUEST]: true,
    [PROFILE_OTHER_SUCCESS]: false,
    [PROFILE_OTHER_FAILURE]: false
  },
  profileUpdate: {
    [PROFILE_UPDATE_REQUEST]: true,
    [PROFILE_UPDATE_SUCCESS]: false,
    [PROFILE_UPDATE_FAILURE]: false
  },
  availability: {
    [AVAILABILITIES_REQUEST]: true,
    [AVAILABILITIES_SUCCESS]: false,
    [AVAILABILITIES_FAILURE]: false
  },
  unavailability: {
    [UNAVAILABILITIES_REQUEST]: true,
    [UNAVAILABILITIES_SUCCESS]: false,
    [UNAVAILABILITIES_FAILURE]: false
  },
  constants: {
    [CONSTANTS_REQUEST]: true,
    [CONSTANTS_SUCCESS]: false,
    [CONSTANTS_FAILURE]: false
  },
  loadNotifications: {
    [LOAD_NOTIFICATION_SETTINGS_REQUEST]: true,
    [LOAD_NOTIFICATION_SETTINGS_SUCCESS]: false,
    [LOAD_NOTIFICATION_SETTINGS_FAILURE]: false
  },
  saveNotifications: {
    [SAVE_NOTIFICATION_SETTINGS_REQUEST]: true,
    [SAVE_NOTIFICATION_SETTINGS_SUCCESS]: false,
    [SAVE_NOTIFICATION_SETTINGS_FAILURE]: false
  },
  deleteAvailability: {
    [PROVIDER_DELETE_AVAILABILITY_REQUEST]: true,
    [PROVIDER_DELETE_AVAILABILITY_SUCCESS]: false,
    [PROVIDER_DELETE_AVAILABILITY_FAILURE]: false
  },
  login: {
    [LOGIN_REQUEST]: true,
    [LOGIN_SUCCESS]: false,
    [LOGIN_FAILURE]: false
  },
  registration: {
    [REGISTRATION_REQUEST]: true,
    [REGISTRATION_SUCCESS]: false,
    [REGISTRATION_FAILURE]: false
  },
  addAvailability: {
    [PROVIDER_ADD_AVAILABILITY_REQUEST]: true,
    [PROVIDER_ADD_AVAILABILITY_SUCCESS]: false,
    [PROVIDER_ADD_AVAILABILITY_FAILURE]: false
  },
  addUnavailability: {
    [PROVIDER_ADD_UNAVAILABILITY_REQUEST]: true,
    [PROVIDER_ADD_UNAVAILABILITY_SUCCESS]: false,
    [PROVIDER_ADD_UNAVAILABILITY_FAILURE]: false
  },
  providerCoverageZones: {
    [PROVIDER_COVERAGE_ZONES_REQUEST]: true,
    [PROVIDER_COVERAGE_ZONES_SUCCESS]: false,
    [PROVIDER_COVERAGE_ZONES_FAILURE]: false
  },
  providerAllCoverageZones: {
    [PROVIDER_ALL_COVERAGE_ZONES_REQUEST]: true,
    [PROVIDER_ALL_COVERAGE_ZONES_SUCCESS]: false,
    [PROVIDER_ALL_COVERAGE_ZONES_FAILURE]: false
  },
  loadReviews: {
    [LOAD_REVIEWS_REQUEST]: true,
    [LOAD_REVIEWS_SUCCESS]: false,
    [LOAD_REVIEWS_FAILURE]: false
  },
  interestedSearch: {
    [INTERESTED_SEARCH_REQUEST]: true,
    [INTERESTED_SEARCH_FAILURE]: false,
    [INTERESTED_SEARCH_SUCCESS]: false
  },
  passwordChange: {
    [PASSWORD_CHANGE_REQUEST]: true,
    [PASSWORD_CHANGE_SUCCESS]: false,
    [PASSWORD_CHANGE_FAILURE]: false
  },
  passwordReset: {
    [PASSWORD_RESET_REQUEST]: true,
    [PASSWORD_RESET_SUCCESS]: false,
    [PASSWORD_RESET_FAILURE]: false
  },
  respondAll: {
    [RESPOND_ALL_REQUEST]: true,
    [RESPOND_ALL_FAILURE]: false,
    [RESPOND_ALL_SUCCESS]: false
  },
  respondInterested: {
    [RESPOND_INTERESTED_REQUEST]: true,
    [RESPOND_INTERESTED_FAILURE]: false,
    [RESPOND_INTERESTED_SUCCESS]: false
  },
  respondBooking: {
    [RESPOND_BOOKING_REQUEST]: true,
    [RESPOND_BOOKING_SUCCESS]: false,
    [RESPOND_BOOKING_FAILURE]: false
  },
  respondOneTime: {
    [RESPOND_ONE_TIME_REQUEST]: true,
    [RESPOND_ONE_TIME_FAILURE]: false,
    [RESPOND_ONE_TIME_SUCCESS]: false
  },

  respondRecurring: {
    [RESPOND_RECURRING_REQUEST]: true,
    [RESPOND_RECURRING_FAILURE]: false,
    [RESPOND_RECURRING_SUCCESS]: false
  },

  interestedSearches: {
    [LOAD_INTERESTED_SEARCHES_REQUEST]: true,
    [LOAD_INTERESTED_SEARCHES_SUCCESS]: false,
    [LOAD_INTERESTED_SEARCHES_FAILURE]: false
  },

  recurringSearches: {
    [LOAD_RECURRING_SEARCHES_REQUEST]: true,
    [LOAD_RECURRING_SEARCHES_SUCCESS]: false,
    [LOAD_RECURRING_SEARCHES_FAILURE]: false
  },

  allSearches: {
    [LOAD_ALL_SEARCHES_REQUEST]: true,
    [LOAD_ALL_SEARCHES_SUCCESS]: false,
    [LOAD_ALL_SEARCHES_FAILURE]: false
  },
  confirmBooking: {
    [CONFIRM_BOOKING_REQUEST]: true,
    [CONFIRM_BOOKING_SUCCESS]: false,
    [CONFIRM_BOOKING_FAILURE]: false
  },
  oneTimeSearches: {
    [LOAD_ONE_TIME_SEARCHES_REQUEST]: true,
    [LOAD_ONE_TIME_SEARCHES_SUCCESS]: false,
    [LOAD_ONE_TIME_SEARCHES_FAILURE]: false
  },
  pastBookings: {
    [PAST_BOOKINGS_REQUEST]: true,
    [PAST_BOOKINGS_SUCCESS]: false,
    [PAST_BOOKINGS_FAILURE]: false
  },
  parentPhone: {
    [PARENT_PHONE_REQUEST]: true,
    [PARENT_PHONE_SUCCESS]: false,
    [PARENT_PHONE_FAILURE]: false
  },
  futureBookings: {
    [FUTURE_BOOKINGS_REQUEST]: true,
    [FUTURE_BOOKINGS_SUCCESS]: false,
    [FUTURE_BOOKINGS_FAILURE]: false
  },
  bookingDetails: {
    [LOAD_BOOKING_DETAILS_REQUEST]: true,
    [LOAD_BOOKING_DETAILS_SUCCESS]: false,
    [LOAD_BOOKING_DETAILS_FAILURE]: false
  },
  bookingNotes: {
    [LOAD_PARENT_SITTER_NOTES_REQUEST]: true,
    [LOAD_PARENT_SITTER_NOTES_SUCCESS]: false,
    [LOAD_PARENT_SITTER_NOTES_FAILURE]: false
  },
  streamToken: {
    [STREAM_TOKEN_REQUEST]: true,
    [STREAM_TOKEN_SUCCESS]: false,
    [STREAM_TOKEN_FAILURE]: false
  }
};

export default function (state = getDefaultState(), action) {
  return {
    ...state,
    ...handleLoading(loadingTypes, action)
  };
}
