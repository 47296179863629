import React from 'react';

import FormField from 'forms/controls/FormField';

export default class NameInputField extends React.Component {
  render () {
    return (
      <div>
        <FormField
            fields={[
              {
                name: 'firstLastName',
                placeholder: 'First and Last Name',
                type: 'text'
              }
            ]}
            icon="Person"
        />
      </div>
    );
  }
}
