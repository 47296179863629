export function set (key, value) {
  if (!window.localStorage) {
    return;
  }
  return localStorage.setItem(key, value);
}

export function get (key) {
  if (!window.localStorage) {
    return true;
  }
  return localStorage.getItem(key);
}

export function unset (key) {
  if (!window.localStorage) {
    return;
  }
  return localStorage.removeItem(key);
}
