import BaseProfile from 'models/Profile';

export default class Profile extends BaseProfile {
  constructor (json = {}) {
    super(json);
    this.summary = json.summary;
    this.videoUrl = json.video_url;
    this.broadcastUrl = json.broadcast_url;
    this.verifications = json.verification_list;
    this.skills = json.skill_list;
    this.reviewCount = json.reviews_count;
    this.referenceCount = json.references_count;
    this.authenticationToken = json.authentication_token;
  }
}
