import React from 'react';
import PropTypes from 'prop-types';

import PhoneNumberView from 'components/sitter/PhoneNumberView';

export default class ContactView extends React.Component {
  static propTypes = {
    contact: PropTypes.object.isRequired
  }

  render () {
    return (
      <div className="c-contact-view">
        <div className="c-icon-contact" />
        <div className="c-contact-view-details">
          <div className="c-contact-view-details__name">{this.props.contact.firstName}</div>
          {this.props.contact.relationship && (
            <div className="c-contact-view-details__relationship">{this.props.contact.relationship}</div>
          )}
          <div className="c-contact-view-details-phone-numbers">
            <div className="c-contact-view-details__phone-numbers">
              {this.props.contact.phoneNumbers.map((number, key) => (
                <PhoneNumberView
                    className="c-phone-number"
                    {...{key}}
                >{number}</PhoneNumberView>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
