import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {track} from 'utils/track';
import {setTitle as setTitleAction} from 'actions/navigation';
import {passwordReset as passwordResetSubmission} from 'submissions/session';
import {resetPassword as resetPasswordAction} from 'actions/session';
import PasswordResetForm from 'forms/PasswordReset';

export class PasswordReset extends React.Component {
  static propTypes = {
    setTitle: PropTypes.func.isRequired,
    resetPassword: PropTypes.func.isRequired
  };

  componentDidMount () {
    track('Password Reset Page');
    this.props.setTitle('Request Password Reset');
  }

  render () {
    return ( /* eslint-disable camelcase */
      <div className="o-layout">
        <div className="o-layout__item u-1/2 u-push-1/4">
          <h1 className="c-h1 u-center-text c-search-header">{'Request Password Reset'}</h1>
          <p className="u-center-text">{'Enter your email below to receive a reset link.'}</p>
          <PasswordResetForm
              initialValues={{}}
              onSubmit={passwordResetSubmission(this.props.resetPassword)}
          />
        </div>
      </div>
    ); /* eslint-enable camelcase */
  }
}

export const makeMapStateToProps = () => {
  return () => {
    return {
    };
  };
};

const mapDispatchToProps = {
  setTitle: setTitleAction,
  resetPassword: resetPasswordAction,
};

export default connect(makeMapStateToProps, mapDispatchToProps)(PasswordReset);
