import React from 'react';
import PropTypes from 'prop-types';

import ChildProfileView from 'components/sitter/ChildProfileView';

export default class ProfileChildrenView extends React.Component {
  static propTypes = {
    children: PropTypes.array.isRequired
  }

  render () {
    return (
      <div className="c-profile-children-view">
        <h4>{'Children'}</h4>
        {this.props.children.map((child, key) => (
          <ChildProfileView {...{key, child}} />
        ))}
      </div>
    );
  }
}
