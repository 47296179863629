import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
  stopSubmit as stopSubmitAction,
  change as formChangeAction
} from 'redux-form';
import {
  getAuthToken,
  getUser,
} from 'selectors/session';
import {getNotificationSettings} from 'selectors/settings';
import {track} from 'utils/track';
import SettingsForm from 'forms/sitter/Settings';
import {setTitle as setTitleAction} from 'actions/navigation';
import {
  updateProfile as updateProfileAction,
  loadProfile as loadProfileAction
} from 'actions/profile';
import {
  loadNotificationSettings as loadNotificationSettingsAction,
  saveNotificationSettings as saveNotificationSettingsAction
} from 'actions/settings';
import {
  closeModal as closeModalAction,
  openModal as openModalAction
} from 'actions/modal';

export class Settings extends React.Component {
  static propTypes = {
    notificationSettings: PropTypes.object.isRequired,
    loadNotificationSettings: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    saveNotificationSettings: PropTypes.func.isRequired,
    authToken: PropTypes.string.isRequired,
    formChange: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    setTitle: PropTypes.func.isRequired,
    updateProfile: PropTypes.func.isRequired
  };

  constructor (props) {
    super(props);
    this.handleSubmit = this.onSubmit();
  }

  notificationSettings = [
    {
      title: 'Daily Open Request Emails',
      key: 'requestEmails'
    }
  ];

  componentDidMount () {
    track('Provider Settings Page');
    this.props.setTitle('Settings');
    if (this.props.authToken) {
      this.props.loadNotificationSettings(this.props.authToken);
    }
  }

  componentWillReceiveProps (newProps) {
    if (newProps.authToken && newProps.authToken !== this.props.authToken) {
      this.props.loadNotificationSettings(newProps.authToken);
    }
  }

  handlePasswordChange (passwordChange) {
    if ((passwordChange && passwordChange.password)) {
      this.props.updateProfile(this.props.authToken, {
        firstName: this.props.user.first_name,
        lastName: this.props.user.last_name,
        email: this.props.user.email,
        phoneNumber: this.props.user.phoneNumber,
        password: (passwordChange && passwordChange.password)
      });
      this.props.formChange('settings', 'passwordChange.password', '');
      this.props.formChange('settings', 'passwordChange.passwordConfirm', '');
    }
  }

  handleNotificationPreferencesChange (notificationPreferences) {
    const invertedOptions = Object.keys(notificationPreferences).reduce((p, c) => ({...p, [c]: !notificationPreferences[c] ? 'yes' : 'no'}), {});
    const options = {
      opt_out_open_requests_email: invertedOptions.requestEmails, // eslint-disable-line camelcase
      opt_out_email: invertedOptions.email, // eslint-disable-line camelcase
      opt_out_push: invertedOptions.push, // eslint-disable-line camelcase
      opt_out_sms: invertedOptions.sms // eslint-disable-line camelcase
    };
    this.props.saveNotificationSettings(this.props.authToken, options);
  }

  onSubmit () {
    return ({notificationPreferences, passwordChange}) => {
      this.handlePasswordChange(passwordChange);
      this.handleNotificationPreferencesChange(notificationPreferences);
    };
  }

  render () {
    if (!this.props.user || !this.props.authToken) {
      return null;
    }
    const initialValues = {
      notificationPreferences: this.props.notificationSettings,
    };
    return (
      <div className="o-layout">
        <div className="o-layout__item u-1/1">
          <SettingsForm
              onSubmit={this.handleSubmit}
              settings={this.notificationSettings}
              {...{initialValues}}
          />
        </div>
      </div>
    );
  }
}

export function makeMapStateToProps () {
  return (state, ownProps) => {
    return {
      user: getUser(state, ownProps),
      authToken: getAuthToken(state, ownProps),
      notificationSettings: getNotificationSettings(state, ownProps),
    };
  };
}

const mapDispatchToProps = {
  updateProfile: updateProfileAction,
  setTitle: setTitleAction,
  loadNotificationSettings: loadNotificationSettingsAction,
  saveNotificationSettings: saveNotificationSettingsAction,
  openModal: openModalAction,
  stopSubmit: stopSubmitAction,
  closeModal: closeModalAction,
  formChange: formChangeAction,
  loadProfile: loadProfileAction
};

export default connect(makeMapStateToProps, mapDispatchToProps)(Settings);
