export const REMOVE_AUTH_TOKEN = 'SESSION_REMOVE_AUTH_TOKEN';
export const SET_AUTH_TOKEN = 'SESSION_SET_AUTH_TOKEN';
export const HYDRO = 'STORE_HYDRO';
export const HYDRO_COMPLETE = 'STORE_HYDRO_COMPLETE';
export const PASSWORD_RESET_REQUEST = 'SESSION_PASSWORD_RESET_REQUEST';
export const PASSWORD_RESET_SUCCESS = 'SESSION_PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAILURE = 'SESSION_PASSWORD_RESET_FAILURE';
export const PASSWORD_CHANGE_REQUEST = 'SESSION_PASSWORD_CHANGE_REQUEST';
export const PASSWORD_CHANGE_SUCCESS = 'SESSION_PASSWORD_CHANGE_SUCCESS';
export const PASSWORD_CHANGE_FAILURE = 'SESSION_PASSWORD_CHANGE_FAILURE';
