import {
  MODAL_CLOSE,
  MODAL_OPEN
} from 'constants/modal';

export function getDefaultState () {
  return {
    classes: {},
    component: null,
    isModalOpen: false,
    title: '',
    options: {}
  };
}

export default function (state = getDefaultState(), action) {
  if (action.type === MODAL_OPEN) {
    const {
      component,
      options,
      title,
      classes
    } = action;
    return {
      ...state,
      classes,
      component,
      isModalOpen: true,
      options,
      title
    };
  }
  if (action.type === MODAL_CLOSE) {
    return {
      ...state,
      classes: {},
      component: null,
      isModalOpen: false,
      title: '',
      options: {}
    };
  }
  return state;
}
