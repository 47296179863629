import moment from 'moment';

import Collection from 'models/Collection';
import SitterProfile from 'models/sitter/Profile';

export default class Note {
  static withTrustedBody (body) {
    let note = new Note();
    note.body = body;
    note.author = new SitterProfile({first_name: 'Trusted'}); // eslint-disable-line camelcase
    note.isTrusted = true;
    return note;
  }

  constructor (json = {}) {
    this.body = json.body || '';
    this.author = new SitterProfile(json.sitter);
    this.isTrusted = false;
    this.createdAt = json.created_at ? moment(json.created_at) : moment();
  }
}

export const Notes = Collection(Note, 'parent_sitter_notes');
