import React from 'react';
import { connect } from 'react-redux'
import {
  reduxForm,
  FormSection,
  formValueSelector
} from 'redux-form';
import PropTypes from 'prop-types';

import CoverageZonesField from 'forms/fields/CoverageZonesPicker';
import TimePickerField from 'forms/fields/TimePicker';
import SaveButton from 'forms/fields/SaveButton';
import OptionsField from 'forms/fields/OptionsSelect';
import {
  validateFields
} from '../../utils/formValidations';
import {schedule as validateSchedule} from '../../utils/formValidations';

export class AvailabilityAdd extends React.Component {
  static propTypes = {
    onFormChange: PropTypes.func.isRequired,
    startBounds: PropTypes.object.isRequired,
    endBounds: PropTypes.object.isRequired,
    showCoverage: PropTypes.bool.isRequired,
    submitDisabled: PropTypes.bool.isRequired,
    unavailabilityEnabled: PropTypes.bool.isRequired
  }

  availabilityOptions () {
    return [
      {label: '-- Please Select --', value: 'select_none'},
      {label: 'Available for bookings', value: 'select_unavailable'},
      {label: 'Not Available for bookings', value: 'select_available'}
    ];
  }

  availabilityOptionsNormalizer (value) {
    if (value === 'select_available') {
      return true
    } else if (value === 'select_unavailable') {
      return false
    } else {
      return null
    }
  }

  render () {
    const defaultZones = this.props.sitterCoverageZones.map((zone) => {
      return zone.coverage_zone;
    }).join(', ')

    return (
      <form onSubmit={this.props.handleSubmit}>
        <div className="c-fields">
          {this.props.unavailabilityEnabled ? (
            <FormSection name="schedule">
              <OptionsField
                  label="Available"
                  name="isUnavailable"
                  normalize={this.availabilityOptionsNormalizer}
                  onFormChange={this.props.onFormChange}
                  options={this.availabilityOptions()}
              />
            </FormSection>
          ) : null}
          <FormSection name="schedule">
            <TimePickerField
                isEditable
                label="Start"
                name="start"
                onFormChange={this.props.onFormChange}
                {...this.props.startBounds}
            />
            <TimePickerField
                isEditable
                label="End"
                name="end"
                onFormChange={this.props.onFormChange}
                {...this.props.endBounds}
            />
          </FormSection>
          {this.props.showCoverage ? (
            <div>
              <FormSection name="schedule">
                <div className="o-layout__item u-1/5 u-right-text">
                  <label className="c-time-picker-label">{'Default Zones'}</label>
                </div>
                <div className="o-layout__item u-4/5">{defaultZones}</div>
              </FormSection>
              <FormSection name="schedule">
                <CoverageZonesField
                    coverageZones={this.props.coverageZones}
                    isEditable
                />
              </FormSection>
            </div>
          ) : null}
          <SaveButton disabled={this.props.submitDisabled}/>
        </div>
      </form>
    );
  }
}

let AvailabilityAddForm = reduxForm({
  form: 'availabilityAdd',
  enableReinitialize: true,
  validate: validateFields({
    schedule: validateSchedule
  })
})(AvailabilityAdd);

const selector = formValueSelector('availabilityAdd')
AvailabilityAddForm = connect(
  (state, props) => {
    const isUnavailable = selector(state, 'schedule.isUnavailable');

    let showCoverage = false;
    let submitDisabled = true;

    switch (isUnavailable) {
      case true:
        showCoverage = false;
        submitDisabled = false;
        break;
      case false:
        showCoverage = true;
        submitDisabled = false;
        break;
      default:
        // Initial state, and null=neither option
        showCoverage = false;
        submitDisabled = true;
    }

    return {
      showCoverage: showCoverage || !props.unavailabilityEnabled,
      submitDisabled: submitDisabled && props.unavailabilityEnabled
    }
  }
)(AvailabilityAddForm)

export default AvailabilityAddForm
