import Promise from 'bluebird';
import {track} from 'utils/track';

export function completedRegistration (authToken, submitProfile, userRegistrationValues, history) {
  return ({completeRegistration}) => {
    return new Promise((resolve, reject) => {
      submitProfile(authToken, completeRegistration.firstLastName, completeRegistration.email, completeRegistration.phoneNumber, completeRegistration.password, userRegistrationValues.photoUrl, history, resolve, reject);
      track('Complete Profile - Submit');
    });
  };
}
