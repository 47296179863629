import React from 'react';
import {Field} from 'redux-form';
import PropTypes from 'prop-types';
import TimePickerControl from 'forms/controls/TimePickerAlt';

export default class TimePicker extends React.Component {
  static propTypes = {
    onFormChange: PropTypes.func,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isEditable: PropTypes.bool.isRequired,
    min: PropTypes.object,
    max: PropTypes.object
  }

  render () {
    return (
      <div className="o-layout u-margin-bottom">
        <div className="o-layout__item u-1/5 u-right-text">
          <label className="c-time-picker-label">{this.props.label}</label>
        </div>
        <div className="o-layout__item u-4/5">
          <Field
              component={TimePickerControl}
              isEditable={this.props.isEditable}
              max={this.props.max}
              min={this.props.min}
              name={this.props.name}
              onFormChange={this.props.onFormChange}
          />
        </div>
      </div>
    );
  }
}
