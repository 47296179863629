import React from 'react';

import FormButton from 'forms/controls/Button';

export default class SelectButton extends React.Component {
  render () {
    return (
      <FormButton
          className="c-form-button--primary c-form-button--block"
          label="Select"
          type="submit"
      />
    );
  }
}
