import React from 'react';

import FormButton from '../controls/Button';

export default class DeleteButton extends React.Component {
  render () {
    return (
      <FormButton
          className="c-form-button--primary c-form-button--destructive c-form-button--block"
          label={this.props.title ? this.props.title : 'Delete'}
          type="submit"
      />
    );
  }
}
