import {CALL_API} from 'middleware/api';
import {
  reviews as reviewsSchema
} from 'schemas/review';
import {
  LOAD_REVIEWS_REQUEST,
  LOAD_REVIEWS_SUCCESS,
  LOAD_REVIEWS_FAILURE
} from 'constants/sitter/reviews';

export function loadReviews (authToken, perPage, pageIndex) {
  return {
    [CALL_API]: {
      endpoint: `sitter/reviews?page=${pageIndex}&per_page=${perPage}`,
      headers: {
        'X-Identity-Token': authToken,
        'X-Identity-Key': process.env.SECRET_KEY
      },
      method: 'GET',
      schema: reviewsSchema,
      types: [
        LOAD_REVIEWS_REQUEST,
        LOAD_REVIEWS_SUCCESS,
        LOAD_REVIEWS_FAILURE
      ]
    }
  };
}
