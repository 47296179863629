import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {track} from 'utils/track';
import CompleteRegistrationForm from 'forms/sitter/CompleteRegistration';
import {completedRegistration as completeRegistrationSubmission} from 'submissions/sitter/signUp';
import {
  setTitle as setTitleAction,
} from 'actions/navigation';
import {submitProfile as submitProfileAction} from 'actions/parent/signUp';
import {getAuthToken} from 'selectors/session';
import {
  getUserRegistrationValues,
  getCompleteRegistrationFormValues
} from 'selectors/sitter/session';

export class ScheduleInterview extends React.Component {
  static propTypes = {
    authToken: PropTypes.string.isRequired,
    completeRegistrationFormValues: PropTypes.object.isRequired,
    submitProfile: PropTypes.func.isRequired,
    setTitle: PropTypes.func.isRequired
  };

  componentDidMount () {
    this.props.setTitle('Complete your profile');
    track('Complete Profile - Enter');
  }

  render () {
    return (
      <div className="o-layout">
        <div className="o-layout__item u-1/1 u-1/3@desktop u-push-1/3@desktop">
          <h1 className="c-h1 c-page-title">{'Complete your profile'}</h1>
          <CompleteRegistrationForm
              initialValues={this.props.completeRegistrationFormValues}
              onSubmit={completeRegistrationSubmission(this.props.authToken, this.props.submitProfile, this.props.userRegistrationValues, this.props.history)}
          />
        </div>
      </div>
    );
  }
}

export const makeMapStateToProps = () => {
  return (state, ownProps) => {
    return {
      authToken: getAuthToken(state, ownProps),
      completeRegistrationFormValues: getCompleteRegistrationFormValues(state, ownProps),
      userRegistrationValues: getUserRegistrationValues(state, ownProps)
    };
  };
};

const mapDispatchToProps = {
  setTitle: setTitleAction,
  submitProfile: submitProfileAction
};

export default connect(makeMapStateToProps, mapDispatchToProps)(ScheduleInterview);
