import {
  takeEvery,
  call,
  fork,
  put,
  select,
  take
} from 'redux-saga/effects';

import {identify} from 'utils/track';
import * as fb from 'utils/facebook';
import {HOME_PAGE} from 'constants/parent/navigation';
import {SET_AUTH_TOKEN} from 'constants/session';
import {
  facebookLogin,
} from 'actions/facebook';
import {
  FACEBOOK_AUTH_FAILURE,
  FACEBOOK_AUTH_REQUEST,
  FACEBOOK_AUTH_SUCCESS,
  FACEBOOK_SIGNUP_SUCCESS
} from 'constants/facebook';

export function *auth () {
  yield takeEvery(FACEBOOK_AUTH_REQUEST, authEffect);
}

export function *authEffect (action) {
  try {
    const context = action.context
    const {
      accessToken,
      error
    } = yield call(fb.login);
    if (accessToken) {
      yield put({type: FACEBOOK_AUTH_SUCCESS, accessToken, context});
    } else {
      yield put({type: FACEBOOK_AUTH_FAILURE, error});
    }
  } catch (e) {
    // refactor, handle facebook login fail
  }
}

export function *login () {
  yield takeEvery(FACEBOOK_AUTH_SUCCESS, loginEffect);
}

export function *loginEffect ({accessToken, context}) {
  const {
    session: {
      zip
    }
  } = yield select();
  yield put(facebookLogin(accessToken, zip, context.history));
}

export function *signUpSuccess () {
  while (true) { // eslint-disable-line no-constant-condition
    const {response, context} = yield take(FACEBOOK_SIGNUP_SUCCESS);
    const user = response.entities.user[response.result]; // eslint-disable-line camelcase
    yield fork(signUpSuccessEffect, user.authentication_token, user, context.history);
  }
}

export function *signUpSuccessEffect (authToken, user, history) {
  yield call(identify, user.email);
  yield put({type: SET_AUTH_TOKEN, authToken});
  yield call(history.push, `/${HOME_PAGE}`);
}
