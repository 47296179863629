import {CALL_API} from 'middleware/api';
import {user as userSchema} from 'schemas/user';
import {SECRET_KEY, DEFAULT_PARENT_PHOTO_URL} from 'constants/settings';
import {
  PROFILE_FAILURE,
  PROFILE_REQUEST,
  PROFILE_SUCCESS,
  PROFILE_OTHER_FAILURE,
  PROFILE_OTHER_REQUEST,
  PROFILE_OTHER_SUCCESS,
  PROFILE_UPDATE_FAILURE,
  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_SUCCESS,
} from 'constants/profile';

export function loadProfile (authToken, role = process.env.APP_ROLE === 'parent' ? 'parent' : 'sitter') {
  return {
    [CALL_API]: {
      endpoint: `${role}/profile`,
      headers: {
        'X-Identity-Token': authToken,
        'X-Identity-Key': SECRET_KEY
      },
      method: 'GET',
      schema: userSchema,
      types: [
        PROFILE_REQUEST,
        PROFILE_SUCCESS,
        PROFILE_FAILURE
      ]
    }
  };
}

export function loadOtherProfile (authToken, id) {
  const role = process.env.APP_ROLE === 'parent' ? 'parent' : 'sitter';
  const otherRole = process.env.APP_ROLE === 'parent' ? 'sitters' : 'parents';

  return {
    [CALL_API]: {
      endpoint: `${role}/${otherRole}/${id}`,
      headers: {
        'X-Identity-Token': authToken,
        'X-Identity-Key': SECRET_KEY
      },
      method: 'GET',
      schema: userSchema,
      types: [
        PROFILE_OTHER_REQUEST,
        PROFILE_OTHER_SUCCESS,
        PROFILE_OTHER_FAILURE
      ]
    }
  };
}

export function updateProfile (authToken, {firstName, lastName, email, phoneNumber, password}) {
  const role = process.env.APP_ROLE === 'parent' ? 'parent' : 'sitter';
  const data = {
    email,
    first_name: firstName, // eslint-disable-line camelcase
    last_name: lastName, // eslint-disable-line camelcase
    phone_number: phoneNumber, // eslint-disable-line camelcase
    photo_url: DEFAULT_PARENT_PHOTO_URL // eslint-disable-line camelcase
  };
  if (password) {
    data.password = password;
  }
  return {
    [CALL_API]: {
      data,
      endpoint: `${role}/profile`,
      headers: {
        'X-Identity-Key': SECRET_KEY,
        'X-Identity-Token': authToken
      },
      method: 'POST',
      schema: userSchema,
      types: [
        PROFILE_UPDATE_REQUEST,
        PROFILE_UPDATE_SUCCESS,
        PROFILE_UPDATE_FAILURE
      ]
    }
  };
}
