import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Markdown from 'react-markdown';

import AvailabilityModel from 'models/sitter/Availability';
import ConstantsModel from 'models/sitter/Constants';
import EventView from 'containers/sitter/EventView';
import LetsChangeButton from 'forms/fields/LetsChangeButton';
import LeaveItButton from 'forms/fields/LeaveItButton';
import {
  closeModal as closeModalAction,
  openModal as openModalAction
} from 'actions/modal';
import {getConfirmNextCopy} from 'selectors/sitter/availability';

export class CheckAvailability extends React.Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    booking: PropTypes.object.isRequired,
    copy: PropTypes.object.isRequired,
    availability: PropTypes.object.isRequired,
    duration: PropTypes.string.isRequired
  }

  render () {
    const availability = this.props.booking;
    const title = availability.start.format('MMMM Do YYYY');
    const bodyCopy = this.props.copy.body_markdown.split(':time_to_next').join(this.props.duration);
    return (
      <div>
        <div className="o-layout">
          <div className="o-layout__item u-1/1">
            <div className="u-center-text"><Markdown source={bodyCopy} /></div>
          </div>
        </div>
        <div className="o-layout">
          <div className="o-layout__item u-1/1 u-1/2@tablet">
            <LeaveItButton onClick={() => this.props.closeModal()} />
          </div>
          <div className="o-layout__item u-1/1 u-1/2@tablet">
            <LetsChangeButton
                onClick={() => { // refactor, extract action/saga pair
                  this.props.closeModal();
                  this.props.openModal(EventView, title, {
                    constants: new ConstantsModel(this.props.constants),
                    scheduleItem: availability,
                    isAvailability: true
                  }, {});
                }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export const makeMapStateToProps = () => {
  return (state, ownProps) => {
    return {
      copy: getConfirmNextCopy(state, ownProps)
    };
  };
};

export default connect(makeMapStateToProps, {
  closeModal: closeModalAction,
  openModal: openModalAction
})(CheckAvailability);
