import {
  put,
  takeEvery,
  select
} from 'redux-saga/effects';
import {loadProfile} from 'actions/profile';
import {loadConstants} from 'actions/settings';
import {
  HYDRO,
  SET_AUTH_TOKEN
} from 'constants/session';
import {hydroComplete as hydroCompleteAction} from 'actions/session';
import {
  PROFILE_SUCCESS
} from 'constants/profile';
import {
  CONSTANTS_SUCCESS
} from 'constants/settings';
import {
  getUser,
  getIsAuthenticated
} from 'selectors/session'
import {
  getConstantsLoaded
} from 'selectors/settings'

export function *hydrate () {
  yield takeEvery(HYDRO, hydrationEffect);
}

export function *hydrationEffect (action) {
  const {
    authToken
  } = action;

  if (authToken) {
    yield put({type: SET_AUTH_TOKEN, authToken});
    yield put(loadConstants(authToken));
    yield put(loadProfile(authToken));
  } else {
    yield put(loadConstants());
  }
}

export function *hydrationComplete () {
  yield takeEvery([CONSTANTS_SUCCESS, PROFILE_SUCCESS], hydrationCompleteEffect)
}

export function *hydrationCompleteEffect () {
  const isAuthenticated = yield select(getIsAuthenticated)
  const user = yield select(getUser)
  const constantsLoaded = yield select(getConstantsLoaded)

  if (isAuthenticated && user && constantsLoaded) {
    yield put(hydroCompleteAction());
  } else if (!isAuthenticated && constantsLoaded) {
    yield put(hydroCompleteAction());
  }
}
