import Collection from 'models/Collection';

export default class Contact {
  constructor (json = {}) {
    this.id = json.id;
    this.phonebookId = json.phonebook_id;
    this.firstName = json.first_name || '';
    this.lastName = json.last_name || '';
    this.fullName = `${this.firstName} ${this.lastName}`.trim();
    this.phoneNumbers = json.phone_numbers;
    this.relationship = json.relationship;
  }
}

export const EmergencyContactCollection = Collection(Contact, 'emergency_contacts');
