import {
  AVAILABILITIES_SUCCESS,
  UNAVAILABILITIES_SUCCESS
} from 'constants/sitter/availability';

function getDefaultState () {
  return {
    availabilities: [],
    unavailabilities: []
  };
}

export default function (state = getDefaultState(), action) {
  if (action.type === AVAILABILITIES_SUCCESS) {
    return {
      ...state,
      availabilities: action.response.result.availabilities.map(i => action.response.entities.availability[i])
    };
  }
  if (action.type === UNAVAILABILITIES_SUCCESS) {
    return {
      ...state,
      unavailabilities: action.response.result.unavailabilities.map(i => action.response.entities.unavailability[i])
    };
  }
  return state;
}
