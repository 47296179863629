import 'isomorphic-fetch';
import {normalize} from 'normalizr';

export const CALL_API = Symbol('CALL_API');

function makeAPICall (endpoint, schema, data = {}, method = 'GET', headers = {}) {
  const fullUri = `${process.env.API_URL_ROOT}${endpoint}`;
  const options = {
    headers: {
      'Accept': 'application/json', // eslint-disable-line quote-props
      'Content-Type': 'application/json',
      ...headers
    },
    method
  };
  if (method === 'POST' || method === 'PUT' || method === 'PATCH') { // refactor, handle patch?
    options.body = JSON.stringify(data);
  }
  return fetch(fullUri, options)
    .then(response => response.json().then(json => ({json, response})))
    .then(({json, response}) => {
      if (!response.ok) {
        return Promise.reject(json);
      }
      return {...normalize(json, schema)};
    });
}

export default store => next => action => {
  const callAPI = action[CALL_API];
  if (typeof callAPI === 'undefined') {
    return next(action);
  }
  let {endpoint} = callAPI;
  const {schema, types, data, method, headers, resolve, reject, context} = callAPI;
  if (typeof endpoint === 'function') {
    endpoint = endpoint(store.getState());
  }
  function actionWith (dat) {
    const finalAction = {...action, ...dat};
    delete finalAction[CALL_API];
    return finalAction;
  }
  const [REQUEST, SUCCESS, FAILURE] = types;
  next(actionWith({type: REQUEST}));
  return makeAPICall(endpoint, schema, data, method, headers, context).then(
    response => next(actionWith({
      response,
      resolve,
      reject,
      context,
      type: SUCCESS
    })),
    error => next(actionWith({
      type: FAILURE,
      resolve,
      reject,
      context,
      error: error
    }))
  );
};
