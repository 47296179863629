import {
  TOGGLE_NAV,
  SET_PAGE_TITLE,
  OPEN_MAP_PAGE
} from 'constants/navigation';

export function toggleNav (isOpen = false) {
  return {
    type: TOGGLE_NAV,
    isOpen
  };
}

export function setTitle (title) {
  return {
    type: SET_PAGE_TITLE,
    title
  };
}

export function openMapPage ({street, city, state, zipCode}) {
  return {
    type: OPEN_MAP_PAGE,
    query: (`${street} ${city}, ${state} ${zipCode}`).split(' ').join('+')
  };
}
