import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as SVGIcon from 'components/SVGIcon';

export default class Button extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    icon: PropTypes.string,
    iconProps: PropTypes.object,
    label: PropTypes.string,
    onClick: PropTypes.func,
    type: PropTypes.string.isRequired
  };

  render () {
    const {
      disabled,
      icon,
      iconProps,
      label,
      onClick,
      type
    } = this.props;
    const Icon = SVGIcon[icon];
    const className = classNames({
      'c-form-button': true,
      [this.props.className]: this.props.className && true
    });
    return (
      <button {...{className, disabled, onClick, type}}>{icon && SVGIcon[icon] && (<Icon {...iconProps} />)}{!!label && label}</button>
    );
  }
}
