export const HOME_PAGE = 'home';
export const LOGIN_PAGE = 'login';
export const LOGOUT_PAGE = 'logout';
export const PASSWORD_RESET_PAGE = 'password/reset';
export const SIGN_UP_PAGE = 'sign-up';
export const AVAILABLE_SEARCHES_PAGE = 'open-requests/available';
export const ALL_SEARCHES_PAGE = `${AVAILABLE_SEARCHES_PAGE}/all`;
export const ONE_TIME_SEARCHES_PAGE = `${AVAILABLE_SEARCHES_PAGE}/one-time`;
export const RECURRING_SEARCHES_PAGE = `${AVAILABLE_SEARCHES_PAGE}/recurring`;
export const INTERESTED_SEARCHES_PAGE = 'open-requests/interested';
export const TYPEFORM_PAGE = 'typeform';
export const COMPLETE_REGISTRATION_PAGE = 'complete-registration';
export const APPROVED_PAGE = 'approved';
export const REJECTED_PAGE = 'sorry';
export const WAIT_LIST_SUCCESS_PAGE = 'wait-list-success';
export const FUTURE_BOOKINGS_PAGE = 'bookings/future';
export const PAST_BOOKINGS_PAGE = 'bookings/past';
export const BOOKING_DETAILS_PAGE = 'bookings';
export const MESSAGES_PAGE = 'messages';
export const MESSAGE_PAGE = 'messages/parent';
export const ME_PAGE = 'me';
export const REVIEWS_PAGINATION_PAGE = 'reviews';
export const SIGN_UP_ZIP_PAGE = 'sign-up/zip';
export const SETTINGS_PAGE = 'settings';
