import {
  fork,
  call,
  take
} from 'redux-saga/effects';
import {
  OPEN_MAP_PAGE
} from 'constants/navigation';

export function * openMapPage () {
  while (true) { // eslint-disable-line no-constant-condition
    const {query} = yield take(OPEN_MAP_PAGE);
    yield fork(openMapPageEffect, query);
  }
}

export function * openMapPageEffect (query) {
  // yield call([window.location, window.location.assign], `https://www.google.com/maps/place/${query}`)
  yield call([window, window.open], `https://www.google.com/maps/place/${query}`, '_blank');
}
