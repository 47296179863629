import moment from 'moment';

import Contact from 'models/Contact';

export default class ProfileBase {
  constructor (json = {}) {
    this.id = json.id;
    this.hashId = json.hashId;
    this.email = json.email;
    this.firstName = json.first_name || '';
    this.lastName = json.last_name || '';
    this.fullName = `${this.firstName} ${this.lastName}`;
    this.age = json.age;
    this.phoneNumber = json.phone_number;
    this.dateOfBirth = json.date_of_birth ? moment(json.date_of_birth) : undefined;
    this.rating = json.rating;
    this.photoUrl = json.photo_url;
    const contacts = json.emergency_contacts || [];
    this.emergencyContacts = contacts.map(c => new Contact(c));
    this.inProgressBooking = json.in_progress_booking;
    this.defaultReferralCode = json.default_referral_code;
  }

  getPhotoUrl (w = 40, h) {
    if (!this.photoUrl) { return undefined; }
    const matches = this.photoUrl.match(/^(https?:\/\/res\.cloudinary\.com\/.*?\/upload\/).*?(\/?v\d{10,}.*?)$/);
    if (!matches) {
      return this.photoUrl;
    }
    h = h || w; // eslint-disable-line no-param-reassign
    const manipulation = `c_thumb,w_${w},h_${h}`;
    return `${matches[1]}${manipulation}/${matches[2]}`;
  }

  json () {
    return {
      email: this.email,
      first_name: this.firstName, // eslint-disable-line camelcase
      last_name: this.lastName // eslint-disable-line camelcase
    };
  }
}
