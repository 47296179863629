import moment from 'moment';

export default class Availability {
  constructor (availability) {
    this.id = availability.id || 0;
    this.start = moment(availability.start_time);
    this.end = moment(availability.end_time);
    this.booked = availability.booked;
    this.bookingId = availability.booking;
    this.zones = availability.coverage_zones;
    this.hasDefaultZones = availability.has_default_coverage_zones;
  }
}
