export default class Location {
  constructor (json = {}) {
    this.street = json.street;
    this.city = json.city;
    this.state = json.state;
    this.zipCode = json.zip_code;
    this.apartment = json.apartment;
    this.geolocation = json.geolocation;
  }

  get streetAddress () {
    let line = this.street;
    if (this.apartment) {
      line += `, ${this.apartment}`;
    }
    return line;
  }

  get cityStateZip () {
    return `${this.city}, ${this.state} ${this.zipCode}`;
  }

  get fullAddress () {
    return `${this.streetAddress}, ${this.cityStateZip}`;
  }
}
