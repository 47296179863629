import React from 'react';
import {connect} from 'react-redux';

import {track} from 'utils/track';
import FormButton from 'forms/controls/FormButton';
import {closeModal as closeModalAction} from 'actions/modal';
import * as SVGIcon from 'components/SVGIcon';
import PortraitFrame from 'components/PortraitFrame';

export class WelcomeIntro extends React.Component {
  componentDidMount () {
    track('Welcome Intro Modal');
  }

  renderListItem ({icon, children}) {
    const Icon = SVGIcon[icon];
    return (
      <div className="u-margin-bottom">
        <div className="o-media">
          <div className="o-media__img c-feature-icon">
            {Icon && (<Icon />)}
          </div>
          <div className="o-media__body">
            {children}
          </div>
        </div>
      </div>
    );
  }

  render () {
    const {closeModal} = this.props;
    const ListItem = this.renderListItem;
    return (
      <div>
        <PortraitFrame
            classes={['c-portrait-frame--medium c-welcome-intro__portrait-frame u-margin-bottom u-margin-top']}
            src="/vivian-chan-portrait.jpg"
        />
        <p className="u-center-text">{'Hi, I’m Vivian \u2014 Co-founder of Trusted. Whether you need a babysitter tonight or a professional nanny for daily or weekly care, we can help.'}</p>
        <hr />
        <ul className="o-list-bare">
          <li>
            <ListItem icon="FeatureVetted">
              <p>{'Providers are Trusted employees, background checked, and CPR certified'}</p>
            </ListItem>
          </li>
          <li>
            <ListItem icon="FeatureChat">
              <p>{'Check-in with chat, live video, and geolocation'}</p>
            </ListItem>
          </li>
          <li>
            <ListItem icon="FeaturePayment">
              <p>{'Pay as you go - $25/hour for the first child and $5/hour for every additional child'}</p>
            </ListItem>
          </li>
        </ul>
        <hr />
        <p className="u-center-text c-text-small c-text-small--muted">{'Click on the chat bubble on the bottom-right corner of the screen if you have any questions or special requests'}</p>
        <FormButton
            className="c-form-button--block c-form-button--primary"
            label="Get Started"
            onClick={() => { closeModal(); }}
            type="button"
        />
      </div>
    );
  }
}

export default connect(() => {
  return {};
}, {
  closeModal: closeModalAction
})(WelcomeIntro);
