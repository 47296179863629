import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

export const timeFormat = 'ddd, MMM D, YYYY, h:mm a';

export function generateTimes (start, end, interval = 900) {
  const times = [];
  let time = moment(start);
  while (!time.isAfter(end)) {
    times.push({
      value: time,
      label: time.format(timeFormat)
    });
    time = moment(time).add({seconds: interval});
  }
  return times;
}

export class TimeOption extends React.Component {
  static propTypes = {
  };

  render () {
    return (
      <span>{this.props.text}</span>
    );
  }
}

export default class TimePicker extends React.Component { // eslint-disable-line react/no-multi-comp
  static propTypes = {
    onFormChange: PropTypes.func,
    isEditable: PropTypes.bool.isRequired,
    min: PropTypes.object,
    max: PropTypes.object
  }

  handleChange = event => {
    const value = moment(event.target.value);
    this.props.input.onChange(value);
    this.props.onFormChange(this.props.input.name, value);
  }

  render () {
    const times = this.props.isEditable ? generateTimes(this.props.min, this.props.max) : [];
    if (this.props.isEditable) {
      if (this.props.input.value) {
        return (
          <select
              className="c-time-select"
              defaultValue={this.props.input.value.toISOString()}
              onChange={this.handleChange}
          >
            {times.map(t => (
              <option
                  key={t.value.toISOString()}
                  value={t.value.toISOString()}
              >{t.label}</option>
            ))}
          </select>
        );
      } else {
        return null;
      }
    } else {
      return (
        <div className="c-time-picker">
          <div className="c-time-picker__overlay" />
          <div className="c-fake-field">
            {moment(this.props.input.value).format(timeFormat)}
          </div>
        </div>
      );
    }
  }
}
