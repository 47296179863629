import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {Tooltip} from 'pivotal-ui/react/tooltip';
import {OverlayTrigger} from 'pivotal-ui/react/overlay-trigger';

export default class ProfileSkillBadges extends React.Component {
  static propTypes = {
    skillsList: PropTypes.array.isRequired,
    badges: PropTypes.object.isRequired
  }

  render () {
    const {skillsList} = this.props;
    return (
      <div className="c-skill-badges o-layout">
        {skillsList.map((skillTitle, key) => {
          const className = classNames({
            'c-skill-badges__border': true,
            'c-skill-badges__border--active': true
          });
          return (
            <div
                className={`o-layout__item u-1/${skillsList.length}`}
                {...{key}}
            >
              {(
                <OverlayTrigger
                    overlay={<Tooltip>{this.props.badges[skillTitle].title}</Tooltip>}
                    placement="bottom"
                >
                  <div className="c-icon-block">
                    <span {...{className}}>
                      <img
                          className="c-icon"
                          src={this.props.badges[skillTitle].asset_url}
                      />
                    </span>
                  </div>
                </OverlayTrigger>
              )}
            </div>
          );
        })}
      </div>
    );
  }
}
