import {combineReducers} from 'redux';
import {reducer as formReducer} from 'redux-form';
// import {reducer as burgerMenuReducer} from 'redux-burger-menu';
// import {reducer as modalReducer} from 'react-redux-modal';

import hudReducer from 'reducers/hud';
import modalReducer from 'reducers/modal';
import navigationReducer from 'reducers/navigation';
import settingsReducer from 'reducers/settings';
import sessionReducer from 'reducers/session';
import loadingReducer from 'reducers/sitter/loading';
import availabilityReducer from 'reducers/sitter/availability';
import coverageZonesReducer from 'reducers/sitter/coverageZones';
import searchReducer from 'reducers/sitter/search';
import bookingsReducer from 'reducers/sitter/bookings';

export const reducerList = {
  availability: availabilityReducer,
  loading: loadingReducer,
  form: formReducer,
  hud: hudReducer,
  modal: modalReducer,
  navigation: navigationReducer,
  settings: settingsReducer,
  session: sessionReducer,
  coverageZones: coverageZonesReducer,
  search: searchReducer,
  bookings: bookingsReducer
};

const rootReducer = combineReducers(reducerList);

export default rootReducer;
