import moment from 'moment';

import TimeService from 'services/Time';
import ConstantsModel from 'models/sitter/Constants';

export default class AvailabilityService {
  constructor (constants) {
    this.constants = constants;
    this.timeService = new TimeService(new ConstantsModel(constants));
  }

  /**
   * Determines the lower and upper bounds for the target date and time
   * Returns null if no valid new-availability bounds exist for the specified date+time
   */
  findNewAvailabilityBounds (date, availabilities) {
    if (availabilities.isFetching) {
      return;
    }
    if (date.isBefore(this.timeService.earliestValidStartDate())) {
      return;
    }
    if (date.isBefore(this.timeService.dateStart({date}))) {
      date = moment(date).subtract({days: 1}).endOf('day'); // eslint-disable-line no-param-reassign
    }
    const padding = this.constants.availability.padding;
    const minDuration = this.constants.availability.minDuration;
    const violatesLowerBound = availabilities.values.find(a => {
      const bound = moment(a.start).subtract({seconds: padding});
      return date.isAfter(bound) && date.isBefore(a.end);
    });
    const violatesUpperBound = availabilities.values.find(a => {
      const bound = moment(a.end).add({seconds: padding});
      return date.isBefore(bound) && date.isAfter(a.start);
    });
    if (violatesLowerBound || violatesUpperBound) {
      return;
    }
    const availabilityStarts = availabilities.values.map(a => moment(a.start).unix()).filter(ts => ts > date.unix()); // Find the lower (prev availability end) and upper (next availability start) bounds for the new availability
    const availabilityEnds = availabilities.values.map(a => moment(a.end).unix()).filter(ts => ts < date.unix());
    const nextBound = availabilityStarts.length > 0 ? moment.unix(Math.min(...availabilityStarts)).subtract({seconds: padding}) : null;
    const prevBound = availabilityEnds.length > 0 ? moment.unix(Math.max(...availabilityEnds)).add({seconds: padding}) : null;
    const {
      lowerBound,
      upperBound
    } = this.timeService.getBounds({date, minDuration, lowerBound: prevBound, upperBound: nextBound});
    if ((upperBound.unix() - lowerBound.unix()) < minDuration) {
      return;
    }
    return {
      lower: lowerBound,
      upper: upperBound
    };
  }
}
