import {
  take,
  fork,
  select,
  call,
  put,
  takeEvery,
  putResolve
} from 'redux-saga/effects';

import {
  ALL_SEARCHES_PAGE,
  ONE_TIME_SEARCHES_PAGE,
  RECURRING_SEARCHES_PAGE
} from 'constants/sitter/navigation';
import {
  RESPOND_ALL_SUCCESS,
  RESPOND_RECURRING_SUCCESS,
  RESPOND_ONE_TIME_SUCCESS,
  RESPOND_INTERESTED_SUCCESS,
  SHOW_INTRO_MODAL,
  INTRO_MODAL_SEEN
} from 'constants/sitter/search';
import {
  BOOKING_DETAILS_PAGE
} from 'constants/sitter/navigation';
import {
  RESPOND_BOOKING_SUCCESS,
  RESPOND_BOOKING_FAILURE
} from 'constants/sitter/search';
import {
  getAuthToken,
  getStoredCurrentPage as getCurrentPage
} from 'selectors/session';
import {
  getAllSearches,
  getOneTimeSearches,
  getRecurringSearches,
  getResultsPerPage,
  getIntroModalCopy
} from 'selectors/sitter/search';
import {
  loadAllSearches,
  loadOneTimeSearches,
  loadRecurringSearches,
  loadInterestedSearches
} from 'actions/sitter/search';
import {openModal} from 'actions/modal';
import IntroModal from 'containers/sitter/SearchIntroModal';
import Cookies from 'js-cookie';
import ErrorModal from 'containers/ErrorModal';

export function * showIntroModal () {
  while (true) {
    yield take(SHOW_INTRO_MODAL);
    yield fork(showIntroModalEffect);
  }
}

export function * showIntroModalEffect () {
  const copy = yield select(getIntroModalCopy);
  yield call(Cookies.set, INTRO_MODAL_SEEN, true);
  yield put(openModal(IntroModal, copy.title, {copy}, {}));
}

export function * refreshAllSearches () {
  while (true) {
    yield take(RESPOND_ALL_SUCCESS);
    yield fork(refreshAllSearchesEffect);
  }
}

export function * refreshAllSearchesEffect (action) {
  const authToken = yield select(getAuthToken);
  const perPage = yield select(getResultsPerPage);
  const pageIndex = yield select(getCurrentPage);
  yield putResolve(loadAllSearches(authToken, perPage, pageIndex));
  const searches = yield select(getAllSearches);
  if (!searches.length && pageIndex > 1) {
    yield call(action.context.history.push, `/${ALL_SEARCHES_PAGE}/${pageIndex - 1}`);
  }
}

export function * refreshOneTimeSearches () {
  while (true) {
    yield take(RESPOND_ONE_TIME_SUCCESS);
    yield fork(refreshOneTimeSearchesEffect);
  }
}

export function * refreshOneTimeSearchesEffect (action) {
  const authToken = yield select(getAuthToken);
  const perPage = yield select(getResultsPerPage);
  const pageIndex = yield select(getCurrentPage);
  yield putResolve(loadOneTimeSearches(authToken, perPage, pageIndex));
  const searches = yield select(getOneTimeSearches);
  if (!searches.length && pageIndex > 1) {
    yield call(action.context.history.push, `/${ONE_TIME_SEARCHES_PAGE}/${pageIndex - 1}`);
  }
}

export function * refreshRecurringSearches () {
  while (true) {
    yield take(RESPOND_RECURRING_SUCCESS);
    yield fork(refreshRecurringSearchesEffect);
  }
}

export function * refreshRecurringSearchesEffect (action) {
  const authToken = yield select(getAuthToken);
  const perPage = yield select(getResultsPerPage);
  const pageIndex = yield select(getCurrentPage);
  yield putResolve(loadRecurringSearches(authToken, perPage, pageIndex));
  const searches = yield select(getRecurringSearches);
  if (!searches.length && pageIndex > 1) {
    yield call(action.context.history.push, `/${RECURRING_SEARCHES_PAGE}/${pageIndex - 1}`);
  }
}

export function * refreshInterestedSearches () {
  while (true) {
    yield take(RESPOND_INTERESTED_SUCCESS);
    yield fork(refreshInterestedSearchesEffect);
  }
}

export function * refreshInterestedSearchesEffect () {
  const authToken = yield select(getAuthToken);
  const perPage = yield select(getResultsPerPage);
  const pageIndex = yield select(getCurrentPage);
  yield put(loadInterestedSearches(authToken, perPage, pageIndex));
}

export function * searchToBook () {
  yield takeEvery(RESPOND_BOOKING_SUCCESS, searchToBookEffect);
}

export function * searchToBookEffect (action) {
  const booking = action.response.entities.bookings[action.response.result];
  yield call(action.context.history.push, `/${BOOKING_DETAILS_PAGE}/${booking.id}`)
}

export function * searchToBookFailure () {
  while (true) {
    const {
      error,
      resolve
    } = yield take(RESPOND_BOOKING_FAILURE);
    yield fork(searchToBookFailureEffect, error, resolve);
  }
}

export function * searchToBookFailureEffect (error, resolve) {
  yield put(openModal(ErrorModal, 'Something went wrong', {message: error.message}, {}));
  yield call(resolve);
}
