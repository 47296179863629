import React from 'react';
import {
  reduxForm,
  FormSection
} from 'redux-form';

import { validateFields } from 'utils/formValidations';
import {parentNote as validateParentNote} from 'forms/validations/booking';
import NoteField from 'forms/fields/NoteTextarea';
import SaveButton from 'forms/fields/SaveButton';

export class ParentNoteAdd extends React.Component {
  render () {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <FormSection name="parentNote">
          <NoteField />
        </FormSection>
        <SaveButton />
      </form>
    );
  }
}

export default reduxForm({
  form: 'parentNote',
  validate: validateFields({
    parentNote: validateParentNote
  })
})(ParentNoteAdd);
