import React from 'react';
import PropTypes from 'prop-types';

import {Tooltip} from 'pivotal-ui/react/tooltip';
import {OverlayTrigger} from 'pivotal-ui/react/overlay-trigger';

export default class ProfileVerificationBadges extends React.Component {
  static propTypes = {
    verificationList: PropTypes.array.isRequired,
    badges: PropTypes.object.isRequired
  }

  render () {
    return (
      <div className="c-verification-badges o-layout">
        {this.props.verificationList.map((type, key) => {
          return (
            <div
                className={`o-layout__item u-1/${this.props.verificationList.length}`}
                {...{key}}
            >
              {type && (
                <OverlayTrigger
                    overlay={<Tooltip>{this.props.badges[type].title}</Tooltip>}
                    placement="bottom"
                >
                  <div className="c-icon-block">
                    <img
                        className="c-icon"
                        src={this.props.badges[type].asset_url}
                    />
                  </div>
                </OverlayTrigger>
              )}
            </div>
          );
        })}
      </div>
    );
  }
}
