import {createSelector} from 'reselect';

export const getSettings = state => state.settings;

export const getNotificationSettings = createSelector(
  [getSettings],
  settings => ({
    email: !settings.notificationSettings.opt_out_email,
    push: !settings.notificationSettings.opt_out_push,
    sms: !settings.notificationSettings.opt_out_sms,
    availabilityPush: !settings.notificationSettings.opt_out_availability_push,
    requestEmails: !settings.notificationSettings.opt_out_open_requests_email
  })
);

export const getConstantsLoaded = createSelector(
  [getSettings],
  settings => settings.constantsLoaded
);

export const getConstants = createSelector(
  [getSettings],
  settings => settings.constants
);

export const getCopy = createSelector(
  [getSettings],
  settings => settings.copy.en || {}
);

export const getFeatures = createSelector(
  [getConstants],
  constants => constants.features || {}
);

export const getBadges = createSelector(
  [getCopy],
  copy => copy.badge || {}
);

export const getForms = createSelector(
  [getConstants],
  constants => constants.forms || {}
);

export const getFteFormUrl = createSelector(
  [getForms],
  forms => forms.fte || {}
);
