import {createSelector} from 'reselect';

export const getUi = state => state.ui;
export const getModal = state => state.modal;

export const getCondensed = createSelector(
  [getUi],
  ui => ui.searchCollapsed
);

export const getModalOptions = createSelector(
  [getModal],
  modal => modal.options
);
