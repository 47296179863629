/* global confirm */

import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

import MapView from 'components/MapView'
import ProfileLineView from 'components/sitter/ProfileLineView'
import BookingTimeView from 'components/sitter/BookingTimeView'
import BookingNoteView from 'components/sitter/BookingNoteView'
import BookingChildrenView from 'components/sitter/BookingChildrenView'
import ConfirmBookingButton from 'forms/fields/ConfirmBookingButton'
import EndBookingButton from 'forms/fields/EndBookingButton'
import TabSwitchButton from 'forms/fields/TabSwitchButton'
import AvatarNameView from 'components/sitter/AvatarNameView'
import ProfileChildrenView from 'components/sitter/ProfileChildrenView'
import EmergencyContactsView from 'components/sitter/EmergencyContactsView'
import BookingPolicyView from 'components/sitter/BookingPolicyView'
import TrustedNotesView from 'components/sitter/TrustedNotesView'
import EndTimeForm from 'forms/fields/EndTime'
import SitterNotesView from 'components/sitter/SitterNotesView'

export default class BookingDetails extends React.Component {
  static propTypes = {
    onNoteAdd: PropTypes.func.isRequired,
    notes: PropTypes.array.isRequired,
    openMapPage: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    date: PropTypes.object,
    booking: PropTypes.object.isRequired,
    showContactInfo: PropTypes.bool.isRequired,
    onEndBookingClick: PropTypes.func.isRequired,
    onConfirmBookingClick: PropTypes.func.isRequired,
    onCallPhone: PropTypes.func.isRequired,
    hideMessages: PropTypes.bool.isRequired
  }

  state = {
    activeTab: 'bookingDetails'
  }

  confirmBooking = () => {
    this.props.onConfirmBookingClick()
  }

  endBooking = () => {
    this.setState({activeTab: 'endBooking'})
  }

  onEndSubmit = values => {
    const formattedEnd = values.endTime.time.format('MMM D, YYYY [at] h:mma')
    if (confirm(`Please confirm this booking ended on ${formattedEnd}`)) { // eslint-disable-line no-alert
      this.props.onEndBookingClick(values)
      this.setState({activeTab: 'bookingDetails'})
    }
  }

  renderEndBooking () {
    const now = moment().startOf('minute')
    const lowerBound = this.props.booking.start.startOf('minute')
    return (
      <div className="o-layout">
        <div className="o-layout__item u-1/1">
          <p className="u-center-text">{'Please select the date and time when the booking ended.'}</p>
        </div>
        <div className="o-layout__item u-1/1">
          <EndTimeForm
              booking={this.props.booking}
              date={this.props.date}
              initialValues={{
                endTime: {
                  time: moment.max(moment.min(now, this.props.booking.end), lowerBound)
                }
              }}
              onCancel={() => this.setState({activeTab: 'bookingDetails'})}
              onSubmit={this.onEndSubmit}
          />
        </div>
      </div>
    )
  }

  renderParentDetails () {
    return (
      <div className="o-layout">
        <div className="o-layout__item u-1/1 u-3/5@desktop u-push-1/5@desktop">
          <AvatarNameView profile={this.props.booking.parent} />
          <ProfileChildrenView children={this.props.booking.parent.children} />
          {this.props.showContactInfo && (
            <EmergencyContactsView profile={this.props.booking.parent} />
          )}
          {(this.props.booking.parent.note || (!!this.props.notes && !!this.props.notes.length)) && (
            <div className="c-profile-parent-detail-notes">
              {this.props.booking.parent.note && (
                <TrustedNotesView note={this.props.booking.parent.note} />
              )}
              {(!!this.props.notes && !!this.props.notes.length) && (
                <SitterNotesView
                    notes={this.props.notes}
                    onSubmit={this.props.onNoteAdd}
                />
              )}
            </div>
          )}
        </div>
      </div>
    )
  }

  renderBookingDetails () {
    return (
      <div className="o-layout">
        <div className="o-layout__item u-1/1">
          <MapView
              location={this.props.booking.location}
              openMapPage={this.props.openMapPage}
          />
          <ProfileLineView
              closeModal={this.props.closeModal}
              hideMessages={this.props.hideMessages}
              onPhone={this.props.onCallPhone}
              profile={this.props.booking.parent}
              showPhoneNumber={this.props.showContactInfo}
          />
          <BookingTimeView booking={this.props.booking} />
          {this.props.booking.note && (
            <BookingNoteView booking={this.props.booking} />
          )}
          <BookingChildrenView children={this.props.booking.children} />
          {this.props.showContactInfo && (
            <EmergencyContactsView profile={this.props.booking.parent} />
          )}
          {this.props.booking.policy && (
            <BookingPolicyView policy={this.props.booking.policy} />
          )}
          <div className="o-layout u-margin-top">
            <div className="o-layout__item u-1/1 u-1/3@desktop u-push-1/3@desktop">
              {this.props.booking.status === 'created' && (
                <ConfirmBookingButton onClick={this.confirmBooking} />
              )}
              {this.props.booking.status === 'confirmed' && (
                <div className="c-booking-action c-booking-action--confirmed">{'Confirmed'}</div>
              )}
              {this.props.booking.status === 'in_progress' && (
                <EndBookingButton onClick={this.endBooking} />
              )}
              {this.props.booking.status === 'ended' && (
                <div className="c-booking-action c-booking-action--ended">{'Ended'}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  render () {
    return (
      <div>
        <div className="o-layout">
          {(this.state.activeTab !== 'endBooking') && (
            <div className="o-layout__item u-1/1 u-3/5@desktop u-push-1/5@desktop">
              <div className="o-layout">
                <div className="o-layout__item u-1/2">
                  <TabSwitchButton
                      active={this.state.activeTab === 'bookingDetails'}
                      onClick={() => this.setState({activeTab: 'bookingDetails'})}
                  >{'Booking'}</TabSwitchButton>
                </div>
                <div className="o-layout__item u-1/2">
                  <TabSwitchButton
                      active={this.state.activeTab === 'parentDetails'}
                      onClick={() => this.setState({activeTab: 'parentDetails'})}
                  >{'Parent'}</TabSwitchButton>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="o-layout">
          <div className="o-layout__item u-1/1">
            {(this.state.activeTab === 'bookingDetails') && this.renderBookingDetails()}
            {(this.state.activeTab === 'parentDetails') && this.renderParentDetails()}
            {(this.state.activeTab === 'endBooking') && this.renderEndBooking()}
          </div>
        </div>
      </div>
    )
  }
}
