import {schema} from 'normalizr';

/*
import {childProfile as childProfileSchema} from 'schemas/childProfile';
import {householdDetails as householdDetailsSchema} from 'schemas/householdDetails';
import {location as locationSchema} from 'schemas/location';
import {sitter as sitterSchema} from 'schemas/sitter';
import {paymentMethod as paymentMethodSchema} from 'schemas/paymentMethod';
*/

/*
booking.define({
  child_profiles: arrayOf(childProfileSchema), // eslint-disable-line camelcase
  household_details: householdDetailsSchema, // eslint-disable-line camelcase
  location: locationSchema,
  payment_method: paymentMethodSchema, // eslint-disable-line camelcase
  sitter: sitterSchema
});
*/

export const booking = new schema.Entity(
  'bookings',
  {},
  { idAttribute: 'id' }
);

export const bookings = { bookings: new schema.Array(booking) };

export const phone = new schema.Entity('bridge_number');
