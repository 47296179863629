import React from 'react';
import {
  FormSection,
  reduxForm
} from 'redux-form';

import {
  validateFields,
} from 'utils/formValidations';

import CoverageZonesField from '../fields/CoverageZonesPicker';
import TimePickerField from '../fields/TimePicker';
import DeleteButton from '../fields/DeleteButton';

export class AvailabilityDetail extends React.Component {
  render () {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <div className="c-fields">
          <FormSection name="schedule">
            <TimePickerField
                isEditable={false}
                label="Start"
                name="start"
            />
          </FormSection>
          <FormSection name="schedule">
            <TimePickerField
                isEditable={false}
                label="End"
                name="end"
            />
          </FormSection>
          {this.props.isAvailability ? (
            <div>
              <FormSection name="schedule">
                <CoverageZonesField
                    isEditable={false}
                />
              </FormSection>
            </div>
          ) : null}
          <DeleteButton title={this.props.isAvailability ? 'Delete Availability' : 'Delete Unavailability'} />
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: 'availabilityDetail',
  validate: validateFields({
  })
})(AvailabilityDetail);
