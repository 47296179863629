import React from 'react';
import PropTypes from 'prop-types';

export default class BookingPolicyView extends React.Component {
  static propTypes = {
    policy: PropTypes.object.isRequired
  }

  render () {
    return (
      <div>
        <div className="c-booking-policy-view">
          <h4>{'Booking Policy'}</h4>
          {this.props.policy.links.map((link, key) => (
            <div
                className="c-link"
                key={key}
            >
              <a href={link.url}
                  target="_blank"
              >
                {link.title}
              </a>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
