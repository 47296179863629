import {SECRET_KEY} from 'constants/settings';
import {
  STREAM_TOKEN_REQUEST,
  STREAM_TOKEN_SUCCESS,
  STREAM_TOKEN_FAILURE,
} from 'constants/stream';
import {CALL_API} from 'middleware/api';

import {streamToken as streamTokenSchema} from 'schemas/streamToken';

export function getStreamToken (authToken) {
  return {
    [CALL_API]: {
      endpoint: 'stream_token',
      headers: {
        'X-Identity-Key': SECRET_KEY,
        'X-Identity-Token': authToken
      },
      method: 'POST',
      schema: streamTokenSchema,
      types: [
        STREAM_TOKEN_REQUEST,
        STREAM_TOKEN_SUCCESS,
        STREAM_TOKEN_FAILURE
      ]
    }
  };
}
