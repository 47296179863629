import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {track} from 'utils/track';
import {
  closeModal as closeModalAction
} from 'actions/modal';
import {
  setTitle as setTitleAction
} from 'actions/navigation';
import {
  getPastBookings,
  getPastBookingsPageCount
} from 'selectors/bookings';
import BookingListItem from 'components/BookingListItem';
import Paginate from 'components/Paginate';
import {PAST_BOOKINGS_PAGE} from 'constants/sitter/navigation';
import {
  loadPastBookings
} from 'actions/sitter/bookings';
import {
  getAuthToken,
  getCurrentPage,
} from 'selectors/session';
import {
  getBookingsPerPage,
} from 'selectors/bookings';

export class PastBookings extends React.Component {
  static propTypes = {
    currentPage: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    closeModal: PropTypes.func.isRequired,
    setTitle: PropTypes.func.isRequired,
    pastBookings: PropTypes.array.isRequired,
    getPastBookings: PropTypes.func.isRequired,
    authToken: PropTypes.string.isRequired,
    perPage: PropTypes.number.isRequired,
  }

  componentDidMount () {
    this.props.setTitle('Past Bookings');
    track('Past Bookings Page - Enter');

    this.props.getPastBookings(this.props.authToken, this.props.perPage, this.props.currentPage)
  }

  hrefBuilder (pageIndex) {
    return `/${PAST_BOOKINGS_PAGE}/${pageIndex}`;
  }

  handlePagination = pageIndex => {
    this.props.history.push(this.hrefBuilder(pageIndex))
    this.props.getPastBookings(this.props.authToken, this.props.perPage, pageIndex)
  }

  render () {
    return (
      <div className="o-layout">
        <div className="o-layout__item u-1/1">
          <h1 className="c-h1 c-page-title">{'Past Bookings'}</h1>
          {!this.props.pastBookings.length && (
            <p className="u-center-text">{'Your completed bookings will appear here.  After you\'ve finished a booking, it will appear here.'}</p>
          )}
          {!!this.props.pastBookings.length && this.props.pastBookings.map(booking => (
            <BookingListItem
                closeModal={this.props.closeModal}
                key={booking.id}
                {...{booking}}
            />
          ))}
          {(this.props.pageCount > 1) && (
            <Paginate
                currentPage={this.props.currentPage}
                hrefBuilder={this.hrefBuilder}
                onPageChange={this.handlePagination}
                pageCount={this.props.pageCount}
            />
          )}
        </div>
      </div>
    );
  }
}

const makeMapStateToProps = () => {
  return (state, ownProps) => {
    return {
      currentPage: getCurrentPage(state, ownProps),
      pageCount: getPastBookingsPageCount(state, ownProps),
      pastBookings: getPastBookings(state, ownProps),
      authToken: getAuthToken(state, ownProps),
      perPage: getBookingsPerPage(state, ownProps)
    };
  };
};

const mapDispatchToProps = {
  setTitle: setTitleAction,
  closeModal: closeModalAction,
  getPastBookings: loadPastBookings
};

export default connect(makeMapStateToProps, mapDispatchToProps)(PastBookings);
