import {PROVIDER_ALL_COVERAGE_ZONES_SUCCESS} from 'constants/sitter/availability';

export function getDefaultState () {
  return {
    coverageZones: []
  };
}

export default function (state = getDefaultState(), action) {
  if (action.type === PROVIDER_ALL_COVERAGE_ZONES_SUCCESS) {
    return {
      ...state,
      coverageZones: action.response.result.coverage_zones // .map(id => action.response.entities.coverage_zone[id])
    };
  }
  return state;
}
