import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {Loading} from 'components/SVGIcon';

export default class Hud extends React.Component {
  static propTypes = {
    active: PropTypes.bool,
    brand: PropTypes.bool,
    complete: PropTypes.bool,
    dark: PropTypes.bool,
    light: PropTypes.bool,
    title: PropTypes.string
  };

  renderContent (props) {
    const {
      brand,
      complete,
      dark,
      title
    } = props;
    const loadingProps = {
      active: true,
      brand,
      complete,
      drawing: !complete,
      inverse: dark,
      tracing: true
    };
    return (
      <div className="c-hud-content">
        <div className="c-hud-content__loading">
          <Loading {...loadingProps} />
        </div>
        <div className="c-h4 c-hud-content__context">
          {title}
        </div>
      </div>
    );
  }

  render () {
    const {
      active,
      brand,
      complete,
      dark,
      light
    } = this.props;
    const className = classNames({
      'c-hud': true,
      'c-hud--active': active,
      'c-hud--complete': complete,
      'c-hud--light': light || !dark,
      'c-hud--brand': brand,
      'c-hud--dark': dark
    });
    return (
      <div {...{className}}>
        {active && this.renderContent(this.props)}
      </div>
    );
  }
}
