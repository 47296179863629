import React from 'react';
import {connect} from 'react-redux';

import {closeModal as closeModalAction} from 'actions/modal';
import {getAfterAddCopy} from 'selectors/sitter/availability';
import AfterAddForm from 'forms/AfterAdd';
import PropTypes from 'prop-types';
import {afterAdd as afterAddSubmission} from 'submissions/sitter/availability';

export class AfterAdd extends React.Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired
  };

  render () {
    return (
      <AfterAddForm
          copy={this.props.afterAddCopy}
          initialValues={{}}
          onSubmit={afterAddSubmission(this.props.closeModal)}
      />
    );
  }
}

const makeMapStateToProps = () => {
  return (state, ownProps) => {
    return {
      afterAddCopy: getAfterAddCopy(state, ownProps)
    };
  };
};

export default connect(makeMapStateToProps, {
  closeModal: closeModalAction
})(AfterAdd);
