import React from 'react';
import {
  FormSection,
  reduxForm
} from 'redux-form';

import {
  validateFields,
  completeRegistration as validateCompleteRegistration } from 'utils/formValidations';
import NextButton from 'forms/fields/NextButton';
import EmailField from 'forms/fields/EmailInput';
import PasswordField from 'forms/fields/PasswordInput';
import NameField from 'forms/fields/NameInput';
import PhoneNumberField from 'forms/fields/PhoneNumberInput';

export class Register extends React.Component {
  render () {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <FormSection name="completeRegistration">
          <NameField />
          <EmailField />
          <PhoneNumberField />
          <PasswordField />
        </FormSection>
        <NextButton />
      </form>
    );
  }
}

export default reduxForm({
  form: 'completeRegistration',
  validate: validateFields({
    completeRegistration: validateCompleteRegistration
  })
})(Register);
