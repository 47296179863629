import Promise from 'bluebird';

import {track} from 'utils/track';

export function logIn (callback) {
  return values => {
    const {login} = values;
    return new Promise((resolve, reject) => {
      callback(login.email, login.password, resolve, reject);
      track('Login Page - Submit - Email');
    });
  };
}

export function passwordReset (callback) {
  return values => {
    const passwordResetObj = values.passwordReset;
    return new Promise((resolve, reject) => {
      callback(passwordResetObj.email, resolve, reject);
      track('Password Reset Page - Submit');
    });
  };
}

export function passwordChange (token, callback) {
  return values => {
    return new Promise((resolve, reject) => {
      callback(token, values.passwordChange.password, resolve, reject);
      track('Password Change Page - Submit');
    });
  };
}
