import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import classNames from 'classnames';
import Intercom from 'react-intercom';
import routes from 'routes/sitter';
import * as SVGIcon from 'components/SVGIcon';
import DevTools from 'containers/DevTools';
import Hud from 'components/Hud';
import Menu from 'containers/sitter/Menu';
import Modal from 'components/Modal';
import {toggleNav as toggleNavAction} from 'actions/navigation';
import {
  loadConstants as loadConstantsAction
} from 'actions/settings';
import {
  openModal as openModalAction,
  closeModal as closeModalAction
} from 'actions/modal';
import {
  showHud as showHudAction,
  completeHud as completeHudAction
} from 'actions/hud';
import {
  getHydrated,
  getIsAuthenticated,
  getUser,
  getStatus
} from 'selectors/session';

export class App extends React.Component {
  static propTypes = {
    hydrated: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    completeHud: PropTypes.func.isRequired,
    hud: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    loadConstants: PropTypes.func.isRequired,
    loading: PropTypes.object.isRequired,
    modal: PropTypes.object.isRequired,
    openModal: PropTypes.func.isRequired,
    user: PropTypes.object,
    status: PropTypes.object,
    showHud: PropTypes.func.isRequired,
    toggleNav: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired
  };

  componentDidMount () {
    console.log('Version', process.env.GIT_SHA1);
  }

  componentWillReceiveProps (newProps) {
    this.handleHudToggle(this.props.loading, newProps.loading);
  }

  handleHudToggle (loading, nextLoading) {
    const isLoading = Object.keys(loading).reduce((p, c) => (p || loading[c]), false);
    const isNextLoading = Object.keys(nextLoading).reduce((p, c) => (p || nextLoading[c]), false);
    if (isLoading && !isNextLoading) {
      this.props.completeHud();
    } else if (!isLoading && isNextLoading) {
      this.props.showHud({dark: false, title: 'Loading'});
    }
  }

  buildMenu () {
    if (!this.props.isAuthenticated) {
      return null;
    }
    return (
      <Menu />
    );
  }

  buildDevTools () {
    if (process.env.NODE_ENV !== 'development' || !process.env.DEV_TOOLS) {
      return null;
    }
    return (
      <DevTools />
    );
  }

  buildTitle (title) {
    const Logo = SVGIcon.Logo;
    const LogoText = SVGIcon.LogoText;
    const className = classNames({
      'c-header__logo': true,
      'c-header__logo--has-title': !!title
    });
    return (
      <div>
        <div {...{className}}>
          <a
              className="u-padding-small"
              href="//usetrusted.com"
          >
            <Logo active />
            <LogoText />
          </a>
        </div>
        {title && (<h1 className="c-header__title">{title}</h1>)}
      </div>
    );
  }

  handleNavClick = () => {
    this.props.toggleNav(!this.props.isOpen);
  }

  buildMenuIcon () {
    if (!this.props.isAuthenticated) {
      return null;
    }
    const className = classNames({
      'c-burger-menu-icon': true,
      'c-burger-menu-icon--active': this.props.isOpen
    });
    return (
      <div
          {...{className}}
          onClick={this.handleNavClick}
      >
        <div className="c-burger-menu-icon__clicky-bit" />
      </div>
    );
  }

  buildIntercom (userData) {
    let user = {
    };
    if (userData) {
      user = {
        user_id: `sitter_${userData.id}`, // eslint-disable-line camelcase
        email: userData.email,
        name: `${userData.first_name} ${userData.last_name}`
      };
    }
    return (
      <Intercom
          appID={process.env.INTERCOM_ID}
          {...user}
      />
    );
  }

  render () {
    const {
      closeModal,
      hud,
      modal,
      user,
      hydrated,
      isAuthenticated
    } = this.props;
    return (
      <div>
        {hydrated ? (
          <div>
            {this.buildMenuIcon()}
            {this.buildMenu()}
            <div className="o-wrapper">
              <div className="o-layout">
                <div className="o-layout__item u-1/1 u-center-text c-header">
                  {this.buildTitle(this.props.title)}
                </div>
              </div>
            </div>
            <div className="c-divider" />
            <div className="o-wrapper">
              {routes(this.props.isAuthenticated, this.props.status)}
              {this.buildDevTools()}
            </div>
          </div>
        ) : null}
        {isAuthenticated ? this.buildIntercom(user) : null}
        <Modal
            classes={modal.classes}
            closeModal={closeModal}
            component={modal.component}
            history={this.props.history}
            isOpened={modal.isModalOpen}
            location={this.props.location}
            match={this.props.match}
            options={modal.options}
            title={modal.title}
        />
        <Hud
            active={hud.isHudVisible}
            brand={hud.brand}
            complete={hud.complete}
            dark={hud.dark}
            title={hud.title}
        />
      </div>
    );
  }
}

function mapStateToProps (state, ownProps) {
  const {
    navigation: {
      isOpen
    },
    loading,
    modal,
    hud,
  } = state;
  return {
    isOpen,
    loading,
    user: getUser(state, ownProps),
    status: getStatus(state, ownProps),
    hud,
    modal,
    hydrated: getHydrated(state, ownProps),
    isAuthenticated: getIsAuthenticated(state, ownProps)
  };
}

const mapDispatchToProps = {
  openModal: openModalAction,
  completeHud: completeHudAction,
  toggleNav: toggleNavAction,
  showHud: showHudAction,
  loadConstants: loadConstantsAction,
  closeModal: closeModalAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
