import classNames from 'classnames';

export function formatTime (time) {
  return time.format('h:mma').slice(0, -1);
}

export default class CalendarEvent {
  static fromAvailability (availability) {
    const event = new CalendarEvent();
    event.id = availability.id;
    event.title = formatTime(availability.start) + '\n-\n' + formatTime(availability.end);
    event.startTitle = formatTime(availability.start);
    event.endTitle = formatTime(availability.end);
    event.start = availability.start;
    event.end = availability.end;
    event.className = classNames({
      'c-availability': true,
      'c-availability--booked': availability.booked,
      'c-availability--not-default': !availability.hasDefaultZones
    });
    event.availability = availability;
    return event;
  }

  static fromUnavailability (unavailability) {
    const event = new CalendarEvent();
    event.id = unavailability.id;
    event.title = formatTime(unavailability.start) + '\n-\n' + formatTime(unavailability.end);
    event.startTitle = formatTime(unavailability.start);
    event.endTitle = formatTime(unavailability.end);
    event.start = unavailability.start;
    event.end = unavailability.end;
    event.className = classNames({
      'c-unavailability': true
    });
    event.unavailability = unavailability;
    return event;
  }
}
