import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FormButton from 'forms/controls/Button';

export default class DoneButton extends React.Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    active: PropTypes.bool
  }

  render () {
    const className = classNames({
      'c-form-button--primary c-form-button--block': true,
      'c-form-button--inverse': !this.props.active
    });
    return (
      <FormButton
          label={this.props.children}
          onClick={this.props.onClick}
          type="button"
          {...{className}}
      />
    );
  }
}
