/* global FB */
import {EventEmitter} from 'events';
import Promise from 'bluebird';

const emitter = new EventEmitter();

window.fbAsyncInit = () => {
  FB.init({
    appId: process.env.FB_ID, // refactor, this is development key
    version: 'v2.8',
    xfbml: true
  });
  emitter.emit('ready');
};

if (process.env.NODE_ENV !== 'test') {
  (function (d, s, id) {
    const fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {return;}
    const js = d.createElement(s);
    js.id = id;
    js.src = '//connect.facebook.net/en_US/sdk.js';
    fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', 'facebook-jssdk'));
}

function ready () {
  return new Promise(resolve => {
    if (typeof FB === 'undefined') {
      return emitter.once('ready', () => {
        resolve();
      });
    } else {
      resolve();
    }
  });
}

export function getLoginStatus () {
  return new Promise(resolve => {
    ready().then(() => {
      FB.getLoginStatus(response => {
        switch (response.status) {
          case 'connected':
            resolve({error: null, accessToken: response.authResponse.accessToken});
            break;
          case 'not_authorized':
            resolve({error: response});
            break;
          default:
            resolve({error: response});
        }
      });
    });
  });
}

export function login () {
  return new Promise((resolve, reject) => {
    FB.login(response => {
      switch (response.status) {
        case 'connected':
          resolve({error: null, accessToken: response.authResponse.accessToken});
          break;
        default:
          reject(new Error(response));
      }
    }, {
      scope: 'email'
    });
  });
}

export function getProfile () {
  return new Promise((resolve, reject) => {
    FB.api('/me', {
      fields: 'first_name,last_name,email,picture.type(large)'
    }, response => {
      if (!response || response.error) {
        reject({error: response});
      } else {
        resolve({error: null, response});
      }
    });
  });
}
