import React from 'react';
import PropTypes from 'prop-types';
import Linkify from 'linkifyjs/react';

export default class ParentNoteView extends React.Component {
  static propTypes = {
    note: PropTypes.object.isRequired,
    isTrusted: PropTypes.bool
  }

  render () {
    const avatar = this.props.isTrusted ? '/icons/trusted-avatar@2x.png' : this.props.note.author.getPhotoUrl(64);
    const createdAt = this.props.isTrusted ? null : this.props.note.createdAt.format('MMM d, YYYY');
    return (
      <div className="c-parent-note-view">
        <img
            className="c-parent-note-view__avatar"
            src={avatar}
        />
        <div className="c-parent-note-view-details">
          <div className="c-parent-note-view-details__title">
            <div className="c-parent-note-view-details__name">{this.props.note.author.firstName}</div>
            <div className="c-parent-note-view-details__date">{createdAt}</div>
          </div>
          <div className="c-parent-note-view-details__body">
            <Linkify>{this.props.note.body}</Linkify>
          </div>
        </div>
      </div>
    );
  }
}
