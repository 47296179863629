import { StreamChat } from 'stream-chat';
import { getStreamToken } from 'actions/stream';
import { STREAM_TOKEN_FAILURE } from 'constants/stream';

export async function makeClient (authToken, user, dispatch) {
  const action = await dispatch(getStreamToken(authToken));

  if (action.type === STREAM_TOKEN_FAILURE) { return null; }

  const response = action.response.entities.stream_token[action.response.result];
  const token = response.stream_token;
  const streamApiKey = response.stream_api_key;

  const chatClient = new StreamChat(streamApiKey);

  const id = streamIdfor(user);
  const name = user.first_name;
  chatClient.setUser({ id, name }, token);

  return chatClient;
}

export function getChannel (streamClient, currentUser, otherUser) {
  const id = channelId(currentUser, otherUser);
  const name = channelName(currentUser, otherUser);
  const members = [
    streamIdfor(otherUser, process.env.APP_ROLE === 'parent' ? 'Sitter' : 'Parent'),
    streamIdfor(currentUser)
  ];
  return streamClient.channel('messaging', id, { name, members });
}

function channelId (currentUser, otherUser) {
  if (process.env.APP_ROLE === 'parent') {
    return `${streamIdfor(currentUser, 'Parent')}-${streamIdfor(otherUser, 'Sitter')}`;
  } else {
    return `${streamIdfor(otherUser, 'Parent')}-${streamIdfor(currentUser, 'Sitter')}`;
  }
}

export function streamIdfor (user, role = process.env.APP_ROLE === 'parent' ? 'Parent' : 'Sitter') {
  return `${role}_${user.id}`;
}

function channelName (currentUser, otherUser) {
  if (process.env.APP_ROLE === 'parent') {
    return `${currentUser.first_name} & ${otherUser.first_name}`;
  } else {
    return `${otherUser.first_name} & ${currentUser.first_name}`;
  }
}
