import React from 'react';

import FormField from 'forms/controls/FormField';
import {
  formatters,
  parsers
} from 'forms';

export default class ContactFields extends React.Component {
  render () {
    return (
      <div>
        <FormField
            fields={[
              {
                name: 'firstLastName',
                placeholder: 'First and Last Name',
                type: 'text'
              }
            ]}
            icon="Person"
        />
        <FormField
            fields={[
              {
                name: 'email',
                placeholder: 'Email Address',
                type: 'email'
              }
            ]}
            icon="Email"
        />
        <FormField
            fields={[
              {
                format: formatters.phone,
                name: 'phoneNumber',
                parse: parsers.phone,
                placeholder: 'Phone Number',
                type: 'tel'
              }
            ]}
            icon="Phone"
        />
      </div>
    );
  }
}
