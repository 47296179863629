import BaseProfile from 'models/Profile';
import Note from 'models/parent/Note';
import ChildProfile from 'models/ChildProfile';

export default class Profile extends BaseProfile {
  constructor (json = {}) {
    super(json);
    const children = json.child_profiles || [];
    this.children = children.map(c => { return new ChildProfile(c); });
    this.note = json.note ? Note.withTrustedBody(json.note) : undefined;
    this.authenticationToken = json.authentication_token;
  }
}
