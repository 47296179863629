import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class Textarea extends React.Component {
  static propTypes = {
    limit: PropTypes.number
  }

  onChange = () => {
    this.props.input.onChange(this.textarea.value);
  }

  render () {
    const className = classNames({
      'o-layout__item u-1/1 u-right-text c-text-small': true,
      'c-error-text': this.props.input.value.length > this.props.limit
    });
    return (
      <div className="o-layout">
        <div className="o-layout__item u-1/1">
          <textarea
              className={this.props.className}
              onChange={this.onChange}
              ref={c => { this.textarea = c; }}
              value={this.props.input.value}
          />
        </div>
        {!!this.props.limit && (
          <div {...{className}}>
            {`(${this.props.input.value.length} / ${this.props.limit})`}
          </div>
        )}
      </div>
    );
  }
}
