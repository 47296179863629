import {
  HUD_HIDE,
  HUD_SHOW,
  HUD_UPDATE
} from 'constants/hud';

export function getDefaultState () {
  return {
    isHudVisible: false,
    brand: false,
    dark: true,
    complete: false,
    title: null
  };
}

export default function (state = getDefaultState(), action) {
  if (action.type === HUD_SHOW) {
    const {
      options
    } = action;
    return {
      ...state,
      ...options,
      complete: false,
      isHudVisible: true
    };
  }
  if (action.type === HUD_UPDATE) {
    const {
      options
    } = action;
    return {
      ...state,
      ...options
    };
  }
  if (action.type === HUD_HIDE) {
    return {
      ...state,
      isHudVisible: false,
    };
  }
  return state;
}
