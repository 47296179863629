import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export default class WeekSchedule extends React.Component {
  static propTypes = {
    activeDays: PropTypes.array.isRequired
  };

  static options = {
    sunday: 'S',
    monday: 'M',
    tuesday: 'T',
    wednesday: 'W',
    thursday: 'T',
    friday: 'F',
    saturday: 'S'
  };

  render () {
    const lookup = this.props.activeDays.reduce((p, c) => ({...p, [c]: true}), {
      sunday: false,
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false
    });
    return (
      <ul className="o-list-inline c-week-schedule">
        {Object.keys(WeekSchedule.options).map((i, key) => {
          const className = classNames({
            'o-list-inline__item': true,
            'c-week-schedule__item': true,
            'c-week-schedule__item--active': lookup[i]
          });
          return (
            <li {...{className, key}}>{WeekSchedule.options[i]}</li>
          );
        })}
      </ul>
    );
  }
}
