export const LOAD_ONE_TIME_SEARCHES_REQUEST = 'LOAD_ONE_TIME_SEARCHES_REQUEST';
export const LOAD_ONE_TIME_SEARCHES_SUCCESS = 'LOAD_ONE_TIME_SEARCHES_SUCCESS';
export const LOAD_ONE_TIME_SEARCHES_FAILURE = 'LOAD_ONE_TIME_SEARCHES_FAILURE';
export const LOAD_RECURRING_SEARCHES_REQUEST = 'LOAD_RECURRING_SEARCHES_REQUEST';
export const LOAD_RECURRING_SEARCHES_SUCCESS = 'LOAD_RECURRING_SEARCHES_SUCCESS';
export const LOAD_RECURRING_SEARCHES_FAILURE = 'LOAD_RECURRING_SEARCHES_FAILURE';
export const LOAD_INTERESTED_SEARCHES_REQUEST = 'LOAD_INTERESTED_SEARCHES_REQUEST';
export const LOAD_INTERESTED_SEARCHES_SUCCESS = 'LOAD_INTERESTED_SEARCHES_SUCCESS';
export const LOAD_INTERESTED_SEARCHES_FAILURE = 'LOAD_INTERESTED_SEARCHES_FAILURE';
export const INTERESTED_SEARCH_REQUEST = 'INTERESTED_SEARCH_REQUEST';
export const INTERESTED_SEARCH_SUCCESS = 'INTERESTED_SEARCH_SUCCESS';
export const INTERESTED_SEARCH_FAILURE = 'INTERESTED_SEARCH_FAILURE';
export const RESPOND_ONE_TIME_REQUEST = 'RESPOND_ONE_TIME_REQUEST';
export const RESPOND_ONE_TIME_SUCCESS = 'RESPOND_ONE_TIME_SUCCESS';
export const RESPOND_ONE_TIME_FAILURE = 'RESPOND_ONE_TIME_FAILURE';
export const RESPOND_RECURRING_REQUEST = 'RESPOND_RECURRING_REQUEST';
export const RESPOND_RECURRING_SUCCESS = 'RESPOND_RECURRING_SUCCESS';
export const RESPOND_RECURRING_FAILURE = 'RESPOND_RECURRING_FAILURE';
export const RESPOND_INTERESTED_REQUEST = 'RESPOND_INTERESTED_REQUEST';
export const RESPOND_INTERESTED_SUCCESS = 'RESPOND_INTERESTED_SUCCESS';
export const RESPOND_INTERESTED_FAILURE = 'RESPOND_INTERESTED_FAILURE';
export const RESPOND_BOOKING_REQUEST = 'RESPOND_BOOKING_REQUEST';
export const RESPOND_BOOKING_SUCCESS = 'RESPOND_BOOKING_SUCCESS';
export const RESPOND_BOOKING_FAILURE = 'RESPOND_BOOKING_FAILURE';
export const LOAD_ALL_SEARCHES_REQUEST = 'LOAD_ALL_SEARCHES_REQUEST';
export const LOAD_ALL_SEARCHES_SUCCESS = 'LOAD_ALL_SEARCHES_SUCCESS';
export const LOAD_ALL_SEARCHES_FAILURE = 'LOAD_ALL_SEARCHES_FAILURE';
export const RESPOND_ALL_REQUEST = 'RESPOND_ALL_REQUEST';
export const RESPOND_ALL_SUCCESS = 'RESPOND_ALL_SUCCESS';
export const RESPOND_ALL_FAILURE = 'RESPOND_ALL_FAILURE';
export const SET_SEARCH_CURRENT_PAGE = 'SET_SEARCH_CURRENT_PAGE';
export const INTRO_MODAL_SEEN = 'openSearchesIntroModalSeen';
export const SHOW_INTRO_MODAL = 'SEARCH_SHOW_INTRO_MODAL';
export const SHOW_IN_TOUCH_MODAL = 'SEARCH_SHOW_IN_TOUCH_MODAL';
export const IN_TOUCH_MODAL_SEEN = 'inTouchModalSeen';
