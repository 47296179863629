import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';

import ContactFields from 'forms/fields/Contact';
import FormField from 'forms/controls/FormField';

export default class RegitrationFields extends React.Component {
  static propTypes = {
    requireTerms: PropTypes.bool
  };

  constructor (props) {
    super(props);
    this.renderTermsCheckbox = this.buildTermsCheckbox();
    this.renderAgeCheckbox = this.buildAgeCheckbox();
  }

  buildTermsCopy (props) {
    return (
      <label htmlFor={`checkbox-${props.input.name}`}>
        {'I agree to the '}
        <a
            href="http://usetrusted.com/terms"
            target="_blank"
        >{'Terms\u00a0of\u00a0Service'}</a>
        {' and '}
        <a
            href="http://usetrusted.com/privacy"
            target="_blank"
        >{'Privacy\u00a0Policy'}</a>
      </label>
    );
  }

  buildTermsCheckbox () {
    return props => {
      return (
        <div className="c-form-field-checkbox">
          <div className="c-form-field-checkbox__control">
            <input
                checked={!!props.input.value}
                id={`checkbox-${props.input.name}`}
                name={props.input.name}
                onChange={props.input.onChange}
                type="checkbox"
            />
          </div>
          <div className="c-form-field-checkbox__label c-text-small c-text-small--muted">{this.buildTermsCopy(props)}</div>
        </div>
      );
    };
  }

  buildAgeCheckbox () {
    return props => {
      return (
        <div className="c-form-field-checkbox">
          <div className="c-form-field-checkbox__control">
            <input
                checked={!!props.input.value}
                id={`checkbox-${props.input.name}`}
                name={props.input.name}
                onChange={props.input.onChange}
                type="checkbox"
            />
          </div>
          <div className="c-form-field-checkbox__label c-text-small c-text-small--muted">I am at least 18 years of age</div>
        </div>
      );
    };
  }

  render () {
    const {requireTerms} = this.props;
    return (
      <div>
        <ContactFields />
        <FormField
            fields={[
              {
                name: 'password',
                placeholder: 'Password',
                type: 'password'
              }
            ]}
            icon="Password"
        />
        {requireTerms && process.env.APP_ROLE === 'sitter' && (
          <div className="u-margin-bottom">
            <Field
                component={this.renderAgeCheckbox}
                name="acceptAge"
            />
          </div>)
        }
        {requireTerms && (
          <div className="u-margin-bottom">
            <Field
                component={this.renderTermsCheckbox}
                name="acceptedTerms"
            />
          </div>
        )}
      </div>
    );
  }
}
