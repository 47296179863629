import React from 'react';

import FormField from 'forms/controls/FormField';
import {formatters, parsers} from 'forms';

export default class PhoneNumberInputField extends React.Component {
  render () {
    return (
      <div>
        <FormField
            fields={[
              {
                format: formatters.phone,
                name: 'phoneNumber',
                parse: parsers.phone,
                placeholder: 'Phone Number',
                type: 'tel'
              }
            ]}
            icon="Phone"
        />
      </div>
    );
  }
}
