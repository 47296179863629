import React from 'react';
import PropTypes from 'prop-types';

import ContactView from 'components/sitter/ContactView';

export default class EmergencyContactsView extends React.Component {
  static propTypes = {
    profile: PropTypes.object.isRequired
  }

  render () {
    return (
      <div>
        {!!this.props.profile.emergencyContacts.length && (
          <div className="c-profile-emergency-contacts-view">
            <h4>{'Emergency Contacts'}</h4>
            {this.props.profile.emergencyContacts.map((contact, key) => (
              <ContactView {...{contact, key}} />
            ))}
          </div>
        )}
      </div>
    );
  }
}
