import React from 'react';
import {Field} from 'redux-form';
import PropTypes from 'prop-types';

import CoverageZonesControl from 'forms/controls/CoverageZones';

export default class CoverageZonesPicker extends React.Component {
  static propTypes = {
    coverageZones: PropTypes.array,
    isEditable: PropTypes.bool.isRequired
  }

  render () {
    return (
      <Field
          component={CoverageZonesControl}
          coverageZones={this.props.coverageZones}
          isEditable={this.props.isEditable}
          name="coverageZones"
      />
    );
  }
}
