import React from 'react';
import {
  FormSection,
  reduxForm
} from 'redux-form';

import FormButton from 'forms/controls/FormButton';
import PasswordResetFields from 'forms/fields/PasswordReset';
import {
  validateFields,
  validatePasswordReset
} from 'utils/formValidations';

export class PasswordResetComponent extends React.Component {
  static propTypes = {
  };

  render () {
    const {
      handleSubmit
    } = this.props;
    return (
      <div>
        <form onSubmit={handleSubmit}>
          <FormSection name="passwordReset">
            <PasswordResetFields />
          </FormSection>
          <div>
            <FormButton
                className="c-form-button--primary c-form-button--block"
                label="Submit"
                type="submit"
            />
          </div>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'passwordReset',
  validate: validateFields({
    passwordReset: validatePasswordReset
  })
})(PasswordResetComponent);
