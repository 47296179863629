import React from 'react';
import accounting from 'accounting';
import moment from 'moment';
import haversine from 'haversine';
import classNames from 'classnames';

import {ageAtDate} from 'utils/childAge';
import WeekSchedule from 'components/WeekSchedule';
import Button from 'forms/controls/Button';
import * as SVGIcon from 'components/SVGIcon';
import PropTypes from 'prop-types';

export default class SearchItem extends React.Component {
  static propTypes = {
    interested: PropTypes.bool.isRequired,
    geolocation: PropTypes.object.isRequired,
    search: PropTypes.object.isRequired,
    onInterest: PropTypes.func.isRequired,
    onReject: PropTypes.func.isRequired,
    copy: PropTypes.object.isRequired,
    features: PropTypes.object.isRequired,
  }

  render () {
    const multiplier = Math.floor((this.props.search.sitter_multiplier - 1) * 100);
    const activeDays = this.props.search.recurrence ? this.props.search.recurrence.days_of_week : [moment(this.props.search.start_time).format('dddd').toLowerCase()];
    const title = this.props.search.recurrence ? 'Recurring' : 'One-Time';
    const haversineDistance = haversine({
      latitude: this.props.search.location.geolocation.lat,
      longitude: this.props.search.location.geolocation.lng
    }, {
      latitude: this.props.geolocation.lat,
      longitude: this.props.geolocation.lng
    }, {unit: 'mile'});
    const distance = `${accounting.formatNumber(haversineDistance, 2)}mi`;
    let selectedDays = moment(this.props.search.start_time).format('MMM Do');
    if (this.props.search.recurrence) {
      const {recurrence} = this.props.search;
      const startPart = recurrence.start_date ? moment(recurrence.start_date).format('MMM Do') : 'ASAP';
      const endPart = recurrence.end_date ? moment(recurrence.end_date).format('MMM Do') : 'Ongoing';
      selectedDays = `${startPart} - ${endPart}`;
    }
    const selectedTimes = `${moment(this.props.search.start_time).format('HH:mm')} - ${moment(this.props.search.end_time).format('HH:mm')}`;
    const bookingDuration = moment.duration(moment(this.props.search.end_time).diff(moment(this.props.search.start_time)));
    let durationCopy = `${bookingDuration.hours()} hours`;
    if (bookingDuration.minutes() > 0) {
      durationCopy = `${bookingDuration.hours()}h ${bookingDuration.minutes()}m`;
    }
    const className = classNames({
      'c-panel': true,
      'c-open-search-item': true,
      [`c-open-search-item--${this.props.search.recurrence ? 'recurring' : 'one-time'}`]: true,
      'c-open-search-item--bonus': multiplier > 0
    });
    let interestedTitle = 'Yes, I\'m Available';
    if (this.props.features.search_to_book && this.props.search.instant_book) {
      interestedTitle = this.props.copy.searches.respond.interested.one_time.action;
    }

    return (
      <div {...{className}}>
        <div className="c-panel__masthead c-panel__masthead--fluid">
          <div className="o-layout">
            <div className="o-layout__item u-1/2">
              <h4 className="c-h4 c-panel__title c-open-search-item__colored">{title} {(multiplier > 0) && (<span className="u-margin-left-tiny"><SVGIcon.Flame active /></span>)}</h4>
            </div>
            <div className="o-layout__item u-1/2 u-right-text c-text-smaller">
              <div className="c-open-search-item__colored">
                <ul className="o-list-inline u-margin-none">
                  {(multiplier > 0) && (
                    <li className="o-list-inline__item u-margin-right-small">{`Earn +${multiplier}%`}</li>
                  )}
                  <li className="o-list-inline__item"><strong>{`$${accounting.formatNumber(this.props.search.sitter_total, 2)}`}{this.props.search.recurrence && ' / week'}</strong></li>
                </ul>
              </div>
              <div>{'Estimated Earnings'}</div>
            </div>
          </div>
        </div>
        <div className="c-panel__content">
          <WeekSchedule {...{activeDays}} />
          <div className="o-media u-margin-bottom-small">
            <div className="o-media__img c-form-field__img">
              <SVGIcon.Pin />
            </div>
            <div className="o-media__body">
              <a
                  className="c-inline-list-link"
                  href={`https://google.com/maps/?q=${this.props.search.location.geolocation.lat},${this.props.search.location.geolocation.lng}`}
                  target="_blank"
              >
                <ul className="o-list-inline o-list-inline--dotted">
                  <li className="o-list-inline__item">
                    {`${this.props.search.location.street}, ${this.props.search.location.city}, ${this.props.search.location.state} ${this.props.search.location.zip_code}`}
                  </li>
                  <li className="o-list-inline__item">{distance}</li>
                </ul>
              </a>
            </div>
          </div>
          <div className="o-media u-margin-bottom-small">
            <div className="o-media__img c-form-field__img">
              <SVGIcon.Calendar />
            </div>
            <div className="o-media__body">
              <ul className="o-list-inline o-list-inline--dotted">
                <li className="o-list-inline__item">{selectedDays}</li>
              </ul>
            </div>
          </div>
          <div className="o-media u-margin-bottom-small">
            <div className="o-media__img c-form-field__img">
              <SVGIcon.Clock />
            </div>
            <div className="o-media__body">
              <ul className="o-list-inline o-list-inline--dotted">
                <li className="o-list-inline__item">{selectedTimes}</li>
                <li className="o-list-inline__item">{durationCopy}</li>
              </ul>
            </div>
          </div>
          <div className="o-media u-margin-bottom-small">
            <div className="o-media__img c-form-field__img">
              <SVGIcon.ChildInfant />
            </div>
            <div className="o-media__body">
              <ul className="o-list-inline o-list-inline--dotted">
                {this.props.search.child_profiles.map(p => (
                  <li
                      className="o-list-inline__item"
                      key={p.hash_id}
                  >{ageAtDate(p.date_of_birth, new Date())}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="o-media u-margin-bottom-small">
            <div className="o-media__img c-form-field__img">
              <SVGIcon.Notes />
            </div>
            <div className="o-media__body">
              <ul className="o-list-inline o-list-inline--dotted">
                {this.props.search.parent_note && (
                  <li
                      className="o-list-inline__item"
                  >{this.props.search.parent_note}</li>
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="c-panel__link">
          {this.props.interested && (
            <div className="o-layout u-margin-small">
              <div className="o-layout__item u-1/1 u-center-text c-text-small--muted">{'Pending Approval'}</div>
            </div>
          )}
          {!this.props.interested && this.props.user && this.props.user.status && this.props.user.status.live && (
            <div className="o-layout">
              <div className="o-layout__item u-1/2">
                <Button
                    className="c-form-button--primary c-form-button--destructive c-form-button--block c-form-button--inverse"
                    label="No, I'm Unavailable"
                    onClick={() => {
                      this.props.onReject(this.props.search);
                    }}
                    type="button"
                />
              </div>
              <div className="o-layout__item u-1/2">
                <Button
                    className="c-form-button--primary c-form-button--block c-form-button--inverse"
                    label={interestedTitle}
                    onClick={() => {
                      this.props.onInterest(this.props.search);
                    }}
                    type="button"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
