// refactor, move everything to `forms/index`
import cardValidator from 'card-validator';
import moment from 'moment';

export function validateFields (validators, requiredFields = {}) {
  return values => {
    const validationErrors = Object.keys(validators).map(name => ({
      name, // 5625463739
      error: validators[name](values[name])
    })).reduce((p, {name, error}) => (
      Object.keys(name).length ? {...p, [name]: error} : p
    ), {});
    Object.keys(requiredFields).forEach(fieldName => {
      Object.assign(validationErrors[fieldName], requiredFields[fieldName](values[fieldName]));
    });
    return validationErrors;
  };
}

export function validateFieldArray (validator/* , errorMessage */) { // refactor, this can display 'at least one item is required' messages with a little more work
  return values => {
    if (!values) {
      return [];
    }
    const errors = [];
    values.forEach((member, index) => {
      const memberErrors = validator(member);
      if (Object.keys(memberErrors).length) {
        errors[index] = memberErrors;
      }
    });
    return errors;
  };
}

export function validatePasswordReset (values) {
  return {
    ...validateEmail(values)
  };
}

export function validateReasonFeedback (values) {
  const errors = {};
  if (!values || !values.feedback) {
    errors.feedback = 'Required';
  }
  return errors;
}

export function validatePaymentMethod (values) {
  const errors = {};
  if (!values || !values.cardNumber) {
    errors.cardNumber = 'Required';
  } else if (!cardValidator.number(values.cardNumber).isValid) {
    errors.cardNumber = 'Invalid';
  }
  if (!values || !values.expirationDate) {
    errors.expirationDate = 'Expiration date required';
  } else if (!cardValidator.expirationDate(values.expirationDate).isValid) {
    errors.expirationDate = 'Expiration date invalid';
  }
  if (!values || !values.securityCode) {
    errors.securityCode = 'Security code required';
  } else if (values.cardNumber) {
    const {card} = cardValidator.number(values.cardNumber);
    if (card && !cardValidator.cvv(values.securityCode, card.code.size).isValid) {
      errors.securityCode = 'Security code invalid';
    }
  }
  if (!values || !values.zipCode) {
    errors.zipCode = 'Zip code required';
  } else if (/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(values.zipCode) === false) {
    errors.zipCode = 'Zip code invalid';
  }
  return errors;
}

export function validateDiscountCode (values) {
  const errors = {};
  if (!values || !values.code) {
    errors.code = 'Required';
  }
  return errors;
}

export function validateProviderReview (values) {
  const errors = {};
  if (!values || !values.rating) {
    errors.rating = 'Required';
  }
  return errors;
}

export function validateChildren (values) {
  const errors = {};
  if (!values) {
    errors.gender = 'Required';
  }
  if (!values || !values.name) {
    errors.name = 'Required';
  }
  if (!values || !values.birthday) {
    errors.birthday = 'Required';
  } else if (moment(values.birthday, 'YYYY-MM-DD').isAfter(moment().subtract(1, 'days'))) {
    errors.birthday = 'Must be a date in the past';
  }
  return errors;
}

export function validateSearch (values) { // refactor, improve these checks
  const errors = {};
  if (!values || !values.children || !values.children.length) {
    errors.children = 'Required';
  }
  if (!values || !values.schedule || !values.schedule.targetDate) {
    if (!errors.schedule) {
      errors.schedule = {};
    }
    errors.schedule.targetDate = 'Target date required';
  }
  if (!values || !values.schedule || !values.schedule.startTime) {
    if (!errors.schedule) {
      errors.schedule = {};
    }
    errors.schedule.startTime = 'Start time required';
  }
  if (!values || !values.schedule || !values.schedule.endTime) {
    if (!errors.schedule) {
      errors.schedule = {};
    }
    errors.schedule.endTime = 'End time required';
  }
  if (!values || !values.address || !Object.keys(values.address).length) {
    errors.address = 'Please select a valid address';
  }
  return errors;
}

export function validateHomeAddress (values) {
  const errors = {};
  if (!values || !values.address || values.address === JSON.stringify({})) {
    errors.address = 'Required';
  }
  return errors;
}

export function validateLogin (values) {
  return {
    ...passwordValidation(values),
    ...validateEmail(values)
  };
}

export function validateZip (values) {
  const errors = {};
  if (!values || !values.zip) {
    errors.zip = 'Required';
  } else if (/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(values.zip) === false) {
    errors.zip = 'Invalid';
  }
  return errors;
}

export function validateWaitList (values) {
  return {
    ...validateEmail(values)
  };
}

export function validatePasswordRecover (values) {
  const errors = {};
  if (!values || !values.password || !values.passwordConfirm || values.password !== values.passwordConfirm) {
    errors.passwordConfirm = 'Passwords do not match';
  }
  return errors;
}

export function validatePasswordChange (values) {
  const errors = {};
  if (values && values.password && values.password !== values.passwordConfirm) {
    errors.passwordConfirm = 'Passwords do not match';
  }
  return errors;
}

export function validateContact (values) {
  return {
    ...validateEmail(values),
    ...firstLastName(values),
    ...phoneNumber(values)
  };
}

export function firstLastName (values) {
  const errors = {};
  if (!values || !values.firstLastName) {
    errors.firstLastName = 'Required';
  } else if (values.firstLastName.match(/\w+/g).length < 2) {
    errors.firstLastName = 'Both a first and last name are required';
  }
  return errors;
}

export function validateRegistration (requireTerms = true) {
  return values => {
    const errors = validateContact(values);
    if (requireTerms && (!values || !values.acceptedTerms)) {
      errors.acceptedTerms = 'Required';
    }
    if (requireTerms && (!values || !values.acceptAge)) {
      errors.acceptAge = 'Required';
    }
    if (!values || !values.password) {
      errors.password = 'Required';
    } else if (values.password.length < 6) {
      errors.password = 'Minimum of 6 characters required';
    }
    return errors;
  };
}

export function phoneNumber (values) {
  const errors = {};
  if (!values || !values.phoneNumber) {
    errors.phoneNumber = 'Required';
  } else if (values.phoneNumber.length < 10) {
    errors.phoneNumber = 'Invalid';
  }
  return errors;
}

export function email (emailAddress) {
  if (!emailAddress) {
    return 'Required';
  }
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,16}$/i.test(emailAddress)) {
    return 'Invalid';
  }
}

export function validateEmail (values) {
  const errors = {};
  if (!values || !values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,16}$/i.test(values.email)) {
    errors.email = 'Invalid';
  }
  return errors;
}

export const passwordValidation = values => {
  const errors = {};
  if (!values || !values.password) {
    errors.password = 'Required';
  }
  return errors;
};

export function password (pass) {
  if (!pass) {
    return 'Required';
  }
}

export function cardNumber (numberValue) {
  if (!numberValue) {
    return 'Required';
  }
  if (!cardValidator.number(numberValue).isValid) {
    return 'Invalid';
  }
}

export function cvv (cvvValue) {
  if (!cvvValue) {
    return 'required';
  }
  if (!cardValidator.cvv(cvvValue).isValid) {
    return 'Invalid';
  }
}

export function expiration (expirationValue) {
  if (!expirationValue) {
    return 'Required';
  }
  if (!cardValidator.expirationDate(expirationValue).isValid) {
    return 'Invalid';
  }
}

export function zipCode (zip) {
  if (!zip) {
    return 'Required';
  }
  if (!cardValidator.postalCode(zip).isValid) {
    return 'Invalid';
  }
}

export const coverageZones = values => {
  const error = {};
  if (!values || !values.coverageZones || !values.coverageZones.length) {
    error.coverageZones = 'At least one zone is required';
  }
  return error;
};

export const schedule = values => {
  let error = {};
  return {
    ...error,
  };
};

export const afterAdd = values => {
  const error = {};
  if (!values || !values.accepted) {
    error.accepted = 'Required';
  }
  return error;
};

export const completeRegistration = values => {
  return {
    ...adultAge(values),
    ...firstLastName(values),
    ...validateEmail(values),
    ...phoneNumber(values),
    ...passwordValidation(values)
  };
};

export const adultAge = values => {
  const errors = {};
  if (!values || !values.adultAge) {
    errors.adultAge = 'You must be at least 18 years of age';
  }
  return errors;
};
