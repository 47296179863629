import React from 'react';
import PropTypes from 'prop-types';

export default class AvailabilityCalendarControls extends React.Component {
  static propTypes = {
    onPrev: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired
  };

  render () {
    return (
      <div className="c-availability-calendar-controls">
        <button
            className="c-availability-calendar-controls__control c-availability-calendar-controls__prev"
            onClick={this.props.onPrev}
            type="button"
        >
          <div className="c-symbol">
            <span className="c-symbol__chevron c-symbol__chevron--left" />
          </div>
        </button>
        <button
            className="c-availability-calendar-controls__control c-availability-calendar-controls__next"
            onClick={this.props.onNext}
            type="button"
        >
          <div className="c-symbol">
            <span className="c-symbol__chevron c-symbol__chevron--right" />
          </div>
        </button>
      </div>
    );
  }
}
