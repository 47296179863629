import React from 'react';
import jQuery from 'jquery';
import 'fullcalendar';
import 'fullcalendar/dist/fullcalendar.css';
import PropTypes from 'prop-types';

import AvailabilityCalendarControls from 'components/AvailabilityCalendarControls';

export default class AvailabilityCalendar extends React.Component {
  static propTypes = {
    minDate: PropTypes.object.isRequired,
    maxDate: PropTypes.object.isRequired,
    onEventClick: PropTypes.func.isRequired,
    onDayClick: PropTypes.func.isRequired,
    options: PropTypes.object.isRequired
  };

  componentDidMount () {
    const eventSources = [{
      events: (start, end, timezone, callback) => {
        if (this.calendarApi) {
          this.calendarApi.fullCalendar('removeEvents');
        }
        callback(this.props.options.events);
      }
    }];
    this.calendarApi = jQuery(this.calendar).fullCalendar({
      ...this.props.options,
      eventClick: this.handleEventClick,
      dayClick: this.handleDayClick,
      eventSources
    });
  }

  componentDidUpdate () {
    this.calendarApi.fullCalendar('refetchEvents');
  }

  handleEventClick = event => {
    this.props.onEventClick(event);
  };

  handleDayClick = (date, view) => {
    if (view.name === 'month') {
      this.calendarApi.fullCalendar('changeView', 'agendaWeek');
      this.calendarApi.fullCalendar('gotoDate', date);
      return;
    }
    this.props.onDayClick(date);
  };

  render () {
    return (
      <div>
        <AvailabilityCalendarControls
            onNext={() => {
              if (this.calendarApi.fullCalendar('getView').end.isBefore(this.props.maxDate)) {
                this.calendarApi.fullCalendar('next');
              }
            }}
            onPrev={() => {
              if (this.calendarApi.fullCalendar('getView').start.isAfter(this.props.minDate)) {
                this.calendarApi.fullCalendar('prev');
              }
            }}
        />
        <div
            className="c-availability-calendar"
            ref={i => { this.calendar = i; }}
        />
      </div>
    );
  }
}
