import {
  call,
  put,
  takeEvery
} from 'redux-saga/effects';

import {LOGIN_PAGE} from 'constants/sitter/navigation';
import {LOG_OUT} from 'constants/logOut';
import {REMOVE_AUTH_TOKEN} from 'constants/session';

export function *logOut () {
  yield takeEvery(LOG_OUT, logOutEffect);
}

export function *logOutEffect (action) {
  yield put({type: REMOVE_AUTH_TOKEN});
  yield call(action.context.history.push, `/${LOGIN_PAGE}`);
}
