import {
  HEALTH_CHECK,
  HEALTH_CHECK_FOLLOW_UP,
  HEALTH_CHECK_PASS,
  HEALTH_CHECK_FAIL
} from 'constants/healthCheck'

export function healthCheck (onPass) {
  return {
    type: HEALTH_CHECK,
    onPass
  }
}
