import {createSelector} from 'reselect';

import {
  getZipCode,
  getPhoneNumber,
  getLastName,
  getFirstName,
  getEmail
} from 'selectors/session';

import {
  getFteFormUrl
} from 'selectors/settings';

export const getTypeformOptions = createSelector(
  [getFirstName, getLastName, getEmail, getPhoneNumber, getZipCode, getFteFormUrl],
  (firstName, lastName, email, phoneNumber, zipCode, formUrl) => ({
    firstName,
    lastName,
    email,
    phoneNumber,
    zipCode,
    formUrl: fteFormUrl(firstName, lastName, email, phoneNumber, zipCode, formUrl)
  })
);

const fteFormUrl = (firstName, lastName, email, phoneNumber, zipCode, formUrl) => {
  return formUrl
    .replace(/:first_name/g, encodeURIComponent(firstName))
    .replace(/:last_name/g, encodeURIComponent(lastName))
    .replace(/:email/g, encodeURIComponent(email))
    .replace(/:phone_number/g, encodeURIComponent(phoneNumber))
    .replace(/:zip_code/g, encodeURIComponent(zipCode));
};
