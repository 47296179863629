export const PROVIDER_ADD_AVAILABILITY_SUCCESS = 'PROVIDER_ADD_AVAILABILITY_SUCCESS';
export const PROVIDER_ADD_AVAILABILITY_REQUEST = 'PROVIDER_ADD_AVAILABILITY_REQUEST';
export const PROVIDER_ADD_AVAILABILITY_FAILURE = 'PROVIDER_ADD_AVAILABILITY_FAILURE';
export const PROVIDER_ADD_UNAVAILABILITY_REQUEST = 'PROVIDER_ADD_UNAVAILABILITY_REQUEST';
export const PROVIDER_ADD_UNAVAILABILITY_SUCCESS = 'PROVIDER_ADD_UNAVAILABILITY_SUCCESS';
export const PROVIDER_ADD_UNAVAILABILITY_FAILURE = 'PROVIDER_ADD_UNAVAILABILITY_FAILURE';
export const PROVIDER_DELETE_AVAILABILITY_SUCCESS = 'PROVIDER_DELETE_AVAILABILITY_SUCCESS';
export const PROVIDER_DELETE_AVAILABILITY_REQUEST = 'PROVIDER_DELETE_AVAILABILITY_REQUEST';
export const PROVIDER_DELETE_AVAILABILITY_FAILURE = 'PROVIDER_DELETE_AVAILABILITY_FAILURE';
export const PROVIDER_COVERAGE_ZONES_SUCCESS = 'PROVIDER_COVERAGE_ZONES_SUCCESS';
export const PROVIDER_COVERAGE_ZONES_REQUEST = 'PROVIDER_COVERAGE_ZONES_REQUEST';
export const PROVIDER_COVERAGE_ZONES_FAILURE = 'PROVIDER_COVERAGE_ZONES_FAILURE';
export const PROVIDER_ALL_COVERAGE_ZONES_SUCCESS = 'PROVIDER_ALL_COVERAGE_ZONES_SUCCESS';
export const PROVIDER_ALL_COVERAGE_ZONES_REQUEST = 'PROVIDER_ALL_COVERAGE_ZONES_REQUEST';
export const PROVIDER_ALL_COVERAGE_ZONES_FAILURE = 'PROVIDER_ALL_COVERAGE_ZONES_FAILURE';
export const ADD_TIME_KEY = 'PROVIDER_LAST_AVAILABILITY_ADD_TIME';
export const AVAILABILITIES_REQUEST = 'AVAILABITIES_REQUEST';
export const AVAILABILITIES_SUCCESS = 'AVAILABITIES_SUCCESS';
export const AVAILABILITIES_FAILURE = 'AVAILABITIES_FAILURE';
export const UNAVAILABILITIES_REQUEST = 'UNAVAILABITIES_REQUEST';
export const UNAVAILABILITIES_SUCCESS = 'UNAVAILABITIES_SUCCESS';
export const UNAVAILABILITIES_FAILURE = 'UNAVAILABITIES_FAILURE';
export const PROVIDER_LOAD_SCHEDULE = 'PROVIDER_LOAD_SCHEDULE';
