import React from 'react';
import Markdown from 'react-markdown';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {closeModal as closeModalAction} from 'actions/modal';
import Button from 'forms/controls/Button';

export class SearchIntroModal extends React.Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    copy: PropTypes.object.isRequired
  }

  render () {
    return (
      <div>
        <div className="u-center-text">
          <Markdown source={this.props.copy.body_markdown} />
        </div>
        <Button
            className="c-form-button--block c-form-button--primary c-form-button--inverse"
            label={this.props.copy.action}
            onClick={() => this.props.closeModal()}
            type="button"
        />
      </div>
    );
  }
}

export const makeMapStateToProps = () => {
  return () => {
    return {
    };
  };
};

export default connect(makeMapStateToProps, {
  closeModal: closeModalAction
})(SearchIntroModal);
