import React from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import {closeModal as closeModalAction} from 'actions/modal'
import ConfirmButton from 'forms/fields/ConfirmButton'
import DenyButton from 'forms/fields/DenyButton'

export class ConfirmModal extends React.Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
    onConfirm: PropTypes.func,
    onDeny: PropTypes.func,
    confirmText: PropTypes.string
  }

  confirmAction () {
    if (this.props.onConfirm) {
      this.props.onConfirm()
    }
    this.props.closeModal()
  }

  denyAction () {
    if (this.props.onDeny) {
      this.props.onDeny()
    }
    this.props.closeModal()
  }

  render () {
    return (
      <div className="o-layout">
        <div className="o-layout__item u-1/1">
          <p className="u-center-text">{this.props.message}</p>
          <ConfirmButton
              onClick={() => this.confirmAction()}
              title={this.props.confirmText}
          />
          {this.props.onDeny ? (
            <DenyButton onClick={() => this.denyAction()} />
          ) : null}
        </div>
      </div>
    )
  }
}

export const makeMapStateToProps = () => {
  return () => {
    return {
    }
  }
}

const mapDispatchToProps = {
  closeModal: closeModalAction
}

export default connect(makeMapStateToProps, mapDispatchToProps)(ConfirmModal)
