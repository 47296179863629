import {createSelector} from 'reselect';

export const getLoading = state => state.loading;

export const getSearchLoading = createSelector(
  [getLoading],
  loading => loading.search
);

export const getBookingRequestLoading = createSelector(
  [getLoading],
  loading => loading.bookingRequest
);

export const getRegistrationLoading = createSelector(
  [getLoading],
  loading => loading.registration
);
