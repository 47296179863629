import moment from 'moment';

export default class TimeService {
  constructor (constants, date = moment()) {
    this.constants = constants;
    this.date = date;
  }

  dateStart (options) {
    options = Object.assign({}, { // eslint-disable-line no-param-reassign
      date: moment(this.date),
      dayStart: this.constants.availability.dayStart
    }, options);
    return moment(options.date).startOf('day').add({seconds: options.dayStart});
  }

  dateEnd (options) {
    options = Object.assign({}, { // eslint-disable-line no-param-reassign
      date: moment(this.date),
      dayEnd: this.constants.availability.dayEnd
    }, options);
    return moment(options.date).startOf('day').add({seconds: options.dayEnd});
  }

  dateDefaultStart (options) {
    options = Object.assign({}, { // eslint-disable-line no-param-reassign
      date: moment(this.date),
      defaultStart: this.constants.availability.defaultStart
    }, options);
    return moment(options.date).startOf('day').add({seconds: options.defaultStart});
  }

  dateDefaultEnd (options) {
    options = Object.assign({}, { // eslint-disable-line no-param-reassign
      date: moment(this.date),
      defaultEnd: this.constants.availability.defaultEnd
    }, options);
    return moment(options.date).startOf('day').add({seconds: options.defaultEnd});
  }

  /**
   * It may be useful to override one or more of the following constants depending on the context from which this is called
   * minLeadTime
   * dayStart
   * dayLatestStart
   */
  earliestValidStartDate (options) {
    options = Object.assign({}, { // eslint-disable-line no-param-reassign
      date: moment(this.date),
      minLeadTime: this.constants.booking.minLeadTime,
      timeIncrement: this.constants.booking.timeIncrement
    }, options);
    const afterLeadTime = moment(options.date).add({seconds: options.minLeadTime});
    const timeIncrement = options.timeIncrement;
    const afterRounding = moment.unix(Math.ceil(afterLeadTime.unix() / timeIncrement) * timeIncrement);
    return afterRounding;
  }

  latestValidStartDate (options) {
    options = Object.assign({}, { // eslint-disable-line no-param-reassign
      date: moment(this.date),
      dayLatestStart: this.constants.booking.dayLatestStart,
      calendarDaysAhead: this.constants.availability.calendarDaysAhead
    }, options);
    const latestDay = moment(options.date).add({days: options.calendarDaysAhead});
    return latestDay.startOf('day').add({seconds: options.dayLatestStart});
  }

  getBounds (options) {
    options = Object.assign({}, { // eslint-disable-line no-param-reassign
      earliestValidStartDate: this.earliestValidStartDate()
    }, options);
    const dateStart = this.dateStart(options);
    const dateEnd = this.dateEnd(options);
    let lowerBound;
    if (options.lowerBound) {
      lowerBound = options.lowerBound.isBefore(dateStart) ? dateStart : options.lowerBound;
    } else {
      lowerBound = dateStart;
    }
    lowerBound = lowerBound.isBefore(options.earliestValidStartDate) ? options.earliestValidStartDate : lowerBound;
    let upperBound;
    if (options.upperBound) {
      upperBound = options.upperBound.isAfter(dateEnd) ? dateEnd : options.upperBound;
    } else {
      upperBound = dateEnd;
    }
    return {
      lowerBound,
      upperBound
    };
  }

  pickerStart (options) {
    options = Object.assign({}, { // eslint-disable-line no-param-reassign
      date: moment(this.date),
      dayStart: this.constants.booking.dayStart,
      dayEnd: this.constants.booking.dayEnd,
      minDuration: this.constants.booking.minDuration
    }, options);
    const {
      lowerBound,
      upperBound
    } = this.getBounds(options);
    const currentEnd = options.currentEnd || upperBound;
    const minStart = lowerBound;
    const maxStart = moment(currentEnd).subtract({seconds: options.minDuration});
    return {
      min: minStart,
      max: maxStart
    };
  }

  pickerEnd (options) {
    options = Object.assign({}, { // eslint-disable-line no-param-reassign
      date: moment(this.date),
      dayStart: this.constants.booking.dayStart,
      dayEnd: this.constants.booking.dayEnd,
      minDuration: this.constants.booking.minDuration
    }, options);
    const {
      lowerBound,
      upperBound
    } = this.getBounds(options);
    const currentStart = options.currentStart || lowerBound;
    const minEnd = moment(currentStart).add({seconds: options.minDuration});
    const maxEnd = upperBound;
    return {
      min: minEnd,
      max: maxEnd
    };
  }
}
