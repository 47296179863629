export function endBooking (authToken, bookingId, callback) {
  return values => {
    callback(authToken, bookingId, values.endTime.time.format());
  };
}

export function confirmBooking (authToken, bookingId, callback) {
  return () => {
    callback(authToken, bookingId);
  };
}

export function addNote (authToken, parentId, callback) {
  return ({parentNote}) => {
    callback(authToken, parentId, parentNote.note);
  };
}

export function phoneCall (authToken, parentId, callback) {
  return () => {
    callback(authToken, parentId);
  };
}
