import {
  HUD_COMPLETE,
  HUD_HIDE,
  HUD_SHOW,
  HUD_UPDATE
} from 'constants/hud';

export function completeHud (options = {}) {
  return {
    options,
    type: HUD_COMPLETE
  };
}

export function hideHud () {
  return {
    type: HUD_HIDE
  };
}

export function showHud (options = {}) {
  return {
    options,
    type: HUD_SHOW
  };
}

export function updateHud (options = {}) {
  return {
    options,
    type: HUD_UPDATE
  };
}
