import {
  call,
  fork,
  put,
  take
} from 'redux-saga/effects';

import {identify} from 'utils/track';
import {HOME_PAGE} from 'constants/sitter/navigation';
import {HYDRO} from 'constants/session';
import {VALIDATE_STATUS} from 'constants/parent/signUpFlow';
import {
  LOGIN_SUCCESS
} from 'constants/login';

export function *loginSuccess () {
  while (true) { // eslint-disable-line no-constant-condition
    const {response, context} = yield take([LOGIN_SUCCESS]);
    const user = response.entities.user[response.result];
    yield fork(loginSuccessEffect, user.authentication_token, user, context);
  }
}

export function *loginSuccessEffect (authToken, user, context) {
  yield call(identify, user.email);
  yield put({type: HYDRO, authToken});
  yield call(context.history.push, `/${HOME_PAGE}`);

  // TODO: Move all of this to hydrate saga
  yield put({type: VALIDATE_STATUS, status: user.status, context});
}
