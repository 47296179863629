import React from 'react';

export default class PhoneNumberView extends React.Component {
  render () {
    const phoneNumber = this.props.children;
    let formattedPhoneNumber = phoneNumber;
    if (phoneNumber.length === 10) {
      formattedPhoneNumber = `(${phoneNumber.substr(0, 3)}) ${phoneNumber.substr(3, 3)} ${phoneNumber.substr(7, 3)}`;
    }
    return (
      <a
          href={`tel:${phoneNumber}`}
          title={formattedPhoneNumber}
          {...this.props}
      >{formattedPhoneNumber}</a>
    );
  }
}
