import React from 'react';
import PropTypes from 'prop-types';
import BuddyModal from 'react-buddy-modal';
import classNames from 'classnames';

export default class Modal extends React.Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    component: PropTypes.func,
    isOpened: PropTypes.bool.isRequired,
    options: PropTypes.object,
    classes: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object
  };

  constructor (props) {
    super(props);
    this.onClose = this.handleClose();
  }

  handleClose () {
    return () => {
      if (this.props.options && this.props.options.disableClose === true) { return }
      this.props.closeModal();
    };
  }

  render () {
    const Component = this.props.component;
    const {
      onClose,
      props: {
        isOpened,
        title,
        options,
        classes,
        history,
        location,
        match
      }
    } = this;
    const className = classNames({
      'c-modal-wrapper': true,
      ...classes
    });
    return (
      <div {...{className}}>
        <BuddyModal
            {...{isOpened, onClose, title}}
            closeOnEsc
        >
          {Component && (
            <Component
                {...options}
                history={history}
                location={location}
                match={match}
            />
          )}
        </BuddyModal>
      </div>
    );
  }
}
