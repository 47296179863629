export const BOOKINGS_FUTURE_PAGE = 'bookings/future';
export const BOOKINGS_PAST_PAGE = 'bookings/past';
export const COMPLETE_REGISTRATION_PAGE = 'complete-registration';
export const REFERRAL_PAGE = 'referral';
export const HOME_PAGE = 'search';
export const BOOKING_CONFIRMATION_PAGE = 'booking-confirmation';
export const LOGIN_PAGE = 'login';
export const PURCHASE_CREDITS_PAGE = 'purchase-credits';
export const LOGOUT_PAGE = 'logout';
export const PROFILE_PAGE = 'profile';
export const PROVIDER_PAGE = 'providers';
export const PROFILE_SCHEDULE_PAGE = 'schedule';
export const SETTINGS_PAGE = 'settings';
export const REDEEM_PAGE = 'redeem';
export const FAVORITES_PAGE = 'favorites';
export const FAVORITE_BOOKING_PAGE = 'favorites-booking';
export const WAIT_LIST_SUCCESS_PAGE = 'wait-list-success';
export const IN_PROGRESS_BOOKING_PAGE = 'bookings';
export const PASSWORD_RESET_PAGE = 'password';
export const PASSWORD_CHANGE_PAGE = 'password/reset';
export const OPEN_SEARCHES_PAGE = 'open-searches';
export const SIGN_UP_PAGE = 'sign-up';
export const SUGGESTION_BOOKING_PAGE = 'suggestion-booking';
export const MESSAGES_PAGE = 'messages';
export const MESSAGE_PAGE = 'messages/provider';
export const REVIEWS_PAGINATION_PAGE = 'reviews';
export const SIGN_UP_ZIP_PAGE = 'sign-up/zip';
