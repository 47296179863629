import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import classNames from 'classnames';

import PortraitFrame from 'components/PortraitFrame';
import {toggleNav as toggleNavAction} from 'actions/navigation';
import {
  LOGOUT_PAGE as SITTER_LOGOUT_PAGE,
  LOGIN_PAGE as SITTER_LOGIN_PAGE,
  HOME_PAGE as SITTER_HOME_PAGE,
  ALL_SEARCHES_PAGE,
  FUTURE_BOOKINGS_PAGE,
  PAST_BOOKINGS_PAGE,
  MESSAGES_PAGE,
  BOOKING_DETAILS_PAGE,
  ME_PAGE,
  SETTINGS_PAGE
} from 'constants/sitter/navigation';

import {
  getIsAuthenticated,
  getMenuIsOpen,
  getUser
} from 'selectors/session';
import {
  getInProgressBooking
} from 'selectors/sitter/session';
import {
  getFeatures
} from 'selectors/settings';

export class Menu extends React.Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    features: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    toggleNav: PropTypes.func.isRequired,
    inProgressBooking: PropTypes.number,
    user: PropTypes.object
  }

  constructor (props) {
    super(props);
    this.renderSitter = this.renderSitter.bind(this);
  }

  renderSitter (user, isAuthenticated, inProgressBooking, features) {
    let showLinks = user && user.status && user.status.accepted;
    let displayChat = features.live_chat;
    return (
      <>
        {showLinks && (<li>
          <Link
              className="c-burger-nav__menu-item c-nav-item c-nav-item__primary"
              onClick={() => {this.props.toggleNav(false)}}
              to={`/${ALL_SEARCHES_PAGE}`}
          >{'Open Requests'}</Link>
        </li>)}
        {showLinks && (<li>
          <Link
              className="c-burger-nav__menu-item c-nav-item c-nav-item__primary"
              onClick={() => {this.props.toggleNav(false)}}
              to={`/${SITTER_HOME_PAGE}`}
          >{'My Schedule'}</Link>
        </li>)}
        {showLinks && inProgressBooking && (<li>
          <Link
              className="c-burger-nav__menu-item c-nav-item c-nav-item__primary"
              onClick={() => {this.props.toggleNav(false)}}
              to={`/${BOOKING_DETAILS_PAGE}/${this.props.inProgressBooking}`}
          >{'In-progress Booking'}</Link>
        </li>)}
        {showLinks && (<li>
          <Link
              className="c-burger-nav__menu-item c-nav-item c-nav-item__primary"
              onClick={() => {this.props.toggleNav(false)}}
              to={`/${FUTURE_BOOKINGS_PAGE}`}
          >{'Upcoming Bookings'}</Link>
        </li>)}
        {showLinks && (<li>
          <Link
              className="c-burger-nav__menu-item c-nav-item c-nav-item__primary"
              onClick={() => {this.props.toggleNav(false)}}
              to={`/${PAST_BOOKINGS_PAGE}`}
          >{'Past Bookings'}</Link>
        </li>)}
        {showLinks && displayChat && (<li>
          <Link
              className="c-burger-nav__menu-item c-nav-item c-nav-item__primary"
              onClick={() => {this.props.toggleNav(false)}}
              to={`/${MESSAGES_PAGE}`}
          >{'Messages'}</Link>
        </li>)}
        {showLinks && (<li>
          <Link
              className="c-burger-nav__menu-item c-nav-item c-nav-item__primary"
              onClick={() => {this.props.toggleNav(false)}}
              to={`/${ME_PAGE}`}
          >{'Me'}</Link>
        </li>)}
        {showLinks && (
          <li>
            <Link
                className="c-burger-nav__menu-item c-nav-item c-nav-item__primary"
                onClick={() => {this.props.toggleNav(false)}}
                to={`/${SETTINGS_PAGE}`}
            >{'Settings'}</Link>
          </li>
        )}
        {isAuthenticated && (
          <li className="c-burger-nav__sign-out">
            <Link
                className="c-burger-nav__menu-item c-nav-item c-nav-item__primary"
                onClick={() => {this.props.toggleNav(false)}}
                to={`/${SITTER_LOGOUT_PAGE}`}
            >{'Sign out'}</Link>
          </li>
        )}
        {!isAuthenticated && (
          <li className="c-burger-nav__sign-out">
            <Link
                className="c-burger-nav__menu-item c-nav-item c-nav-item__primary"
                onClick={() => {this.props.toggleNav(false)}}
                to={`/${SITTER_LOGIN_PAGE}`}
            >{'Log in'}</Link>
          </li>
        )}
      </>
    );
  }

  render () {
    const {
      isAuthenticated,
      inProgressBooking,
      isOpen,
      user,
      features
    } = this.props;
    const className = classNames({
      'c-burger-menu': true,
      'c-burger-menu--hidden': !isOpen
    });
    let name = '';
    let email = '';
    if (user) {
      name = `${user.first_name} ${user.last_name}`;
      email = user.email;
    }
    return (
      <div {...{className}}>
        <div className="c-burger-menu__wrap">
          <div className="c-burger-menu__content">
            <div className="o-wrapper">
              <div className="o-layout">
                <div className="o-layout__item u-1/2@tablet c-burger-nav__main">
                  <div className="c-burger-nav__wrap">
                    <PortraitFrame
                        classes={['c-portrait-frame--profile c-burger-nav__portrait']}
                        src={user && user.photo_url}
                    />
                    <h1 className="c-burger-nav__user-name c-h2 c-h2--inverse">{name}</h1>
                    <h4 className="c-burger-nav__user-email">{email}</h4>
                    <ul className="o-list-bare c-burger-nav__menu">
                      { this.renderSitter(user, isAuthenticated, inProgressBooking, features) }
                    </ul>
                  </div>
                </div>
                <div className="o-layout__item u-1/2@tablet">
                  <div className="c-burger-nav__nav-section">
                    <div className="o-layout">
                      <div className="o-layout__item u-1/2@tablet">
                        <h6 className="c-burger-nav__title">{'Trusted'}</h6>
                        <ul className="o-list-bare c-burger-nav__site-map c-burger-nav__nav-list">
                          <li>
                            <a
                                className="c-nav-item c-nav-item__alternate"
                                href="https://usetrusted.com/"
                                target="_blank"
                            >{'Home'}</a>
                          </li>
                          <li>
                            <a
                                className="c-nav-item c-nav-item__alternate"
                                href="https://help-providers.usetrusted.com/en/articles/5108329-what-is-care-work"
                                target="_blank"
                            >{'About'}</a>
                          </li>
                          <li>
                            <a
                                className="c-nav-item c-nav-item__alternate"
                                href="https://help-providers.usetrusted.com/en/"
                                target="_blank"
                            >{'FAQs'}</a>
                          </li>
                        </ul>
                      </div>
                      <div className="o-layout__item u-1/2@tablet">
                        <h6 className="c-burger-nav__title">{'More'}</h6>
                        <ul className="o-list-bare c-burger-nav__nav-list">
                          <li>
                            <a
                                className="c-nav-item c-nav-item__alternate"
                                href="https://usetrusted.com/privacy"
                                target="_blank"
                            >{'Privacy Policy'}</a>
                          </li>
                          <li>
                            <a
                                className="c-nav-item c-nav-item__alternate"
                                href="https://usetrusted.com/terms"
                                target="_blank"
                            >{'Terms of Service'}</a>
                          </li>
                          <li>
                            <a
                                className="c-nav-item c-nav-item__alternate"
                                href="https://help-providers.usetrusted.com/en/articles/4124581-contacts"
                                target="_blank"
                            >{'Contact Us'}</a>
                          </li>
                          <li className="c-burger-nav__copyright">{'© 2016 Trusted Labs, Inc.'}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps (state, ownProps) {
  const isAuthenticated = getIsAuthenticated(state, ownProps);
  const user = isAuthenticated ? getUser(state, ownProps) : null;
  return {
    isAuthenticated,
    isOpen: getMenuIsOpen(state, ownProps),
    user,
    features: getFeatures(state, ownProps),
    inProgressBooking: isAuthenticated && user ? getInProgressBooking(state, ownProps) : null
  };
}

const mapDispatchToProps = {
  toggleNav: toggleNavAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
