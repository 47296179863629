import {
  select,
  put,
  take,
  call,
  fork,
  takeEvery
} from 'redux-saga/effects';

import {
  ADD_TIME_KEY,
  PROVIDER_ADD_AVAILABILITY_SUCCESS,
  PROVIDER_ADD_UNAVAILABILITY_SUCCESS,
  PROVIDER_ADD_AVAILABILITY_FAILURE,
  PROVIDER_ADD_UNAVAILABILITY_FAILURE,
  PROVIDER_DELETE_AVAILABILITY_SUCCESS,
  PROVIDER_DELETE_AVAILABILITY_FAILURE,
  PROVIDER_LOAD_SCHEDULE
} from 'constants/sitter/availability';
import {
  loadSchedule as loadScheduleAction,
  loadAvailabilities,
  loadUnvailabilities
} from 'actions/sitter/availability';
import {getAuthToken} from 'selectors/session';
import {openModal} from 'actions/modal';
import moment from 'moment';

import * as localStore from 'utils/localStorage';
import AfterAdd from 'containers/sitter/AfterAdd';
import ErrorModal from 'containers/ErrorModal';

export function * loadSchedule () {
  yield takeEvery(PROVIDER_LOAD_SCHEDULE, loadScheduleEffect);
}

function * loadScheduleEffect () {
  const authToken = yield select(getAuthToken);
  yield put(loadAvailabilities(authToken));
  yield put(loadUnvailabilities(authToken));
}

export function * availabilityAddSuccess () {
  yield takeEvery(PROVIDER_ADD_AVAILABILITY_SUCCESS, availabilityAddSuccessEffect);
}

export function * availabilityAddSuccessEffect () {
  const lastAddTime = yield call(localStore.get, ADD_TIME_KEY);
  const currentTime = +new Date();
  const duration = currentTime - lastAddTime;
  if (moment.duration(duration).as('days') >= 14) {
    yield put(openModal(AfterAdd, 'Thank You', {disableClose: true}, {}));
    yield call(localStore.set, ADD_TIME_KEY, currentTime);
  }
  yield put(loadScheduleAction());
}

export function * unavailabilityAddSuccess () {
  yield takeEvery(PROVIDER_ADD_UNAVAILABILITY_SUCCESS, unavailabilityAddSuccessEffect);
}

export function * unavailabilityAddSuccessEffect () {
  yield put(loadScheduleAction());
}

export function * availabilityAddFailure () {
  while (true) {
    const {
      error,
      resolve
    } = yield take(PROVIDER_ADD_AVAILABILITY_FAILURE);
    yield fork(availabilityAddFailureEffect, error, resolve);
  }
}

export function * availabilityAddFailureEffect (error, resolve) {
  yield put(openModal(ErrorModal, 'Something went wrong', {message: error.message}, {}));
  yield call(resolve);
  yield put(loadScheduleAction());
}

export function * unavailabilityAddFailure () {
  while (true) {
    const {
      error,
      resolve
    } = yield take(PROVIDER_ADD_UNAVAILABILITY_FAILURE);
    yield fork(unavailabilityAddFailureEffect, error, resolve);
  }
}

export function * unavailabilityAddFailureEffect (error, resolve) {
  yield put(openModal(ErrorModal, 'Something went wrong', {message: error.message}, {}));
  yield call(resolve);
  yield put(loadScheduleAction());
}

export function * availabilityDeleteSuccess () {
  while (true) {
    yield take(PROVIDER_DELETE_AVAILABILITY_SUCCESS);
    yield put(loadScheduleAction());
  }
}

export function * availabilityDeleteSuccessEffect () {
  yield put(loadScheduleAction());
}

export function * availabilityDeleteFailure () {
  while (true) {
    const {
      error,
      resolve
    } = yield take(PROVIDER_DELETE_AVAILABILITY_FAILURE);
    yield fork(availabilityDeleteFailureEffect, error, resolve);
  }
}

export function * availabilityDeleteFailureEffect (error, resolve) {
  yield put(openModal(ErrorModal, 'Something went wrong', {message: error.message}, {}));
  yield call(resolve);
  yield put(loadScheduleAction());
}
