import React from 'react';
import PropTypes from 'prop-types';

import ChildProfileView from 'components/sitter/ChildProfileView';

export default class BookingChildrenView extends React.Component {
  static propTypes = {
    children: PropTypes.array.isRequired
  }

  render () {
    return (
      <div className="c-booking-child-profile-views">
        {this.props.children.map((child, key) => (
          <div
              className="c-booking-child-profile-views__view"
              {...{key}}
          >
            <h4>{`Child ${key + 1}`}</h4>
            <ChildProfileView {...{child}} />
          </div>
        ))}
      </div>
    );
  }
}
