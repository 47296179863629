import React from 'react';

import SearchItem from 'components/sitter/SearchItem';
import Paginate from 'components/Paginate';
import PropTypes from 'prop-types';

export default class SearchList extends React.Component {
  static propTypes = {
    currentPage: PropTypes.number.isRequired,
    interested: PropTypes.bool.isRequired,
    hrefBuilder: PropTypes.func.isRequired,
    onPageChange: PropTypes.func.isRequired,
    pageCount: PropTypes.number.isRequired,
    geolocation: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    searches: PropTypes.array.isRequired,
    scope: PropTypes.string.isRequired,
    copy: PropTypes.object.isRequired,
    features: PropTypes.object.isRequired,
  }

  handleSubmit = interested => {
    return search => {
      this.props.onSubmit({
        search: {
          searchId: search.id,
          interested,
          instantBook: search.instant_book,
          isRecurring: search.recurrence
        }
      });
    };
  }

  render () {
    return (
      <div>
        <ul className="o-layout">
          {this.props.searches.map(search => (
            <li
                className="o-layout__item u-1/1"
                key={search.id}
            >
              <SearchItem
                  copy={this.props.copy}
                  features={this.props.features}
                  geolocation={this.props.geolocation}
                  interested={this.props.interested}
                  onInterest={this.handleSubmit(true)}
                  onReject={this.handleSubmit(false)}
                  {...{search}}
                  user={this.props.user}
              />
            </li>
          ))}
        </ul>
        {(this.props.pageCount > 1) && (
          <Paginate
              currentPage={this.props.currentPage}
              hrefBuilder={this.props.hrefBuilder}
              onPageChange={this.props.onPageChange}
              pageCount={this.props.pageCount}
          />
        )}
      </div>
    );
  }
}
