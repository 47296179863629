import {schema} from 'normalizr';

export const availability = new schema.Entity(
  'availability',
  {},
  { idAttribute: 'id' }
);

export const availabilities = { availabilities: new schema.Array(availability) };

export const unavailability = new schema.Entity(
  'unavailability',
  {},
  { idAttribute: 'id' }
);

export const unavailabilities = { unavailabilities: new schema.Array(unavailability) };
