import React from 'react';
import PropTypes from 'prop-types';

import ParentNoteView from 'components/sitter/ParentNoteView';
import ParentNoteAdd from 'forms/fields/ParentNoteAdd';

export default class SitterNotesView extends React.Component {
  static propTypes = {
    notes: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired
  }

  render () {
    return (
      <div className="c-trusted-note-view">
        <h4>{'Provider Notes'}</h4>
        {this.props.notes.map((note, key) => (
          <ParentNoteView {...{key, note}} />
        ))}
        <h4 className="u-center-text">{'+ Add a Note'}</h4>
        <ParentNoteAdd
            initialValues={{}}
            onSubmit={this.props.onSubmit}
        />
      </div>
    );
  }
}
