import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {setTitle as setTitleAction} from 'actions/navigation';
import {getFirstName} from 'selectors/session';
import {track} from 'utils/track';

export class Rejected extends React.Component {
  static propTypes = {
    setTitle: PropTypes.func.isRequired,
    firstName: PropTypes.string.isRequired
  };

  componentDidMount () {
    this.props.setTitle('Thank you');
    track('Rejected Page - Enter');
  }

  render () {
    return (
      <div className="o-layout">
        <div className="o-layout__item u-1/1 u-3/5@desktop u-push-1/5@desktop">
          <h1 className="c-h1 c-page-title">{'Thank you'}</h1>
          <p>{`Hi ${this.props.firstName},`}</p>
          <p>{'Thank you for your interest in Trusted! We\'ve decided not to move forward with your application. We wish you the best of luck in your future endeavors.'}</p>
          <p>{'Kind Regards,'}<br />{'Team Trusted'}</p>
        </div>
      </div>
    );
  }
}

export const makeMapStateToProps = () => {
  return (state, ownProps) => {
    return {
      firstName: getFirstName(state, ownProps)
    };
  };
};

const mapDispatchToProps = {
  setTitle: setTitleAction
};

export default connect(makeMapStateToProps, mapDispatchToProps)(Rejected);
