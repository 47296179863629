export const BOOKING_REQUEST = 'BOOKING_REQUEST'
export const BOOKING_SUCCESS = 'BOOKING_SUCCESS'
export const BOOKING_FAILURE = 'BOOKING_FAILURE'
export const BOOKING_DETAILS_REQUEST = 'BOOKING_DETAILS_REQUEST'
export const BOOKING_DETAILS_SUCCESS = 'BOOKING_DETAILS_SUCCESS'
export const BOOKING_DETAILS_FAILURE = 'BOOKING_DETAILS_FAILURE'
export const BOOKING_PRICE_REQUEST = 'BOOKING_PRICE_REQUEST'
export const BOOKING_PRICE_SUCCESS = 'BOOKING_PRICE_SUCCESS'
export const BOOKING_PRICE_FAILURE = 'BOOKING_PRICE_FAILURE'
export const PREVIOUS_BOOKING_LISTING_REQUEST = 'PREVIOUS_BOOKING_LISTING_REQUEST'
export const PREVIOUS_BOOKING_LISTING_SUCCESS = 'PREVIOUS_BOOKING_LISTING_SUCCESS'
export const PREVIOUS_BOOKING_LISTING_FAILURE = 'PREVIOUS_BOOKING_LISTING_FAILURE'
export const BOOKING_LISTING_REQUEST = 'BOOKING_LISTING_REQUEST'
export const BOOKING_LISTING_SUCCESS = 'BOOKING_LISTING_SUCCESS'
export const BOOKING_LISTING_FAILURE = 'BOOKING_LISTING_FAILURE'
export const BOOKING_DESTROY_REQUEST = 'BOOKING_DESTROY_REQUEST'
export const BOOKING_DESTROY_SUCCESS = 'BOOKING_DESTROY_SUCCESS'
export const BOOKING_DESTROY_FAILURE = 'BOOKING_DESTROY_FAILURE'
export const REQUEST_BOOKING_PRICE = 'REQUEST_BOOKING_PRICE'
export const UNREVIEWED_BOOKING_LISTING_REQUEST = 'UNREVIEWED_BOOKING_LISTING_REQUEST'
export const UNREVIEWED_BOOKING_LISTING_SUCCESS = 'UNREVIEWED_BOOKING_LISTING_SUCCESS'
export const UNREVIEWED_BOOKING_LISTING_FAILURE = 'UNREVIEWED_BOOKING_LISTING_FAILURE'
export const PROVIDER_REVIEW_REQUEST = 'PROVIDER_REVIEW_REQUEST'
export const PROVIDER_REVIEW_SUCCESS = 'PROVIDER_REVIEW_SUCCESS'
export const PROVIDER_REVIEW_FAILURE = 'PROVIDER_REVIEW_FAILURE'
export const DISCOUNT_CODE_REQUEST = 'DISCOUNT_CODE_REQUEST'
export const DISCOUNT_CODE_SUCCESS = 'DISCOUNT_CODE_SUCCESS'
export const DISCOUNT_CODE_FAILURE = 'DISCOUNT_CODE_FAILURE'
export const PURCHASE_CREDIT_REQUEST = 'PURCHASE_CREDIT_REQUEST'
export const PURCHASE_CREDIT_SUCCESS = 'PURCHASE_CREDIT_SUCCESS'
export const PURCHASE_CREDIT_FAILURE = 'PURCHASE_CREDIT_FAILURE'
export const BOOKING_REFRESH = 'BOOKING_REFRESH'
