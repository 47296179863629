import {schema} from 'normalizr';

export const settings = new schema.Entity(
  'settings',
  {},
  { idAttribute: 'id' }
);

export const notificationPreference = new schema.Entity(
  'notification_preference',
  {},
  { idAttribute: 'id' }
);

export const notificationPreferences = { notificationPreferences: new schema.Array(notificationPreference) };
