import React from 'react';
import {
  Redirect,
  Route,
  Switch
} from 'react-router-dom';

import Login from 'containers/sitter/Login';
import LogOut from 'containers/LogOut';
import Home from 'containers/sitter/Home';
import SignUp from 'containers/parent/SignUp';
import Typeform from 'pages/sitter/Typeform';
import WaitListSuccess from 'containers/parent/WaitListSuccess';
import FutureBookings from 'pages/sitter/FutureBookings';
import CompleteRegistration from 'pages/sitter/CompleteRegistration';
import BookingDetails from 'containers/sitter/BookingDetails';
import PastBookings from 'pages/sitter/PastBookings';
import Messages from 'pages/Messages';
import Conversation from 'pages/Conversation';
import Me from 'pages/sitter/Me';
import PasswordReset from 'containers/PasswordReset';
import PasswordChange from 'containers/PasswordChange';
import Approved from 'pages/sitter/Approved';
import Rejected from 'pages/sitter/Rejected';
import Settings from 'pages/sitter/Settings';
import AuthenticatedRoute from 'routes/authenticated'
import UnauthenticatedRoute from 'routes/unauthenticated'
import {
  HOME_PAGE,
  LOGIN_PAGE,
  ME_PAGE,
  REVIEWS_PAGINATION_PAGE,
  LOGOUT_PAGE,
  ALL_SEARCHES_PAGE,
  ONE_TIME_SEARCHES_PAGE,
  RECURRING_SEARCHES_PAGE,
  INTERESTED_SEARCHES_PAGE,
  SIGN_UP_PAGE,
  TYPEFORM_PAGE,
  WAIT_LIST_SUCCESS_PAGE,
  FUTURE_BOOKINGS_PAGE,
  PAST_BOOKINGS_PAGE,
  BOOKING_DETAILS_PAGE,
  MESSAGES_PAGE,
  MESSAGE_PAGE,
  PASSWORD_RESET_PAGE,
  APPROVED_PAGE,
  REJECTED_PAGE,
  COMPLETE_REGISTRATION_PAGE,
  SETTINGS_PAGE
} from 'constants/sitter/navigation';
import {
  ZIP_STEP
} from 'constants/parent/signUpFlow';
import {
  OneTimeSearches,
  RecurringSearches,
  InterestedSearches,
  AllSearches
} from 'pages/sitter/Searches';

export default function routes (isAuthenticated, status) {
  return (
    <div>
      <Switch>
        <Route
            component={LogOut}
            exact
            path={`/${LOGOUT_PAGE}`}
        />
        <UnauthenticatedRoute
            component={Login}
            isAuthenticated={isAuthenticated}
            path={`/${LOGIN_PAGE}`}
        />
        <UnauthenticatedRoute
            component={SignUp}
            isAuthenticated={isAuthenticated}
            path={`/${SIGN_UP_PAGE}/:currentStep/:zipCode`}
        />
        <UnauthenticatedRoute
            component={SignUp}
            isAuthenticated={isAuthenticated}
            path={`/${SIGN_UP_PAGE}/:currentStep`}
        />
        <Redirect
            from={`/${SIGN_UP_PAGE}`}
            to={`/${SIGN_UP_PAGE}/${ZIP_STEP}`}
        />
        <Route
            component={WaitListSuccess}
            exact
            path={`/${WAIT_LIST_SUCCESS_PAGE}`}
        />
        <AuthenticatedRoute
            component={Approved}
            isAuthenticated={isAuthenticated}
            path={`/${APPROVED_PAGE}`}
            status={status}
        />
        <AuthenticatedRoute
            component={Rejected}
            isAuthenticated={isAuthenticated}
            path={`/${REJECTED_PAGE}`}
            status={status}
        />
        <AuthenticatedRoute
            component={Typeform}
            isAuthenticated={isAuthenticated}
            path={`/${TYPEFORM_PAGE}`}
            status={status}
        />
        <AuthenticatedRoute
            component={FutureBookings}
            isAuthenticated={isAuthenticated}
            path={`/${FUTURE_BOOKINGS_PAGE}/:currentPage?`}
            status={status}
        />
        <AuthenticatedRoute
            component={PastBookings}
            isAuthenticated={isAuthenticated}
            path={`/${PAST_BOOKINGS_PAGE}/:currentPage?`}
            status={status}
        />
        <AuthenticatedRoute
            component={BookingDetails}
            isAuthenticated={isAuthenticated}
            path={`/${BOOKING_DETAILS_PAGE}/:bookingId`}
            status={status}
        />
        <AuthenticatedRoute
            component={Home}
            isAuthenticated={isAuthenticated}
            path={`/${HOME_PAGE}`}
            status={status}
        />
        <AuthenticatedRoute
            component={Conversation}
            isAuthenticated={isAuthenticated}
            path={`/${MESSAGE_PAGE}/:otherId`}
        />
        <AuthenticatedRoute
            component={Messages}
            isAuthenticated={isAuthenticated}
            path={`/${MESSAGES_PAGE}`}
        />
        <AuthenticatedRoute
            component={AllSearches}
            isAuthenticated={isAuthenticated}
            path={`/${ALL_SEARCHES_PAGE}/:currentPage?`}
            status={status}
        />
        <AuthenticatedRoute
            component={OneTimeSearches}
            isAuthenticated={isAuthenticated}
            path={`/${ONE_TIME_SEARCHES_PAGE}/:currentPage?`}
            status={status}
        />
        <AuthenticatedRoute
            component={RecurringSearches}
            isAuthenticated={isAuthenticated}
            path={`/${RECURRING_SEARCHES_PAGE}/:currentPage?`}
            status={status}
        />
        <AuthenticatedRoute
            component={InterestedSearches}
            isAuthenticated={isAuthenticated}
            path={`/${INTERESTED_SEARCHES_PAGE}/:currentPage?`}
            status={status}
        />
        <AuthenticatedRoute
            component={Me}
            isAuthenticated={isAuthenticated}
            path={`/${ME_PAGE}/${REVIEWS_PAGINATION_PAGE}/:currentPage`}
            status={status}
        />
        <Route path={`/${ME_PAGE}`}>
          <Redirect to={`/${ME_PAGE}/${REVIEWS_PAGINATION_PAGE}/1`} />
        </Route>
        <AuthenticatedRoute
            component={Me}
            isAuthenticated={isAuthenticated}
            path={`/${ME_PAGE}`}
            status={status}
        />
        <Route
            component={PasswordChange}
            path={`/${PASSWORD_RESET_PAGE}/:resetToken`}
        />
        <Route
            component={PasswordReset}
            path={`/${PASSWORD_RESET_PAGE}`}
        />
        <AuthenticatedRoute
            component={Settings}
            isAuthenticated={isAuthenticated}
            path={`/${SETTINGS_PAGE}`}
            status={status}
        />
        <AuthenticatedRoute
            component={CompleteRegistration}
            isAuthenticated={isAuthenticated}
            path={`/${COMPLETE_REGISTRATION_PAGE}`}
            status={status}
        />
        <Redirect to={`/${HOME_PAGE}`} />
      </Switch>
    </div>
  );
}
