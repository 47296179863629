import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {closeModal as closeModalAction} from 'actions/modal';
import DoneButton from 'forms/fields/DoneButton';

export class ErrorModal extends React.Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired
  }

  render () {
    return (
      <div className="o-layout">
        <div className="o-layout__item u-1/1">
          <p className="u-center-text">{`Error: ${this.props.message}`}</p>
          <DoneButton onClick={() => this.props.closeModal()} />
        </div>
      </div>
    );
  }
}

export const makeMapStateToProps = () => {
  return () => {
    return {
    };
  };
};

const mapDispatchToProps = {
  closeModal: closeModalAction
};

export default connect(makeMapStateToProps, mapDispatchToProps)(ErrorModal);
