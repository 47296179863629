import React from 'react';
import PropTypes from 'prop-types';

export default class Typeform extends React.Component {
  static propTypes = {
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    zipCode: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    formUrl: PropTypes.string.isRequired
  }

  render () {
    return (
      <iframe
          className="c-iframe c-iframe--full"
          src={this.props.formUrl}
      />
    );
  }
}
