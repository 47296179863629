import React from 'react';
import PropTypes from 'prop-types';
import ParentNoteView from 'components/sitter/ParentNoteView';

export default class TrustedNotesView extends React.Component {
  static propTypes = {
    note: PropTypes.object.isRequired
  }

  render () {
    return (
      <div className="c-trusted-note-view">
        <h4>{'Our Notes'}</h4>
        <ParentNoteView
            isTrusted
            note={this.props.note}
        />
      </div>
    );
  }
}
