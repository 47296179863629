import React from 'react';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {BOOKING_DETAILS_PAGE} from 'constants/sitter/navigation';
import ProfileLineView from 'components/sitter/ProfileLineView';
import BookingTimeView from 'components/sitter/BookingTimeView';

export function titleFor (status) {
  if ('ended confirmed cancelled in_progress'.split(' ').includes(status)) {
    return status.replace('_', ' ');
  }
  return 'booked';
}

export default class BookingListItem extends React.Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    booking: PropTypes.object.isRequired
  };

  render () {
    const className = classNames({
      'c-booking-list-item-status': true,
      ['c-booking-list-item-status--' + this.props.booking.status.replace('_', '-')]: true
    });
    const statusTitle = titleFor(this.props.booking.status);
    return (
      <Link
          className="c-booking-list-item"
          to={`/${BOOKING_DETAILS_PAGE}/${this.props.booking.id}`}
      >
        <div {...{className}}>
          {statusTitle}
        </div>
        <div className="c-booking-list-item__details">
          <ProfileLineView
              badgeUrl={(this.props.booking.policy && this.props.booking.policy.logo_url) ? this.props.booking.policy.logo_url : null}
              closeModal={this.props.closeModal}
              hideMessages
              profile={this.props.booking.parent}
              showPhoneNumber={false}
          />
          <BookingTimeView booking={this.props.booking} />
        </div>
      </Link>
    );
  }
}
