import moment from 'moment';
import pluralize from 'pluralize';

export default class ChildProfile {
  constructor (json = {}) {
    this.id = json.id || 0;
    this.firstName = json.first_name || '';
    this.lastName = json.last_name || '';
    this.gender = json.gender || 'unspecified';
    this.note = json.note || '';
    this.fullName = `${this.firstName} ${this.lastName}`.trim();
    this.dateOfBirth = json.date_of_birth ? moment(json.date_of_birth) : undefined;
  }

  currentAge () {
    return this.ageAtDate(moment());
  }

  ageAtDate (date) {
    if (!this.dateOfBirth) {
      return undefined;
    }
    let years = date.diff(this.dateOfBirth, 'years');
    if (years >= 2) {
      return years + pluralize(' year', years);
    }
    let months = date.diff(this.dateOfBirth, 'months');
    if (months >= 2) {
      return months + pluralize(' month', months);
    }
    let weeks = date.diff(this.dateOfBirth, 'weeks');
    if (weeks >= 1) {
      return weeks + pluralize(' week', weeks);
    }
    return '1 week';
  }
}
