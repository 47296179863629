import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class StarRating extends React.Component {
  static propTypes = {
    count: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    size: PropTypes.string,
    onChange: PropTypes.func
  };

  onClick (value) {
    return () => {
      if (this.props.onChange) {
        this.props.onChange(value);
      }
    };
  }

  generateStars (count) {
    return Array(...Array(count)).map((_, key) => ( // refactor, this isn't a good form control, not accessible at all
      <span
          onClick={this.onClick(key + 1)}
          {...{key}}
      >{'★'}</span>
    ));
  }

  render () {
    const {
      count,
      width,
      size
    } = this.props;
    const className = classNames({
      'c-star-rating': true,
      'c-star-rating--large': size === 'large'
    });
    return (
      <div {...{className}}>
        <div
            className="c-star-rating__top"
            style={{width: `${width}%`}}
        >{this.generateStars(count)}</div>
        <div className="c-star-rating__bottom">{this.generateStars(count)}</div>
      </div>
    );
  }
}
