import React from 'react';
import {
  Redirect,
  Route,
} from 'react-router-dom';
import {
  HOME_PAGE as SITTER_HOME_PAGE
} from 'constants/sitter/navigation';
import {
  HOME_PAGE as PARENT_HOME_PAGE,
} from 'constants/parent/navigation';

export default function UnauthenticatedRoute (route) {
  const homePage = process.env.APP_ROLE === 'parent' ? PARENT_HOME_PAGE : SITTER_HOME_PAGE;
  if (!route.isAuthenticated) {
    return (
      <Route
          {...route}
      />
    )
  } else {
    return (
      <Redirect to={`/${homePage}`} />
    )
  }
}
