import React from 'react';
import {connect} from 'react-redux';

import {track} from 'utils/track';

export class WaitListSuccess extends React.Component {
  componentDidMount () {
    track('Wait List Success');
  }

  render () {
    return (
      <div className="o-layout">
        <div className="o-layout__item u-1/1 u-1/2@desktop u-push-1/4@desktop">
          <h1 className="c-h1 u-center-text">{'Thank you!'}</h1>
          <p className="u-center-text">{'We will notify you when our service is availble in your area!'}</p>
          <a
              className="u-display-block u-center-text"
              href="//usetrusted.com"
          >{'Return to usetrusted.com'}</a>
        </div>
      </div>
    );
  }
}

export default connect(() => {
  return {};
}, {
})(WaitListSuccess);
