import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom';
import 'react-buddy-modal/dist/styles.css';
import App from 'containers/sitter/App';
import 'stylesheets/sitter.scss';
import {configureStore} from 'stores/sitter/configureStore';
import PropTypes from 'prop-types';
import * as localStore from 'utils/localStorage';
import connect from 'react-redux/es/connect/connect';
import {hydro as hydroAction} from './actions/session';

import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';

var bugsnagClient = bugsnag({
  apiKey: process.env.BUGSNAG_API_KEY,
  releaseStage: process.env.NODE_ENV
});
bugsnagClient.use(bugsnagReact, React);

var ErrorBoundary = bugsnagClient.getPlugin('react');

const initialState = {
};

const store = configureStore(initialState);
class WrapperComponent extends React.Component { // eslint-disable-line react/no-multi-comp
  static propTypes = {
    hydro: PropTypes.func.isRequired
  };
  componentWillMount () {
    if (process.env.BROWSER) {
      const authToken = localStore.get('TRUSTED_AUTH_TOKEN_' + process.env.APP_ROLE);
      this.props.hydro(authToken);
    }
  }
  render () {
    return (
      <div>
        {this.props.children}
      </div>
    );
  }
}
const Wrapper = connect(() => {
  return {
  };
}, {
  hydro: hydroAction
})(WrapperComponent);

ReactDOM.render(
  <ErrorBoundary>
    <Provider {...{store}}>
      <Wrapper>
        <Router>
          <Route component={App} />
        </Router>
      </Wrapper>
    </Provider>
  </ErrorBoundary>
  , document.getElementById('js-app'));
