/* global mixpanel */

export function track (eventName, properties, callback) {
  if (process.env.NODE_ENV !== 'development' && mixpanel && mixpanel.track) {
    mixpanel.track(eventName, properties, callback)
  } else {
    console.log(eventName, properties)
  }
}

export function identify (id) {
  if (process.env.NODE_ENV !== 'development' && mixpanel && mixpanel.identify) {
    mixpanel.identify(id)
  }
}

export function setPerson (options) {
  if (process.env.NODE_ENV !== 'development' && mixpanel && mixpanel.people) {
    mixpanel.people.set(options)
  }
}
