export const CHILD_PROFILE_STEP = 'child-profile';
export const COMPLETE_PROFILE_STEP = 'complete-profile';
export const LOCATION_STEP = 'address';
export const PAYMENT_STEP = 'payment';
export const REGISTER_STEP = 'register';
export const WAIT_LIST = 'wait-list';
export const ZIP_STEP = 'zip';
export const VALIDATE_STATUS = 'SIGN_UP_VALIDATE_STATUS';
export const SET_PROFILE_FORM = 'SIGN_UP_SET_PROFILE_FORM';
export const REGISTRATION_REQUEST = 'SIGN_UP_REGISTRATION_REQUEST';
export const REGISTRATION_SUCCESS = 'SIGN_UP_REGISTRATION_SUCCESS';
export const REGISTRATION_FAILURE = 'SIGN_UP_REGISTRATION_FAILURE';
export const SUBMIT_PROFILE_REQUEST = 'SIGN_UP_SUBMIT_PROFILE_REQUEST';
export const SUBMIT_PROFILE_SUCCESS = 'SIGN_UP_SUBMIT_PROFILE_SUCCESS';
export const SUBMIT_PROFILE_FAILURE = 'SIGN_UP_SUBMIT_PROFILE_FAILURE';
export const CREATE_CHILD_PROFILE_REQUEST = 'SIGN_UP_CREATE_CHILD_PROFILE_REQUEST';
export const CREATE_CHILD_PROFILE_SUCCESS = 'SIGN_UP_CREATE_CHILD_PROFILE_SUCCESS';
export const CREATE_CHILD_PROFILE_FAILURE = 'SIGN_UP_CREATE_CHILD_PROFILE_FAILURE';
export const PAYMENT_SUBMIT_REQUEST = 'SIGN_UP_PAYMENT_SUBMIT_REQUEST';
export const PAYMENT_SUBMIT_SUCCESS = 'SIGN_UP_PAYMENT_SUBMIT_SUCCESS';
export const PAYMENT_SUBMIT_FAILURE = 'SIGN_UP_PAYMENT_SUBMIT_FAILURE';
export const SET_HOME_REQUEST = 'SIGN_UP_SET_HOME_REQUEST';
export const SET_HOME_SUCCESS = 'SIGN_UP_SET_HOME_SUCCESS';
export const SET_HOME_FAILURE = 'SIGN_UP_SET_HOME_FAILURE';
export const BRAINTREE_TOKEN_REQUEST = 'SIGN_UP_BRAINTREE_TOKEN_REQUEST';
export const BRAINTREE_TOKEN_SUCCESS = 'SIGN_UP_BRAINTREE_TOKEN_SUCCESS';
export const BRAINTREE_TOKEN_FAILURE = 'SIGN_UP_BRAINTREE_TOKEN_FAILURE';
export const TOKENIZE_CARD_REQUEST = 'SIGN_UP_TOKENIZE_CARD_REQUEST';
export const TOKENIZE_CARD_SUCCESS = 'SIGN_UP_TOKENIZE_CARD_SUCCESS';
export const TOKENIZE_CARD_FAILURE = 'SIGN_UP_TOKENIZE_CARD_FAILURE';
export const WAIT_LIST_SIGNUP_FAILURE = 'WAIT_LIST_SIGNUP_FAILURE';
export const WAIT_LIST_SIGNUP_REQUEST = 'WAIT_LIST_SIGNUP_REQUEST';
export const WAIT_LIST_SIGNUP_SUCCESS = 'WAIT_LIST_SIGNUP_SUCCESS';
export const SET_ZIP = 'SET_ZIP';
export const UPDATE_HOME_REQUEST = 'UPDATE_HOME_REQUEST';
export const UPDATE_HOME_SUCCESS = 'UPDATE_HOME_SUCCESS';
export const UPDATE_HOME_FAILURE = 'UPDATE_HOME_FAILURE';
export const ADD_CHILD_PROFILE_REQUEST = 'ADD_CHILD_PROFILE_REQUEST';
export const ADD_CHILD_PROFILE_SUCCESS = 'ADD_CHILD_PROFILE_SUCCESS';
export const ADD_CHILD_PROFILE_FAILURE = 'ADD_CHILD_PROFILE_FAILURE';
export const PAYMENT_CHANGE_REQUEST = 'PAYMENT_CHANGE_REQUEST';
export const PAYMENT_CHANGE_SUCCESS = 'PAYMENT_CHANGE_SUCCESS';
export const PAYMENT_CHANGE_FAILURE = 'PAYMENT_CHANGE_FAILURE';
export const PAYMENT_REQUEST = 'PAYMENT_REQUEST';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
export const PAYMENT_FAILURE = 'PAYMENT_FAILURE';
export const PAYMENT_DELETE_REQUEST = 'PAYMENT_DELETE_REQUEST';
export const PAYMENT_DELETE_SUCCESS = 'PAYMENT_DELETE_SUCCESS';
export const PAYMENT_DELETE_FAILURE = 'PAYMENT_DELETE_FAILURE';
export const PAYMENT_UPDATE_REQUEST = 'PAYMENT_UPDATE_REQUEST';
export const PAYMENT_UPDATE_SUCCESS = 'PAYMENT_UPDATE_SUCCESS';
export const PAYMENT_UPDATE_FAILURE = 'PAYMENT_UPDATE_FAILURE';
export const ZIP_REQUEST = 'ZIP_REQUEST';
export const ZIP_SUCCESS = 'ZIP_SUCCESS';
export const ZIP_FAILURE = 'ZIP_FAILURE';

