import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import AvailabilityCalendar from 'components/AvailabilityCalendar';
import {addAvailability as addAvailabilitySubmission} from 'submissions/sitter/availability';
import {change as formChangeAction} from 'redux-form';
import AddAvailability from 'containers/sitter/AddAvailability';
import ConfirmModal from 'containers/ConfirmModal';
import EventView from 'containers/sitter/EventView';
import {openBookingDetailsModal as openBookingDetailsModalAction} from 'actions/sitter/bookings';
import {
  getAvailabilityCalendarOptions,
  getAvailabilities,
  getUnavailabilities,
  getAllEvents
} from 'selectors/sitter/availability';
import {
  getAuthToken,
  getUser,
  getCoverageZones as getSitterCoverageZones,
  getCoverageZoneOptions
} from 'selectors/session';
import {
  getTimeService,
  getConstantsModel,
  getAvailabilityService
} from 'selectors/sitter/session';
import {
  addAvailability as addAvailabilityAction,
  addUnavailability as addUnavailabilityAction,
  loadSchedule as loadScheduleAction,
  loadAllCoverageZones as loadAllCoverageZonesAction,
  loadCoverageZones as loadCoverageZonesAction,
} from 'actions/sitter/availability';
import {
  openModal as openModalAction
} from 'actions/modal';
import {
  getCopy
} from 'selectors/settings';
import {getCoverageZones} from 'selectors/sitter/coverageZone';
import {IntercomAPI} from 'react-intercom';

export class Home extends React.Component {
  static propTypes = {
    availabilities: PropTypes.array.isRequired,
    unavailabilities: PropTypes.array.isRequired,
    authToken: PropTypes.string.isRequired,
    loadSchedule: PropTypes.func.isRequired,
    timeService: PropTypes.object.isRequired,
    constantsModel: PropTypes.object.isRequired,
    availabilityService: PropTypes.object.isRequired,
    allEvents: PropTypes.array.isRequired,
    copy: PropTypes.object.isRequired,
  };

  componentDidMount () {
    this.props.loadCoverageZones(this.props.authToken)
    this.props.loadAllCoverageZones(this.props.authToken);
    this.props.loadSchedule();

    if (this.props.user && this.props.user.suspended) {
      setTimeout(() => {
        this.props.openModal(ConfirmModal, this.props.copy.announcements.suspended.title, {
          confirmText: this.props.copy.announcements.suspended.action,
          message: this.props.copy.announcements.suspended.body,
          onConfirm: () => IntercomAPI('showMessages')
        });
      }, 1000);
    }
  }

  componentWillReceiveProps (newProps) {
    if (newProps.authToken && newProps.authToken !== this.props.authToken) {
      this.props.loadSchedule();
    }
  }

  handleDayClick = date => {
    if (!this.props.user.status.live) {
      return;
    }
    const bounds = this.props.availabilityService.findNewAvailabilityBounds(date, {values: this.props.allEvents});
    if (!bounds) {
      return;
    }

    this.props.openModal(AddAvailability, 'Add Availability', {
      onSubmit: addAvailabilitySubmission(this.props.authToken, this.props.addAvailability, this.props.addUnavailability, this.props.sitterCoverageZones),
      onFormChange: this.props.onFormChange,
      allCoverageZones: this.props.coverageZones,
      coverageZoneOptions: this.props.coverageZoneOptions,
      date,
      constants: this.props.constantsModel,
      lowerBound: bounds.lower,
      sitterCoverageZones: this.props.sitterCoverageZones,
      upperBound: bounds.upper
    }, {});
  };

  handleAvailability = (availability, title) => {
    if (availability.booked) {
      this.props.openBookingDetailsModal(availability.bookingId);
    } else {
      this.props.openModal(EventView, `Available ${title}`, {
        constants: this.props.constantsModel,
        scheduleItem: availability,
        isAvailability: true
      }, {});
    }
  };

  handleUnavailability = (unavailability, title) => {
    this.props.openModal(EventView, `Unavailable ${title}`, {
      constants: this.props.constantsModel,
      scheduleItem: unavailability,
      isAvailability: false
    }, {});
  };

  handleEventClick = event => {
    if (!this.props.user.status.live) {
      return;
    }
    const title = event.start.format('MMMM Do YYYY');

    if (event.availability) {
      this.handleAvailability(event.availability, title);
    } else if (event.unavailability) {
      this.handleUnavailability(event.unavailability, title);
    }
  };

  render () {
    return (
      <div className="o-layout">
        <div className="o-layout__item u-1/1">
          <AvailabilityCalendar
              maxDate={this.props.timeService.latestValidStartDate()}
              minDate={this.props.timeService.earliestValidStartDate().subtract(1, 'days')}
              onDayClick={this.handleDayClick}
              onEventClick={this.handleEventClick}
              options={this.props.availabilityCalendarOptions}
          />
        </div>
      </div>
    );
  }
}

export const mapStateToProps = () => {
  return (state, ownProps) => {
    return {
      sitterCoverageZones: getSitterCoverageZones(state, ownProps),
      coverageZoneOptions: getCoverageZoneOptions(state, ownProps),
      coverageZones: getCoverageZones(state, ownProps),
      constantsModel: getConstantsModel(state, ownProps),
      availabilityService: getAvailabilityService(state, ownProps),
      availabilities: getAvailabilities(state, ownProps),
      unavailabilities: getUnavailabilities(state, ownProps),
      authToken: getAuthToken(state, ownProps),
      timeService: getTimeService(state, ownProps),
      availabilityCalendarOptions: getAvailabilityCalendarOptions(state, ownProps),
      user: getUser(state, ownProps),
      allEvents: getAllEvents(state, ownProps),
      copy: getCopy(state, ownProps),
    };
  };
};

export default connect(mapStateToProps, {
  addAvailability: addAvailabilityAction,
  addUnavailability: addUnavailabilityAction,
  onFormChange: formChangeAction,
  openModal: openModalAction,
  loadAllCoverageZones: loadAllCoverageZonesAction,
  loadCoverageZones: loadCoverageZonesAction,
  openBookingDetailsModal: openBookingDetailsModalAction,
  loadSchedule: loadScheduleAction,
})(Home);
