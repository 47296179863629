import {
  takeEvery,
  put,
} from 'redux-saga/effects';
import {CONSTANTS_FAILURE} from 'constants/settings';
import {LOG_OUT} from 'constants/logOut';
import {
  PROFILE_FAILURE,
  PROFILE_UPDATE_FAILURE
} from 'constants/profile';
import {
  AVAILABILITIES_FAILURE,
  UNAVAILABILITIES_FAILURE,
} from 'constants/sitter/availability';
import {
  LOAD_REVIEWS_FAILURE,
} from 'constants/sitter/reviews';
import {
  PAST_BOOKINGS_FAILURE,
  CONFIRM_BOOKING_FAILURE,
  LOAD_BOOKING_DETAILS_FAILURE,
} from 'constants/sitter/bookings';

export function *allFail () {
  yield takeEvery([
    CONSTANTS_FAILURE,
    PROFILE_FAILURE,
    PROFILE_UPDATE_FAILURE,
    AVAILABILITIES_FAILURE,
    UNAVAILABILITIES_FAILURE,
    LOAD_REVIEWS_FAILURE,
    LOAD_BOOKING_DETAILS_FAILURE,
    CONFIRM_BOOKING_FAILURE,
    PAST_BOOKINGS_FAILURE
  ], unautorizedFailEffect);
}

export function *unautorizedFailEffect (action) {
  if (action && action.error && action.error.status === 401) {
    // No history object by this point since HYDRO action occurs before router setup
    // So resort to hard window refresh
    window.location.reload();
    yield put({type: LOG_OUT});
  }
}
