import moment from 'moment';
import SitterProfile from 'models/sitter/Profile';

export default class ParentNote {
  static withTrustedBody (body) {
    let note = new ParentNote();
    note.body = body;
    note.author = new SitterProfile({firstName: 'Trusted'});
    note.isTrusted = true;
    return note;
  }

  constructor (json = {}) {
    this.body = json.body || '';
    this.author = new SitterProfile(json.sitter);
    this.isTrusted = false;
    this.createdAt = json.created_at ? moment(json.created_at) : moment();
  }
}
