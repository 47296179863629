import React from 'react';
import {Field} from 'redux-form';
import PropTypes from 'prop-types';
import Toggle from 'forms/controls/Toggle';

export default class NotificationSettings extends React.Component {
  static propTypes = {
    settings: PropTypes.array,
  }

  render () {
    return (
      <div>
        {this.props.settings.map((setting, key) => {
          return (
            <Field
                component={Toggle}
                content={(<span>{setting.title}</span>)}
                key={key}
                name={setting.key}
            />
          );
        })}
      </div>
    );
  }
}
