import React from 'react';
import PropTypes from 'prop-types';

export default class VideoPlayer extends React.Component {
  static propTypes = {
    posterUrl: PropTypes.string.isRequired,
    videoUrl: PropTypes.string.isRequired
  };

  render () {
    const {
      posterUrl,
      videoUrl
    } = this.props;
    return (
      <video
          className="c-video-player"
          controls
          poster={posterUrl}
          src={videoUrl}
      />
    );
  }
}
