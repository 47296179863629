import React from 'react';
import PropTypes from 'prop-types';
import {
  FormSection,
  reduxForm
} from 'redux-form';

import {track} from 'utils/track';
import FormButton from 'forms/controls/FormButton';
import WaitListField from 'forms/fields/WaitList';
import {
  validateFields,
  validateWaitList
} from 'utils/formValidations';

export class WaitListComponent extends React.Component {
  static propTypes = {
    zipCode: PropTypes.string.isRequired
  };

  componentDidMount () {
    track('Sign Up Page - Wait List');
  }

  render () {
    const {
      handleSubmit,
      zipCode
    } = this.props;
    return (
      <div>
        <p>{`Sorry \u2014 we're not currently serving ${zipCode}. If you'd like to leave your email, we'll notify you when we launch in your area.`}</p>
        <form onSubmit={handleSubmit}>
          <FormSection name="waitList">
            <WaitListField />
          </FormSection>
          <div>
            <FormButton
                className="c-form-button--primary c-form-button--block"
                label="Next"
                type="submit"
            />
          </div>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'waitList',
  validate: validateFields({
    waitList: validateWaitList
  })
})(WaitListComponent);
