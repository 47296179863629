import React from 'react';

import FormField from 'forms/controls/FormField';

export default class WaitList extends React.Component {
  render () {
    return (
      <FormField
          fields={[
            {
              name: 'email',
              placeholder: 'Email address',
              type: 'email'
            }
          ]}
          icon="Email"
      />
    );
  }
}
