import moment from 'moment';

export const endTime = values => {
  const errors = {};
  if (!values || !values.time) {
    errors.time = 'Required';
  } else if (values.time.isAfter(moment())) {
    errors.time = 'Time cannot be in the future';
  }
  return errors;
};

export const parentNote = values => {
  const errors = {};
  if (!values || !values.note) {
    errors.note = 'Required';
  }
  return errors;
};
