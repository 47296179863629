import React from 'react';
import * as SVGIcon from 'components/SVGIcon';

const InfoWindow = (props) => {
  const { location } = props;

  return (
    <div className="map-address-container">
      <div style={{ fontWeight: 'bold' }}>
        {location.street} {location.apartment}
      </div>
      <div>
        {location.city}, {location.state} {location.zipCode}
      </div>
    </div>
  );
};

const MapMarker = ({location}) => {
  return (
     <div className="c-icon-booking-detail">
        <SVGIcon.Pin />
        <InfoWindow location={location} />
    </div>
  );
};

export default MapMarker;
