export function addAvailability (authToken, availabilityCallback, unavailabilityCallback, sitterCoverageZones) {
  return ({schedule}) => {
    return new Promise((resolve, reject) => {
      if (schedule.isUnavailable) {
        unavailabilityCallback(authToken, schedule.start.format(), schedule.end.format(), resolve, reject);
      } else {
        const sitterNonParentCoverageZones = sitterCoverageZones.filter(z => z.depth !== 2).map(z => z.id)
        const mergedUniqueZones = Array.from(new Set([...schedule.coverageZones, ...sitterNonParentCoverageZones]));
        availabilityCallback(authToken, schedule.start.format(), schedule.end.format(), mergedUniqueZones, resolve, reject);
      }
    });
  };
}

export function deleteAvailability (authToken, id, callback) {
  return () => {
    return new Promise((resolve, reject) => {
      callback(authToken, id, resolve, reject);
    });
  };
}

export function afterAdd (callback) {
  return () => {
    return new Promise(resolve => {
      callback();
      resolve();
    });
  };
}
