import React from 'react';

import FormButton from '../controls/Button';
import PropTypes from 'prop-types';

export default class AvailableButton extends React.Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired
  }

  render () {
    return (
      <FormButton
          className="c-form-button--primary c-form-button--block"
          label="Yes, I'm Available!"
          onClick={this.props.onClick}
          type="button"
      />
    );
  }
}
