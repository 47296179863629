import React from 'react';
import PropTypes from 'prop-types';

export default class Select extends React.Component { // eslint-disable-line react/no-multi-comp
  static propTypes = {
    onFormChange: PropTypes.func,
    options: PropTypes.array.isRequired,
  }

  handleChange = event => {
    this.props.input.onChange(event.target.value);
    this.props.onFormChange(this.props.input.name, event.target.value);
  }

  render () {
    return (
      <select
          className="c-time-select"
          defaultValue={this.props.options[0].value}
          onChange={this.handleChange}
      >
        {this.props.options.map(option => (
          <option
              key={option.value}
              value={option.value}
          >{option.label}</option>
        ))}
      </select>
    );
  }
}
