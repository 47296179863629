import React from 'react';
import {connect} from 'react-redux';

import {closeModal as closeModalAction} from 'actions/modal';
import NevermindButton from 'forms/fields/NevermindButton';
import PropTypes from 'prop-types';

export class ConfirmSearchResponse extends React.Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
    confirmButton: PropTypes.func.isRequired
  }

  render () {
    const ConfirmButton = this.props.confirmButton;
    return (
      <div className="o-layout">
        <div className="o-layout__item u-1/1">
          <p className="u-center-text">{this.props.message}</p>
        </div>
        <div className="o-layout__item u-1/1">
          <div className="o-layout">
            <div className="o-layout__item u-1/1 u-3/7@tablet">
              <ConfirmButton
                  onClick={() => {
                    this.props.onConfirm();
                    this.props.closeModal();
                  }}
              />
            </div>
            <div className="o-layout__item u-1/1 u-3/7@tablet u-push-1/7@tablet">
              <NevermindButton onClick={() => this.props.closeModal()} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const makeMapStateToProps = () => {
  return () => {
    return {
    };
  };
};

export default connect(makeMapStateToProps, {
  closeModal: closeModalAction
})(ConfirmSearchResponse);
