import React from 'react';
import {Field} from 'redux-form';
import PropTypes from 'prop-types';
import Toggle from '../controls/Toggle';

export default class UpToDateInput extends React.Component {
  static propTypes = {
    copy: PropTypes.string.isRequired
  };

  render () {
    return (
      <Field
          component={Toggle}
          content={(<span>{this.props.copy}</span>)}
          name="accepted"
      />
    );
  }
}
