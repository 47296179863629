import {LOG_OUT} from 'constants/session';

import ParentNoteModel from 'models/sitter/ParentNote';
import {
  PAST_BOOKINGS_REQUEST,
  PAST_BOOKINGS_SUCCESS,
  FUTURE_BOOKINGS_REQUEST,
  FUTURE_BOOKINGS_SUCCESS,
  LOAD_BOOKING_DETAILS_SUCCESS,
  LOAD_PARENT_SITTER_NOTES_REQUEST,
  LOAD_PARENT_SITTER_NOTES_SUCCESS
} from 'constants/sitter/bookings';

export function getDefaultState () {
  return {
    pastBookingsCount: 0,
    futureBookingsCount: 0,
    sitterParentNotes: [],
    pastBookings: [],
    futureBookings: [],
    bookingDetails: null
  };
}

export default function (state = getDefaultState(), action) {
  if (action.type === LOG_OUT) {
    return {
      ...state,
      ...getDefaultState()
    };
  }
  if (action.type === LOAD_PARENT_SITTER_NOTES_REQUEST) {
    return {
      ...state,
      sitterParentNotes: []
    };
  }
  if (action.type === LOAD_PARENT_SITTER_NOTES_SUCCESS) {
    return {
      ...state,
      sitterParentNotes: action.response.result.parent_sitter_notes.map(i => new ParentNoteModel(action.response.entities.note[i]))
    };
  }
  if (action.type === LOAD_BOOKING_DETAILS_SUCCESS) {
    return {
      ...state,
      bookingDetails: action.response.entities.bookings[action.response.result]
    };
  }
  if (action.type === PAST_BOOKINGS_SUCCESS) {
    return {
      ...state,
      pastBookingsCount: action.response.result.total_count,
      pastBookings: action.response.result.bookings.map(i => action.response.entities.bookings[i])
    };
  }
  if (action.type === PAST_BOOKINGS_REQUEST) {
    return {
      ...state,
      pastBookings: []
    };
  }
  if (action.type === FUTURE_BOOKINGS_SUCCESS) {
    return {
      ...state,
      futureBookingsCount: action.response.result.total_count,
      futureBookings: action.response.result.bookings.map(i => action.response.entities.bookings[i])
    };
  }
  if (action.type === FUTURE_BOOKINGS_REQUEST) {
    return {
      ...state,
      futureBookings: []
    };
  }
  return state;
}
