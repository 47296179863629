import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {track} from 'utils/track';
import {
  closeModal as closeModalAction
} from 'actions/modal';
import {
  setTitle as setTitleAction,
} from 'actions/navigation';
import {
  getFutureBookings,
  getFutureBookingsPageCount
} from 'selectors/bookings';
import BookingListItem from 'components/BookingListItem';
import Paginate from 'components/Paginate';
import {FUTURE_BOOKINGS_PAGE} from 'constants/sitter/navigation';
import {
  loadFutureBookings
} from 'actions/sitter/bookings';
import {
  getAuthToken,
  getCurrentPage,
} from 'selectors/session';
import {
  getBookingsPerPage,
} from 'selectors/bookings';

export class FutureBookings extends React.Component {
  static propTypes = {
    currentPage: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    closeModal: PropTypes.func.isRequired,
    setTitle: PropTypes.func.isRequired,
    futureBookings: PropTypes.array.isRequired,
    getFutureBookings: PropTypes.func.isRequired,
    authToken: PropTypes.string.isRequired,
    perPage: PropTypes.number.isRequired,
  }

  componentDidMount () {
    this.props.setTitle('Upcoming Bookings');
    track('Upcoming Bookings Page - Enter');

    this.props.getFutureBookings(this.props.authToken, this.props.perPage, this.props.currentPage)
  }

  hrefBuilder (pageIndex) {
    return `/${FUTURE_BOOKINGS_PAGE}/${pageIndex}`;
  }

  handlePagination = pageIndex => {
    this.props.history.push(this.hrefBuilder(pageIndex))
    this.props.getFutureBookings(this.props.authToken, this.props.perPage, pageIndex)
  }

  render () {
    return (
      <div className="o-layout">
        <div className="o-layout__item u-1/1">
          <h1 className="c-h1 c-page-title">{'Upcoming Bookings'}</h1>
          {!this.props.futureBookings.length && (
            <p className="u-center-text">{'Your bookings will appear here.  Once a parent books you, you can review your booking details here.'}</p>
          )}
          {!!this.props.futureBookings.length && this.props.futureBookings.map(booking => (
            <BookingListItem
                closeModal={this.props.closeModal}
                key={booking.id}
                {...{booking}}
            />
          ))}
          {(this.props.pageCount > 1) && (
            <Paginate
                currentPage={this.props.currentPage}
                hrefBuilder={this.hrefBuilder}
                onPageChange={this.handlePagination}
                pageCount={this.props.pageCount}
            />
          )}
        </div>
      </div>
    );
  }
}

const makeMapStateToProps = () => {
  return (state, ownProps) => {
    return {
      currentPage: getCurrentPage(state, ownProps),
      pageCount: getFutureBookingsPageCount(state, ownProps),
      futureBookings: getFutureBookings(state, ownProps),
      authToken: getAuthToken(state, ownProps),
      perPage: getBookingsPerPage(state, ownProps),
    };
  };
};

const mapDispatchToProps = {
  setTitle: setTitleAction,
  closeModal: closeModalAction,
  getFutureBookings: loadFutureBookings
};

export default connect(makeMapStateToProps, mapDispatchToProps)(FutureBookings);
