import {asYouType} from 'libphonenumber-js';
import jQuery from 'jquery';
import payment from 'jquery.payment'; // eslint-disable-line no-unused-vars

export const formatters = {
  phone: phoneNumber => (new asYouType('US').input(phoneNumber)),

  JSON: jsonString => {
    if (!jsonString) {
      return '';
    }
    return JSON.parse(jsonString);
  },

  cardNumber: input => {
    if (!input) {
      return '';
    }
    return input.replace(/\D/g, '').match(/.{1,4}|^$/g).join(' ').slice(0, 19);
  },

  cvv: input => {
    if (!input) {
      return '';
    }
    return input.slice(0, 4);
  },

  expirationDate: input => jQuery.payment.formatExpiry(input || '')
};

export const parsers = {
  phone: phoneNumber => (phoneNumber.match(/\d+/g) || []).join(''),

  JSON: pojo => JSON.stringify(pojo),

  cardNumber: input => {
    if (!input || /\d/.test(input) === false) {
      return '';
    }
    return input.match(/\d+/g).join('');
  }
};

