import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {track} from 'utils/track';
import {setTitle as setTitleAction} from 'actions/navigation';
import {getTypeformOptions} from 'selectors/sitter/typeform';
import Typeform from 'components/sitter/Typeform';

export class ScheduleInterview extends React.Component {
  static propTypes = {
    setTitle: PropTypes.func.isRequired,
    typeformOptions: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      phoneNumber: PropTypes.string.isRequired,
      zipCode: PropTypes.string.isRequired,
      formUrl: PropTypes.string.isRequired,
    }).isRequired
  };

  componentDidMount () {
    this.props.setTitle('Trusted');
    track('Typeform Page - Enter');
  }

  render () {
    return (
      <div className="o-layout">
        <Typeform {...this.props.typeformOptions} />
      </div>
    );
  }
}

export const makeMapStateToProps = () => {
  return (state, ownProps) => {
    return {
      typeformOptions: getTypeformOptions(state, ownProps)
    };
  };
};

export default connect(makeMapStateToProps, {
  setTitle: setTitleAction
})(ScheduleInterview);
