export const LOAD_SEARCH_STATE = 'SEARCH_LOAD_SEARCH_STATE';
export const SEARCH_FAILURE = 'SEARCH_FAILURE';
export const SEARCH_REQUEST = 'SEARCH_REQUEST';
export const SEARCH_SUBMIT = 'SEARCH_SUBMIT';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SET_CHILDREN_COUNT = 'SEARCH_SET_CHILDREN_COUNT';
export const SET_END_TIME = 'SEARCH_SET_END_TIME';
export const SET_LOCATION = 'SEARCH_SET_LOCATION';
export const SET_START_TIME = 'SEARCH_SET_START_TIME';
export const SET_TARGET_DATE = 'SEARCH_SET_TARGET_DATE';
export const OPEN_SEARCH_REQUEST = 'OPEN_SEARCH_REQUEST';
export const OPEN_SEARCH_SUCCESS = 'OPEN_SEARCH_SUCCESS';
export const OPEN_SEARCH_FAILURE = 'OPEN_SEARCH_FAILURE';
export const CLOSE_SEARCH_REQUEST = 'CLOSE_SEARCH_REQUEST';
export const CLOSE_SEARCH_SUCCESS = 'CLOSE_SEARCH_SUCCESS';
export const CLOSE_SEARCH_FAILURE = 'CLOSE_SEARCH_FAILURE';
export const LIST_OPEN_SEARCHES_REQUEST = 'LIST_OPEN_SEARCHES_REQUEST';
export const LIST_OPEN_SEARCHES_SUCCESS = 'LIST_OPEN_SEARCHES_SUCCESS';
export const LIST_OPEN_SEARCHES_FAILURE = 'LIST_OPEN_SEARCHES_FAILURE';
export const SET_CONVERSATION_ID = 'SET_CONVERSATION_ID';
export const FIND_SEARCH_REQUEST = 'SEARCH_FIND_SEARCH_REQUEST';
export const FIND_SEARCH_SUCCESS = 'SEARCH_FIND_SEARCH_SUCCESS';
export const FIND_SEARCH_FAILURE = 'SEARCH_FIND_SEARCH_FAILURE';
