import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Field,
  Fields
} from 'redux-form';

import * as SVGIcon from 'components/SVGIcon';

export default class FormField extends React.Component {
  static propTypes = {
    icon: PropTypes.string,
    fields: PropTypes.arrayOf(PropTypes.shape({
      className: PropTypes.string,
      format: PropTypes.func,
      name: PropTypes.string.isRequired,
      normalize: PropTypes.func,
      parse: PropTypes.func,
      placeholder: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired
    })),
    hint: PropTypes.string
  };

  renderFields (props) {
    const {
      fields,
      hint,
      icon
    } = props;
    const errors = fields.map(({name}) => {
      const {
        meta: {
          error,
          touched
        } // refactor, ¯\_(ツ)_/¯ @ next line
      } = eval(`props.${name}`); // eslint-disable-line no-eval
      return touched && error; // refactor, `touched` might not be behaving as expected?
    }).filter(i => i);
    let error = errors[0] || props.error;
    if (error === 'Not Authorized!') {
      error = 'Incorrect email or password'; // refactor, this isn't a good solution
    }
    const message = error || hint;
    const className = classNames({
      'c-form-field': true,
      'c-form-field--error': !!error
    });
    const Icon = SVGIcon[icon];
    return (
      <div {...{className}}>
        <div className="o-media">
          <div className="o-media__img c-form-field__img">
            {icon && (<Icon />)}
          </div>
          <div className="o-media__body">
            <div className="c-form-field__control">
              {fields.map((field, key) => {
                const {
                  format,
                  name,
                  normalize,
                  parse,
                  placeholder,
                  type
                } = field;
                const inputClassName = classNames({
                  'c-form-field__input': true,
                  [field.className]: field.className && true
                });
                return (
                  <Field
                      className={inputClassName}
                      component="input"
                      {...{key, placeholder, type, format, normalize, parse, name}}
                  />
                );
              })}
            </div>
            <div className="c-form-field__hint">{message}</div>
          </div>
        </div>
      </div>
    );
  }

  render () {
    const {
      fields,
      hint,
      icon
    } = this.props;
    return (
      <Fields
          component={this.renderFields}
          names={fields.map(({name}) => name)}
          {...{fields, hint, icon}}
      />
    );
  }
}
