import React from 'react';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';

export default class Pagination extends React.Component {
  static propTypes = {
    currentPage: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    hrefBuilder: PropTypes.func,
    pageCount: PropTypes.number.isRequired
  }

  handlePageChange = ({selected}) => {
    this.props.onPageChange(selected + 1);
  }

  render () {
    return (
      <ReactPaginate
          activeClassName="is-current"
          breakClassName="c-pagination__item"
          breakLabel={(
            <a className="c-pagination__link">{'...'}</a>
          )}
          containerClassName="c-pagination"
          disableInitialCallback
          hrefBuilder={this.props.hrefBuilder}
          initialPage={this.props.currentPage - 1}
          marginPagesDisplayed={0}
          nextClassName="c-pagination__item c-pagination__item--next"
          nextLinkClassName="c-pagination__link"
          onPageChange={this.handlePageChange}
          pageClassName="c-pagination__item"
          pageCount={this.props.pageCount}
          pageLinkClassName="c-pagination__link"
          pageRangeDisplayed={3}
          previousClassName="c-pagination__item c-pagination__item--prev"
          previousLinkClassName="c-pagination__link"
      />
    );
  }
}
