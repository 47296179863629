import {CALL_API} from 'middleware/api';
import {searches as searchesSchema} from 'schemas/search';
import {booking as bookingSchema} from 'schemas/booking';
import {
  RESPOND_ALL_REQUEST,
  RESPOND_ALL_SUCCESS,
  RESPOND_ALL_FAILURE,
  RESPOND_ONE_TIME_REQUEST,
  RESPOND_ONE_TIME_SUCCESS,
  RESPOND_ONE_TIME_FAILURE,
  RESPOND_RECURRING_REQUEST,
  RESPOND_RECURRING_SUCCESS,
  RESPOND_RECURRING_FAILURE,
  RESPOND_INTERESTED_REQUEST,
  RESPOND_INTERESTED_SUCCESS,
  RESPOND_INTERESTED_FAILURE,
  LOAD_ALL_SEARCHES_REQUEST,
  LOAD_ALL_SEARCHES_SUCCESS,
  LOAD_ALL_SEARCHES_FAILURE,
  RESPOND_BOOKING_REQUEST,
  RESPOND_BOOKING_SUCCESS,
  RESPOND_BOOKING_FAILURE,
  LOAD_ONE_TIME_SEARCHES_REQUEST,
  LOAD_ONE_TIME_SEARCHES_SUCCESS,
  LOAD_ONE_TIME_SEARCHES_FAILURE,
  LOAD_RECURRING_SEARCHES_REQUEST,
  LOAD_RECURRING_SEARCHES_SUCCESS,
  LOAD_RECURRING_SEARCHES_FAILURE,
  LOAD_INTERESTED_SEARCHES_REQUEST,
  LOAD_INTERESTED_SEARCHES_SUCCESS,
  LOAD_INTERESTED_SEARCHES_FAILURE,
  SET_SEARCH_CURRENT_PAGE,
  SHOW_INTRO_MODAL,
  SHOW_IN_TOUCH_MODAL
} from 'constants/sitter/search';

export function openInTouchModal () {
  return {
    type: SHOW_IN_TOUCH_MODAL
  };
}

export function showIntroModal () {
  return {
    type: SHOW_INTRO_MODAL
  };
}

export function setCurrentPage (currentPage) {
  return {
    type: SET_SEARCH_CURRENT_PAGE,
    currentPage
  };
}

export function loadAllSearches (authToken, perPage, pageIndex) {
  return {
    ...loadSearches(authToken, null, perPage, pageIndex, [
      LOAD_ALL_SEARCHES_REQUEST,
      LOAD_ALL_SEARCHES_SUCCESS,
      LOAD_ALL_SEARCHES_FAILURE
    ])
  };
}

export function loadOneTimeSearches (authToken, perPage, pageIndex) {
  return {
    ...loadSearches(authToken, 'one_time', perPage, pageIndex, [
      LOAD_ONE_TIME_SEARCHES_REQUEST,
      LOAD_ONE_TIME_SEARCHES_SUCCESS,
      LOAD_ONE_TIME_SEARCHES_FAILURE
    ])
  };
}

export function loadRecurringSearches (authToken, perPage, pageIndex) {
  return {
    ...loadSearches(authToken, 'recurring', perPage, pageIndex, [
      LOAD_RECURRING_SEARCHES_REQUEST,
      LOAD_RECURRING_SEARCHES_SUCCESS,
      LOAD_RECURRING_SEARCHES_FAILURE
    ])
  };
}

export function loadInterestedSearches (authToken, perPage, pageIndex) {
  return {
    ...loadSearches(authToken, 'interested', perPage, pageIndex, [
      LOAD_INTERESTED_SEARCHES_REQUEST,
      LOAD_INTERESTED_SEARCHES_SUCCESS,
      LOAD_INTERESTED_SEARCHES_FAILURE
    ])
  };
}

export const loadSearches = (authToken, scope, perPage, pageIndex, types) => {
  const params = [
    `per_page=${perPage}`,
    `page=${pageIndex}`
  ];
  if (scope) {
    params.push(`scope=${scope}`);
  }
  return {
    [CALL_API]: {
      endpoint: `sitter/searches?${params.join('&')}`,
      headers: {
        'X-Identity-Token': authToken,
        'X-Identity-Key': process.env.SECRET_KEY
      },
      method: 'GET',
      schema: searchesSchema,
      types
    }
  };
};

export function allSearchResponse (authToken, searchId, history, interested) {
  return {
    ...searchRespond(authToken, searchId, interested, history, [
      RESPOND_ALL_REQUEST,
      RESPOND_ALL_SUCCESS,
      RESPOND_ALL_FAILURE
    ])
  };
}

export function oneTimeSearchResponse (authToken, searchId, history, interested) {
  return {
    ...searchRespond(authToken, searchId, interested, history, [
      RESPOND_ONE_TIME_REQUEST,
      RESPOND_ONE_TIME_SUCCESS,
      RESPOND_ONE_TIME_FAILURE
    ])
  };
}

export function recurringSearchResponse (authToken, searchId, history, interested) {
  return {
    ...searchRespond(authToken, searchId, interested, history, [
      RESPOND_RECURRING_REQUEST,
      RESPOND_RECURRING_SUCCESS,
      RESPOND_RECURRING_FAILURE
    ])
  };
}

export function interestedSearchResponse (authToken, searchId, history, interested) {
  return {
    ...searchRespond(authToken, searchId, interested, history, [
      RESPOND_INTERESTED_REQUEST,
      RESPOND_INTERESTED_SUCCESS,
      RESPOND_INTERESTED_FAILURE
    ])
  };
}

export const searchRespond = (authToken, searchId, interested, history, types) => {
  return {
    [CALL_API]: {
      endpoint: `sitter/searches/${searchId}/responses`,
      method: 'POST',
      data: {
        interested
      },
      headers: {
        'X-Identity-Token': authToken,
        'X-Identity-Key': process.env.SECRET_KEY
      },
      schema: searchesSchema,
      types,
      context: {
        history
      }
    }
  };
};

export function searchToBooking (authToken, searchId, history) {
  return {
    [CALL_API]: {
      endpoint: `sitter/searches/${searchId}/bookings`,
      method: 'POST',
      headers: {
        'X-Identity-Token': authToken,
        'X-Identity-Key': process.env.SECRET_KEY
      },
      schema: bookingSchema,
      types: [
        RESPOND_BOOKING_REQUEST,
        RESPOND_BOOKING_SUCCESS,
        RESPOND_BOOKING_FAILURE
      ],
      context: {
        history
      }
    }
  };
}
