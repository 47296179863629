import {
  takeEvery,
  put
} from 'redux-saga/effects';

import {HUD_COMPLETE} from 'constants/hud';
import {
  hideHud,
  // updateHud
} from 'actions/hud';

// const defaultDelay = 1200;

export function *hudComplete () {
  yield takeEvery(HUD_COMPLETE, hudCompleteEffect);
}

export function *hudCompleteEffect (/* action */) {
  /*
  const {
    options: {
      delay,
      title
    }
  } = action;
  yield put(updateHud({title, complete: true}));
  yield call(delaySaga, delay || defaultDelay);
  */
  yield put(hideHud());
}
