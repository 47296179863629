import React from 'react';
import {Field} from 'redux-form';
import PropTypes from 'prop-types';
import SelectControl from 'forms/controls/Select';

export default class OptionsSelect extends React.Component {
  static propTypes = {
    onFormChange: PropTypes.func,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    normalize: PropTypes.func
  }

  render () {
    return (
      <div className="o-layout u-margin-bottom">
        <div className="o-layout__item u-1/5 u-right-text">
          <label className="c-time-picker-label">{this.props.label}</label>
        </div>
        <div className="o-layout__item u-4/5">
          <Field
              component={SelectControl}
              name={this.props.name}
              normalize={this.props.normalize}
              onFormChange={this.props.onFormChange}
              options={this.props.options}
          />
        </div>
      </div>
    );
  }
}
