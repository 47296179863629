import React from 'react';
import {
  Redirect,
  Route,
} from 'react-router-dom';
import {
  SIGN_UP_ZIP_PAGE as SITTER_FIRST_PAGE,
  TYPEFORM_PAGE,
  APPROVED_PAGE,
  REJECTED_PAGE,
  COMPLETE_REGISTRATION_PAGE
} from 'constants/sitter/navigation';
import {
  SIGN_UP_ZIP_PAGE as PARENT_FIRST_PAGE
} from 'constants/parent/navigation';

function validateStatus (route) {
  const status = route.status
  const pathname = route.location.pathname
  if (status) {
    if (!status.has_onboarded && status.accepted) {
      return (
        <Redirect to={`/${APPROVED_PAGE}`} />
      )
    } else if (status.rejected) { // eslint-disable-line no-constant-condition
      return (
        <Redirect to={`/${REJECTED_PAGE}`} />
      )
    } else if (!status.has_profile) {
      return (
        <Redirect to={`/${COMPLETE_REGISTRATION_PAGE}`} />
      )
    } else if (!status.has_onboarded && !(pathname === `/${TYPEFORM_PAGE}`)) {
      return (
        <Redirect to={`/${TYPEFORM_PAGE}`} />
      )
    }
  }

  return (
    <Route
        {...route}
    />
  )
}

export default function AuthenticatedRoute (route) {
  const loginPage = process.env.APP_ROLE === 'parent' ? PARENT_FIRST_PAGE : SITTER_FIRST_PAGE;
  if (route.isAuthenticated) {
    return validateStatus(route);
  } else {
    return (
      <Redirect to={`/${loginPage}`} />
    )
  }
}
