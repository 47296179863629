import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import StarRating from 'components/StarRating';
import PortraitFrame from 'components/PortraitFrame';
import Paginate from 'components/Paginate';

export default class ProfileReviews extends React.Component {
  static propTypes = {
    currentPage: PropTypes.number.isRequired,
    hrefBuilder: PropTypes.func,
    isAuthenticated: PropTypes.bool.isRequired,
    onPageChange: PropTypes.func.isRequired,
    pageCount: PropTypes.number.isRequired,
    reviews: PropTypes.array
  }

  buildAgeCopy (child, bookingDate) {
    const {
      date_of_birth // eslint-disable-line camelcase
    } = child;
    const duration = moment.duration(moment(bookingDate, 'YYYY-MM-DD').diff(moment(date_of_birth, 'YYYY-MM-DD')));
    let magnitude = 'yr';
    let age = duration.years();
    if (!age) {
      magnitude = 'mo';
      age = duration.months();
    }
    return `${age} ${magnitude}${age === 1 ? '' : 's'}`;
  }

  render () {
    const {
      isAuthenticated,
      reviews
    } = this.props;
    const sortedReviewKeys = Object.keys(reviews).sort((a, b) => {
      return moment(reviews[a].booking_date, 'YYYY-MM-DD').isBefore(moment(reviews[b].booking_date, 'YYYY-MM-DD'));
    });
    return (
      <div>
        <ul className="o-list-bare c-sitter-reviews">
          {sortedReviewKeys.map((reviewId, key) => {
            const review = reviews[reviewId];
            return (
              <li
                  className="c-sitter-reviews__entry c-sitter-review"
                  {...{key}}
              >
                <div className="o-layout">
                  <div className="o-layout__item u-1/2">
                    <div className="o-media">
                      <div className="o-media__img">
                        <PortraitFrame
                            classes={['c-portrait-frame--small']}
                            src={review.reviewer_photo_url}
                        />
                      </div>
                      <div className="o-media__body">
                        <div className="c-h5">{`${review.reviewer_name || review.reference_author}.`}</div>
                        <StarRating
                            count={5}
                            width={(+review.rating / 5) * 100}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="o-layout__item u-1/2">
                    <div className="c-sitter-review__date c-text-small c-text-small--muted">{review.booking_date || review.reference_date}</div>
                    {(isAuthenticated && review.child_profiles.length > 0) && (
                      <div className="c-sitter-review__age"><span className="c-text-small c-text-small--stronger">{review.child_profiles.length > 1 ? 'Ages' : 'Age'}</span>{' '}<span className="c-text-small">{review.child_profiles.map(child => this.buildAgeCopy(child, review.booking_date)).join(', ')}</span></div>
                    )}
                    {(isAuthenticated && review.child_profiles.length <= 0 && review.reference_children) && (
                      <div className="c-sitter-review__age"><span className="c-text-small c-text-small--stronger">{'Age(s)'}</span>{' '}<span className="c-text-small">{review.reference_children}</span></div>
                    )}
                  </div>
                </div>
                <p className="c-sitter-reviews__body c-sitter-reviews__body--indented">{review.body}</p>
              </li>
            );
          })}
        </ul>
        {isAuthenticated && (
          <Paginate
              currentPage={this.props.currentPage}
              hrefBuilder={this.props.hrefBuilder}
              onPageChange={this.props.onPageChange}
              pageCount={this.props.pageCount}
          />
        )}
      </div>
    );
  }
}
