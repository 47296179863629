import React from 'react';
import Markdown from 'react-markdown';
import {
  reduxForm,
  FormSection
} from 'redux-form';
import PropTypes from 'prop-types';

import ContinueButton from 'forms/fields/ContinueButton';
import {
  validateFields,
  afterAdd as validateAfterAdd
} from '../utils/formValidations';
import UpToDateToggle from 'forms/fields/UpToDateInput';

export class AfterAdd extends React.Component {
  static propTypes = {
    copy: PropTypes.object.isRequired
  };

  render () {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <Markdown source={this.props.copy.body_markdown} />
        <FormSection name="afterAdd">
          <UpToDateToggle copy={this.props.copy.check} />
        </FormSection>
        <ContinueButton checkCopy={this.props.copy.action} />
      </form>
    );
  }
}

export default reduxForm({
  form: 'afterAdd',
  validate: validateFields({
    afterAdd: validateAfterAdd
  })
})(AfterAdd);
