import SearchResponseConfirmModal from 'containers/sitter/ConfirmSearchResponse';
import AvailableButton from 'forms/fields/AvailableButton';
import BookButton from 'forms/fields/BookButton';
import UnavailableButton from 'forms/fields/UnavailableButton';

export function searchInterest (authToken, openModal, callback, showModalCallback, shouldShowModal, isBooking, copy, history) {
  return values => {
    return new Promise((resolve, reject) => {
      const displayAsBook = isBooking
      let message = 'Are you sure you\'re unavailable?';
      let confirmButton = UnavailableButton;
      if (values.search.interested) {
        message = displayAsBook ? copy.interested.one_time.body : 'Are you sure you\'re available?';
        confirmButton = displayAsBook ? BookButton : AvailableButton;
      }

      openModal(SearchResponseConfirmModal, 'Please Confirm', {
        confirmButton,
        message,
        onConfirm: () => {
          const {search} = values;
          callback(authToken, search.searchId, history, search.interested, resolve, reject);
          if (shouldShowModal) {
            showModalCallback();
          }
        }
      });
    });
  };
}
