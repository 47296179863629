import React from 'react';
import {
  Link,
  withRouter
} from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export class NavLink extends React.Component {
  static propTypes = {
    fuzzy: PropTypes.string,
    to: PropTypes.string.isRequired
  }

  render () {
    const className = classNames({
      'c-form-button c-form-button--nav': true,
      'c-form-button--inverse': !!this.props.fuzzy && !!this.props.location.pathname.match(this.props.fuzzy)
    });
    return (
      <Link
          activeclassname="c-form-button--inverse"
          to={this.props.to}
          {...{className}}
      >{this.props.children}</Link>
    );
  }
}

export default withRouter(NavLink);
