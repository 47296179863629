import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';

import {closeModal as closeModalAction} from 'actions/modal';
import AvailabilityAddForm from 'forms/sitter/AvailabilityAdd';
import TimeService from 'services/Time';
import {
  getAvailabilityAddExists,
  getAvailabilityAddEnd
} from 'selectors/sitter/availability';
import {getFeatures} from 'selectors/settings';

export class AddAvailability extends React.Component {
  static propTypes = {
    coverageZoneOptions: PropTypes.array.isRequired,
    allCoverageZones: PropTypes.array.isRequired,
    closeModal: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    endValue: PropTypes.object,
    onFormChange: PropTypes.func.isRequired,
    constants: PropTypes.object.isRequired,
    date: PropTypes.object.isRequired,
    upperBound: PropTypes.object.isRequired,
    lowerBound: PropTypes.object.isRequired,
    sitterCoverageZones: PropTypes.array.isRequired,
    features: PropTypes.object.isRequired
  }

  constructor (props) {
    super(props);
    this.state = {
      currentStart: this.defaultsWithProps().start
    };
  }

  defaultsWithProps () {
    const {
      upperBound,
      lowerBound,
      date,
      constants
    } = this.props;
    const timeService = new TimeService(constants);
    const {minDuration} = this.props.constants.availability;
    const startBounds = timeService.pickerStart({date, minDuration, lowerBound, upperBound});
    const endBounds = timeService.pickerEnd({date, minDuration, lowerBound, upperBound});
    const dateDefaultStart = timeService.dateDefaultStart({date});
    const dateDefaultEnd = timeService.dateDefaultEnd({date});
    const dateDefaultLatestValidStart = moment(dateDefaultEnd).subtract({seconds: constants.availability.minDuration});
    const dateDefaultEarliestValidEnd = moment(dateDefaultStart).add({seconds: constants.availability.minDuration});
    const defaultStart = (startBounds.min.isBefore(dateDefaultStart) && endBounds.max.isAfter(dateDefaultEarliestValidEnd)) ? dateDefaultStart : startBounds.min;
    const defaultEnd = (endBounds.max.isAfter(dateDefaultEnd) && startBounds.min.isBefore(dateDefaultLatestValidStart)) ? dateDefaultEnd : endBounds.max;
    return {
      start: defaultStart,
      end: defaultEnd
    };
  }

  handleSubmit = values => {
    this.props.onSubmit(values);
    this.props.closeModal();
  }

  handleFormChange = (name, value) => {
    if (name === 'schedule.start') {
      this.setState({currentStart: value});
      const bounds = {
        ...this.calculateBounds(this.props.lowerBound, this.props.upperBound, value)
      };
      if (this.props.endValue.isBefore(bounds.endBounds.min)) {
        this.props.onFormChange('availabilityAdd', 'schedule.end', bounds.endBounds.min);
      }
    }
  }

  calculateBounds (lowerBound, upperBound, currentStart) {
    const {
      date,
      constants
    } = this.props;
    const timeService = new TimeService(constants);
    const {minDuration} = constants.availability;
    const startBounds = timeService.pickerStart({date, minDuration, lowerBound, upperBound});
    const initialEndBounds = timeService.pickerEnd({date, minDuration, lowerBound, upperBound, currentStart});
    return {
      startBounds,
      endBounds: initialEndBounds
    };
  }

  render () {
    const bounds = {
      ...this.calculateBounds(this.props.lowerBound, this.props.upperBound, this.state.currentStart)
    };
    return (
      <div className="c-availability-view">
        <AvailabilityAddForm
            constants={this.props.constants}
            coverageZones={this.props.allCoverageZones}
            endBounds={bounds.endBounds}
            initialValues={{
              schedule: {
                ...this.defaultsWithProps(),
                coverageZones: this.props.coverageZoneOptions
              }
            }}
            onFormChange={this.handleFormChange}
            onSubmit={this.handleSubmit}
            sitterCoverageZones={this.props.sitterCoverageZones}
            startBounds={bounds.startBounds}
            unavailabilityEnabled={this.props.features.unavailability}
        />
      </div>
    );
  }
}

const makeMapStateToProps = () => {
  return (state, ownProps) => {
    return {
      endValue: getAvailabilityAddExists(state, ownProps) ? getAvailabilityAddEnd(state, ownProps) : null,
      features: getFeatures(state, ownProps)
    };
  };
};

export default connect(makeMapStateToProps, {
  closeModal: closeModalAction
})(AddAvailability);
