import React from 'react';
import PropTypes from 'prop-types';
import {
  FormSection,
  reduxForm
} from 'redux-form';

import { validateFields } from 'utils/formValidations';
import {endTime as validateEndTime} from 'forms/validations/booking';
import SelectButton from 'forms/fields/SelectButton';
import CancelButton from 'forms/fields/CancelButton';
import EndTimeInput from 'forms/fields/EndTimeInput';

export class EndTime extends React.Component {
  static propTypes = {
    booking: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    date: PropTypes.object
  }

  render () {
    let date = this.props.date;
    if (date && date.isBefore(this.props.booking.start)) {
      date = date.clone().add(1, 'day');
    }
    return (
      <form onSubmit={this.props.handleSubmit}>
        <div className="o-layout">
          <div className="o-layout__item u-1/1 u-1/3@desktop u-push-1/3@desktop">
            <FormSection name="endTime">
              <EndTimeInput date={date} />
            </FormSection>
          </div>
        </div>
        <div className="o-layout">
          <div className="o-layout__item u-1/1 u-1/2@desktop">
            <SelectButton />
          </div>
          <div className="o-layout__item u-1/1 u-1/2@desktop">
            <CancelButton onClick={this.props.onCancel} />
          </div>
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: 'endTime',
  validate: validateFields({
    endTime: validateEndTime
  })
})(EndTime);
