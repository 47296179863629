import {createSelector} from 'reselect';

import {
  getSession,
  getId,
  getUser,
  getName,
  getPhoneNumber,
  getEmail,
  getZipCode,
  getPhotoUrl,
  getStatus
} from 'selectors/session';
import {
  getConstants
} from 'selectors/settings';
import ConstantsModel from 'models/sitter/Constants';
import TimeService from 'services/Time';
import AvailabilityService from 'services/Availability';

export const getInProgressBooking = createSelector(
  [getUser],
  user => user.in_progress_booking
);

export const getDefaultLocation = createSelector(
  [getUser],
  user => user.default_location
);

export const getGeolocation = createSelector(
  [getDefaultLocation],
  defaultLocation => defaultLocation.geolocation
);

export const getHasOnboarded = createSelector(
  [getStatus],
  status => status.has_onboarded
);

export const getIsAccepted = createSelector(
  [getStatus],
  status => status.accepted
);

export const getCompleteRegistrationFormValues = createSelector(
  [getName, getPhoneNumber, getEmail],
  (firstLastName, phoneNumber, email) => ({
    completeRegistration: {
      firstLastName,
      phoneNumber,
      email
    }
  })
);

export const getUserRegistrationValues = createSelector(
  [getPhotoUrl, getZipCode],
  (photoUrl, zipCode) => ({
    zipCode,
    photoUrl
  })
);

export const getUserSignupValues = () => {
  return {
    terms: true
  };
};

export const getIntercomOptions = createSelector(
  [getId, getEmail, getName],
  // eslint-disable-next-line camelcase
  (id, email, name) => ({user_id: `sitter_${id}`, email, name})
);

export const getCoverageZones = createSelector(
  [getSession],
  session => session.coverageZones
);

export const getCoverageZoneOptions = createSelector(
  [getCoverageZones],
  coverageZones => coverageZones.filter(z => z.depth === 2).map(z => z.id)
);

export const getConstantsModel = createSelector(
  [getConstants],
  constants => new ConstantsModel(constants)
);

export const getTimeService = createSelector(
  [getConstantsModel],
  constantsModel => new TimeService(constantsModel)
);

export const getAvailabilityService = createSelector(
  [getConstantsModel],
  constantsModel => new AvailabilityService(constantsModel)
);
