const defaultAvailabilityConstants = {
  day_start_time: '0000', // eslint-disable-line
  day_length: 36, // eslint-disable-line
  calendar_day_length: 24, // eslint-disable-line
  default_start_time: '0700', // eslint-disable-line
  default_length: 18.5, // eslint-disable-line
  calendar_days_ahead: 56, // eslint-disable-line
  min_length: 4, // eslint-disable-line
  max_length: 24, // eslint-disable-line
  padding: 1.5
};

const defaultBookingConstants = {
  day_start_time: '0000', // eslint-disable-line
  day_length: 36, // eslint-disable-line
  default_start_time: '0700', // eslint-disable-line
  default_length: 2, // eslint-disable-line
  start_time: '0700', // eslint-disable-line
  end_time: '0130', // eslint-disable-line
  min_length: 1, // eslint-disable-line
  max_length: 9, // eslint-disable-line
  min_lead_time: 2.5, // eslint-disable-line
  time_increment: 15, // eslint-disable-line
  contact_info_lead_time: 20 // eslint-disable-line
};

/**
 * dayStart: number
 * dayEnd: number
 * dayLatestStart: number
 * minDuration: number
 * maxDuration: number
 * minLeadTime: number
 * timeIncrement: number
 */
export class BookingConstants {
  constructor (json = {}) {
    const values = Object.assign({}, defaultBookingConstants, json);
    this.minDuration = values.min_length * 3600;
    this.maxDuration = values.max_length * 3600;
    this.minLeadTime = values.min_lead_time * 3600;
    this.timeIncrement = values.time_increment * 60;
    this.contactInfoLeadTime = values.contact_info_lead_time * 60;
    this.dayStart = (Math.floor(parseInt(values.day_start_time, 10) / 100) + ((parseInt(values.day_start_time, 10) % 100) / 60)) * 3600; // Start and end times come back as strings formatted as strings like '0700'
    this.dayEnd = this.dayStart + (values.day_length * 3600);
    this.dayLatestStart = this.dayEnd - this.minDuration;
  }
}

/**
 * calendarDaysAhead: number
 * minDuration: number
 * maxDuration: number
 * padding: number
 */
export class AvailabilityConstants {
  constructor (json = {}) {
    const values = Object.assign({}, defaultAvailabilityConstants, json);
    this.calendarDaysAhead = values.calendar_days_ahead;
    this.minDuration = values.min_length * 3600;
    this.maxDuration = values.max_length * 3600;
    this.padding = values.padding * 3600;
    this.dayStart = (Math.floor(parseInt(values.day_start_time, 10) / 100) + ((parseInt(values.day_start_time, 10) % 100) / 60)) * 3600;
    this.dayEnd = this.dayStart + (values.day_length * 3600);
    this.defaultStart = (Math.floor(parseInt(values.default_start_time, 10) / 100) + ((parseInt(values.default_start_time, 10) % 100) / 60)) * 3600;
    this.defaultEnd = this.defaultStart + (values.default_length * 3600);
    this.calendarDayEnd = this.dayStart + (values.calendar_day_length * 3600);
    this.dayLatestStart = this.dayEnd - this.minDuration;
  }
}

export default class Constants {
  constructor (json = {}) {
    this.isFetching = false;
    this.availability = new AvailabilityConstants(json.availability);
    this.booking = new BookingConstants(json.booking);
  }
}
