import React from 'react';
import {
  FormSection,
  reduxForm
} from 'redux-form';

import {track} from 'utils/track';
import FormButton from 'forms/controls/FormButton';
import ZipField from 'forms/fields/Zip';
import {
  validateFields,
  validateZip
} from 'utils/formValidations';

export class ZipComponent extends React.Component {
  componentDidMount () {
    track('Sign Up Page - Zip');
  }

  render () {
    const {
      handleSubmit
    } = this.props;
    return (
      <div>
        {this.props.errors && this.props.errors.values && this.props.errors.values.zip.error === 'error' ? (
          <p className="c-form-button--red-color">{'Service unavailable, please try again.'}</p>)
          : (<p>{'Please enter your zip code to check if we\'re available in your area.'}</p>)}
        <form onSubmit={handleSubmit}>
          <FormSection name="zip">
            <ZipField />
          </FormSection>
          <div>
            <FormButton
                className="c-form-button--primary c-form-button--block"
                label="Next"
                type="submit"
            />
          </div>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'zip',
  validate: validateFields({
    zip: validateZip
  })
})(ZipComponent);
