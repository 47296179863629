import React from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  ME_PAGE,
  REVIEWS_PAGINATION_PAGE
} from 'constants/sitter/navigation';
import {track} from 'utils/track';
import {
  setTitle as setTitleAction,
} from 'actions/navigation';
import {
  openModal as openModalAction
} from 'actions/modal';
import {
  loadReviews as loadReviewsAction
} from 'actions/sitter/reviews';
import {
  getProfilePresentation,
  getCurrentPage,
  getReviewsPageCount,
  getReviews,
  getAuthToken,
  getReviewsPerPage
} from 'selectors/session';
import {
  getBadges
} from 'selectors/settings';

import Button from 'forms/controls/Button';
import PortraitFrame from 'components/PortraitFrame';
import ProfileReviews from 'components/ProfileReviews';
import ProfileSkillBadges from 'components/ProfileSkillBadges';
import ProfileVerificationBadges from 'components/ProfileVerificationBadges';
import VideoPlayer from 'components/VideoPlayer';

export class Me extends React.Component {
  static propTypes = {
    reviewsCurrentPage: PropTypes.number.isRequired,
    reviewsPageCount: PropTypes.number.isRequired,
    providerProfile: PropTypes.object.isRequired,
    openModal: PropTypes.func.isRequired,
    reviews: PropTypes.array,
    setTitle: PropTypes.func.isRequired,
    authToken: PropTypes.string.isRequired,
    reviewsPerPage: PropTypes.number.isRequired,
    className: PropTypes.string,
    badges: PropTypes.object.isRequired
  }

  componentDidMount () {
    this.props.setTitle('Me');
    track('Me Page - Enter');

    this.props.loadReviews(this.props.authToken, this.props.reviewsPerPage, this.props.reviewsCurrentPage);
  }

  hrefBuilder (pageIndex) {
    return `/${ME_PAGE}/${REVIEWS_PAGINATION_PAGE}/${pageIndex}`;
  }

  handlePagination = pageIndex => {
    this.props.history.push(this.hrefBuilder(pageIndex))

    this.props.loadReviews(this.props.authToken, this.props.reviewsPerPage, pageIndex);
  }

  showVideo (videoUrl, posterUrl, title) {
    return () => {
      this.props.openModal(VideoPlayer, title, {posterUrl, videoUrl});
    };
  }

  render () {
    const className = classNames({
      'o-layout__item u-1/1 c-sitter-profile': true,
      ['u-1/2@desktop u-push-1/4@desktop']: true
    });

    return (
      <div className="o-layout c-sitter-profile-layout">
        <div {...{className}}>
          <div className="c-sitter-profile__details">
            <div className="o-layout">
              <div className="o-layout__item u-1/1 u-2/5@desktop">
                <div className="c-sitter-profile__action-menu u-hide@desktop">
                  <ul className="o-list-bare">
                    <li>
                      {/* favorite/unfavorite */}
                    </li>
                  </ul>
                </div>
                <PortraitFrame
                    classes={['c-portrait-frame--large c-portrait-frame--mobile']}
                    src={this.props.providerProfile.photoUrl || ''}
                />
                <div className="c-sitter-profile__play-video">
                  <Button
                      className="c-form-button--primary c-form-button--block c-sitter-profile__play-button"
                      icon="Play"
                      iconProps={{active: true}}
                      label="Play Video"
                      onClick={this.showVideo(this.props.providerProfile.videoUrl, this.props.providerProfile.photoUrl, this.props.providerProfile.firstName)}
                      type="button"
                  />
                </div>
              </div>
              <div className="o-layout__item u-1/1 u-3/5@desktop">
                <h2 className="c-sitter-profile__name c-h1">{`${this.props.providerProfile.firstName}`}</h2>
                <div className="c-sitter-profile__info c-text-small c-text-small--muted"><span>{`${this.props.providerProfile.city}, ${this.props.providerProfile.state}`}</span></div>
                <ProfileVerificationBadges
                    badges={this.props.badges}
                    verificationList={this.props.providerProfile.verificationList}
                />
                <ProfileSkillBadges
                    badges={this.props.badges}
                    skillsList={this.props.providerProfile.skillList}
                />
              </div>
            </div>
          </div>
          <div className="c-sitter-profile__summary">
            <div className="c-sitter-profile__experience">
              <h6 className="c-sitter-profile__experience-leader c-h4">{'Experience with'}</h6>
              <h5 className="c-text-strong c-text-strong--super-strong">{this.props.providerProfile.experienceCopy}</h5>
            </div>
            <p>{this.props.providerProfile.summary}</p>
          </div>
          {this.props.reviews ? (
            <div>
              <h6 className="c-sitter-profile__section-title c-h4">{`${this.props.providerProfile.reviewsCount} Reviews`}</h6>
              <ProfileReviews
                  currentPage={this.props.reviewsCurrentPage}
                  hrefBuilder={this.hrefBuilder}
                  isAuthenticated
                  onPageChange={this.handlePagination}
                  pageCount={this.props.reviewsPageCount}
                  reviews={this.props.reviews}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export const makeMapStateToProps = () => {
  return (state, ownProps) => {
    return {
      providerProfile: getProfilePresentation(state, ownProps),
      reviewsCurrentPage: getCurrentPage(state, ownProps),
      reviewsPageCount: getReviewsPageCount(state, ownProps),
      reviews: getReviews(state, ownProps),
      badges: getBadges(state, ownProps),
      authToken: getAuthToken(state, ownProps),
      reviewsPerPage: getReviewsPerPage(state, ownProps)
    };
  };
};

const mapDispatchToProps = {
  openModal: openModalAction,
  setTitle: setTitleAction,
  loadReviews: loadReviewsAction
};

export default connect(makeMapStateToProps, mapDispatchToProps)(Me);
