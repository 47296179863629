import React from 'react';
import PropTypes from 'prop-types';
import {
  reduxForm,
  FormSection
} from 'redux-form';

import NotificationSettings from 'forms/fields/NotificationSettings';
import FormButton from 'forms/controls/FormButton';
import PasswordConfirmFields from 'forms/fields/PasswordConfirm';
import {
  validateFields,
  validatePasswordChange,
} from 'utils/formValidations';

export class SettingsComponent extends React.Component {
  static propTypes = {
    settings: PropTypes.array.isRequired
  };

  render () {
    const {handleSubmit} = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <div className="o-layout">
          <div className="o-layout__item u-1/1 u-4/10@desktop">
            <h4 className="c-h4">{'Change Password'}</h4>
            <FormSection name="passwordChange">
              <PasswordConfirmFields />
            </FormSection>
            <h4 className="c-h4">{'Notification Preferences'}</h4>
            <FormSection name="notificationPreferences">
              <NotificationSettings
                  settings={this.props.settings}
              />
            </FormSection>
          </div>
          <div className="o-layout__item u-1/1 u-5/10@desktop u-push-1/10@desktop">
            <div className="c-settings-save">
              <FormButton
                  className="c-form-button--primary c-form-button--block"
                  label="Save"
                  type="submit"
              />
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: 'settings',
  enableReinitialize: true,
  validate: validateFields({
    passwordChange: validatePasswordChange,
  }, {})
})(SettingsComponent);
