import * as localstore from 'utils/localStorage';
import {
  call,
  put,
  fork,
  take
} from 'redux-saga/effects';
import {SubmissionError} from 'redux-form';

import {
  REMOVE_AUTH_TOKEN,
  SET_AUTH_TOKEN,
  PASSWORD_CHANGE_SUCCESS,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAILURE,
  PASSWORD_CHANGE_FAILURE
} from 'constants/session';
import PasswordChangeSuccess from 'containers/PasswordChangeSuccess';
import PasswordResetSuccess from 'containers/PasswordResetSuccess';
import {identify} from 'utils/track';
import {PROFILE_SUCCESS} from 'constants/profile';
import {openModal} from 'actions/modal';

export function * passwordChangeSuccess () {
  while (true) { // eslint-disable-line no-constant-condition
    yield take(PASSWORD_CHANGE_SUCCESS);
    yield fork(passwordChangeSuccessAction);
  }
}

export function * passwordChangeSuccessAction () {
  yield put(openModal(PasswordChangeSuccess, 'Success', {}, {}));
}

export function * passwordChangeFailure () {
  while (true) { // eslint-disable-line no-constant-condition
    const {
      reject,
      error
    } = yield take(PASSWORD_CHANGE_FAILURE);
    yield fork(passwordChangeFailureAction, error, reject);
  }
}

export function * passwordChangeFailureAction (error, reject) {
  console.log('change fail', error);
  yield call(reject, new SubmissionError({passwordChange: {passwordConfirm: 'Invalid reset token'}}));
}

export function * passwordResetSuccess () {
  while (true) { // eslint-disable-line no-constant-condition
    yield take(PASSWORD_RESET_SUCCESS);
    yield fork(passwordResetSuccessAction);
  }
}

export function * passwordResetSuccessAction () {
  yield put(openModal(PasswordResetSuccess, 'Success', {}, {}));
}

export function * passwordResetFailure () {
  while (true) { // eslint-disable-line no-constant-condition
    const {reject} = yield take(PASSWORD_RESET_FAILURE);
    yield fork(passwordResetFailureAction, reject);
  }
}

export function * passwordResetFailureAction (reject) {
  yield call(reject, new SubmissionError({passwordReset: {email: 'Couldn\'t find an account with that email'}}));
}

export function *removeAuthToken () {
  while (true) { // eslint-disable-line no-constant-condition
    yield take(REMOVE_AUTH_TOKEN);
    yield fork(removeAuthTokenEffect);
  }
}

export function *removeAuthTokenEffect () {
  yield call(localstore.unset, 'TRUSTED_AUTH_TOKEN_' + process.env.APP_ROLE);
}

export function *setAuthToken () {
  while (true) { // eslint-disable-line no-constant-condition
    const {authToken} = yield take(SET_AUTH_TOKEN);
    yield fork(setAuthTokenEffect, authToken);
  }
}

export function *setAuthTokenEffect (authToken) {
  yield call(localstore.set, 'TRUSTED_AUTH_TOKEN_' + process.env.APP_ROLE, authToken);
}

export function *profileSuccess () {
  while (true) { // eslint-disable-line no-constant-condition
    const {response} = yield take(PROFILE_SUCCESS);
    const profile = response.entities.user[response.result];
    yield fork(profileSuccessEffect, profile);
  }
}

export function *profileSuccessEffect (profile) {
  yield call(identify, profile.email);
}
