import React from 'react'
import PropTypes from 'prop-types'

import FormButton from 'forms/controls/Button'

export default class ConfirmButton extends React.Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    title: PropTypes.string
  }

  render () {
    return (
      <FormButton
          className="c-form-button--primary c-form-button--block"
          label={this.props.title ? this.props.title : 'Yes'}
          onClick={this.props.onClick}
          type="submit"
      />
    )
  }
}
