import {
  LOAD_ONE_TIME_SEARCHES_SUCCESS,
  LOAD_RECURRING_SEARCHES_SUCCESS,
  LOAD_ALL_SEARCHES_SUCCESS,
  LOAD_INTERESTED_SEARCHES_SUCCESS
} from 'constants/sitter/search';

export function getDefaultState () {
  return {
    all: [],
    allCount: 0,
    oneTime: [],
    oneTimeCount: 0,
    recurring: [],
    recurringCount: 0,
    interested: [],
    interestedCount: 0
  };
}

export const extractResults = response => response.result.searches.map(i => response.entities.search[i]);

export default function (state = getDefaultState(), action) {
  if (action.type === LOAD_ALL_SEARCHES_SUCCESS) {
    return {
      ...state,
      allCount: action.response.result.total_count,
      all: extractResults(action.response)
    };
  }
  if (action.type === LOAD_ONE_TIME_SEARCHES_SUCCESS) {
    return {
      ...state,
      oneTimeCount: action.response.result.total_count,
      oneTime: extractResults(action.response)
    };
  }
  if (action.type === LOAD_RECURRING_SEARCHES_SUCCESS) {
    return {
      ...state,
      recurringCount: action.response.result.total_count,
      recurring: extractResults(action.response)
    };
  }
  if (action.type === LOAD_INTERESTED_SEARCHES_SUCCESS) {
    return {
      ...state,
      interestedCount: action.response.result.total_count,
      interested: extractResults(action.response)
    };
  }
  return state;
}
