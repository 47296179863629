import {
  SET_PAGE_TITLE,
  TOGGLE_NAV
} from 'constants/navigation';

function getDefaultState () {
  return {
    isOpen: false,
    title: null
  };
}

export default function (state = getDefaultState(), action) {
  if (action.type === SET_PAGE_TITLE) {
    const {title} = action;
    return {
      ...state,
      title
    };
  }
  if (action.type === TOGGLE_NAV) {
    const {isOpen} = action;
    return {
      ...state,
      isOpen
    };
  }
  return state;
}
