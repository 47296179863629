import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class Toggle extends React.Component {
  static propTypes = {
    content: PropTypes.object.isRequired
  }

  render () {
    const showError = this.props.meta.touched && (!!this.props.meta.error && (this.props.meta.error));
    const className = classNames({
      'c-form-field-checkbox': true,
      'c-form-field-checkbox__error': showError,
      'u-margin-bottom': true
    });
    return (
      <div {...{className}}>
        <div className="c-form-field-checkbox__control">
          <input
              checked={!!this.props.input.value}
              id={`checkbox-${this.props.input.name}`}
              name={this.props.input.name}
              onChange={() => this.props.input.onChange(!this.props.input.value)}
              type="checkbox"
              value={!!this.props.input.value}
          />
        </div>
        <div className="c-form-field-checkbox__label c-text-small c-text-small--muted">
          <label htmlFor={`checkbox-${this.props.input.name}`}>
            {this.props.content}
          </label>
        </div>
        <div className="c-form-field__hint">
          {showError && (
            this.props.meta.error
          )}
        </div>
      </div>
    );
  }
}
