import React from 'react';
import {Field} from 'redux-form';

import Textarea from 'forms/controls/Textarea';

export default class NoteTextarea extends React.Component {
  render () {
    return (
      <div>
        <Field
            className="c-conversation__textarea u-1/1"
            component={Textarea}
            limit={255}
            name="note"
        />
      </div>
    );
  }
}
