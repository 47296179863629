import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {closeModal as closeModalAction} from 'actions/modal';
import AvailabilityDetailForm from 'forms/sitter/AvailabilityDetail';
import {
  deleteAvailability as deleteAvailabilityAction,
  deleteUnavailability as deleteUnavailabilityAction
} from 'actions/sitter/availability';
import {deleteAvailability as deleteAvailabilitySubmission} from 'submissions/sitter/availability';
import {getAuthToken} from 'selectors/session';

export class EventView extends React.Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    authToken: PropTypes.string.isRequired,
    deleteAvailability: PropTypes.func.isRequired,
    scheduleItem: PropTypes.object.isRequired,
    isAvailability: PropTypes.bool.isRequired,
    constants: PropTypes.object.isRequired,
  }

  handleSubmit = values => {
    this.props.closeModal();
    if (this.props.isAvailability) {
      return deleteAvailabilitySubmission(this.props.authToken, this.props.scheduleItem.id, this.props.deleteAvailability)(values);
    } else {
      return deleteAvailabilitySubmission(this.props.authToken, this.props.scheduleItem.id, this.props.deleteUnavailability)(values);
    }
  }

  render () {
    return (
      <AvailabilityDetailForm
          initialValues={{
            schedule: {
              end: this.props.scheduleItem.end,
              coverageZones: this.props.scheduleItem.zones,
              start: this.props.scheduleItem.start
            }
          }}
          isAvailability={this.props.isAvailability}
          onSubmit={this.handleSubmit}
      />
    );
  }
}

export const makeMapStateToProps = () => {
  return (state, ownProps) => {
    return {
      authToken: getAuthToken(state, ownProps),
    };
  };
};

export default connect(makeMapStateToProps, {
  deleteAvailability: deleteAvailabilityAction,
  deleteUnavailability: deleteUnavailabilityAction,
  closeModal: closeModalAction
})(EventView);
