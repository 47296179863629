import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {setTitle as setTitleAction} from 'actions/navigation';
import {getFirstName} from 'selectors/session';
import {track} from 'utils/track';

export class Approved extends React.Component {
  static propTypes = {
    setTitle: PropTypes.func.isRequired,
    firstName: PropTypes.string.isRequired
  };

  componentDidMount () {
    this.props.setTitle('Congratulations');
    track('Accepted Page - Enter');
  }

  render () {
    return (
      <div className="o-layout">
        <div className="o-layout__item u-1/1 u-3/5@desktop u-push-1/5@desktop">
          <h1 className="c-h1 c-page-title">{'Thank you'}</h1>
          <p>{`Congratulations, ${this.props.firstName}!`}</p>
          <p>{'We are thrilled that you are joining the Trusted team and helping us deliver excellent child care. A member of our operations team will be in touch within 1 business day to get your profile set up and for you to get connected with amazing families.'}</p>
          <p>{'In the meantime if you have any questions, please do not hesitate to email us - '} <a href="mailto:ccpsupport@usetrusted.com">{'ccpsupport@usetrusted.com'}</a></p>
          <p className="u-margin-top-huge">{'PS: Do you have friends who might be interested in joining Trusted? Refer them now and you can make $100 for each friend that joins:'} <a href="http://usetrusted.com/become-a-sitter">{'http://usetrusted.com/become-a-sitter'}</a></p>
        </div>
      </div>
    );
  }
}

export const makeMapStateToProps = () => {
  return (state, ownProps) => {
    return {
      firstName: getFirstName(state, ownProps)
    };
  };
};

const mapDispatchToProps = {
  setTitle: setTitleAction
};

export default connect(makeMapStateToProps, mapDispatchToProps)(Approved);
