import {
  MODAL_CLOSE,
  MODAL_OPEN
} from 'constants/modal';

export function openModal (component, title, options, classes = {}) {
  return {
    component,
    options,
    classes,
    title,
    type: MODAL_OPEN
  };
}

export function closeModal () {
  return {
    type: MODAL_CLOSE
  };
}
