import {createSelector} from 'reselect'
import moment from 'moment'
import jQuery from 'jquery'

import Availability from 'models/sitter/Availability';
import Unavailability from 'models/sitter/Unavailability';
import CalendarEvent from 'models/sitter/CalendarEvent';
import {getForm} from 'selectors/form';
import {
  getSession,
} from 'selectors/session'

import {
  getSettings,
  getCopy
} from 'selectors/settings'
import AvailabilityModel from 'models/sitter/Availability';
import UnavailabilityModel from 'models/sitter/Unavailability';

export const getAfterAddCopy = createSelector(
  [getCopy],
  copy => copy.availability.guide.after_add.prompt
)

export const getConfirmNextCopy = createSelector(
  [getCopy],
  copy => copy.availability.guide.confirm_next.prompt
)

export const getAuthToken = createSelector(
  [getSession],
  session => session.authToken
);

export const getAvailability = state => state.availability;

const makeGetAvailabilities = () => createSelector(
  [getAvailability],
  availability => availability.availabilities
)

export const getAvailabilities = makeGetAvailabilities();

const makeGetUnvailabilities = () => createSelector(
  [getAvailability],
  availability => availability.unavailabilities
);

export const getUnavailabilities = makeGetUnvailabilities();

export const getAvailabilityConstants = createSelector(
  [getSettings],
  settings => settings.constants.availability
)

export const getDefaultAvailabilityConstants = createSelector(
  [],
  () => ({
    day_start_time: '0000', // eslint-disable-line
    day_length: 36, // eslint-disable-line
    calendar_day_length: 24, // eslint-disable-line
    default_start_time: '0700', // eslint-disable-line
    default_length: 18.5 // eslint-disable-line
  })
)

export const getTransformedAvailabilityConstants = createSelector(
  [getDefaultAvailabilityConstants, getAvailabilityConstants],
  (defaults, constants) => {
    const availability = Object.assign({}, defaults, constants)
    let values = {
      calendarDaysAhead: availability.calendar_days_ahead,
      minDuration: availability.min_length * 3600,
      maxDuration: availability.max_length * 3600,
      padding: availability.padding,
      dayStart: (Math.floor(parseInt(availability.day_start_time, 10) / 100) + ((parseInt(availability.day_start_time, 10) % 100) / 60)) * 3600,
      defaultStart: (Math.floor(parseInt(availability.default_start_time, 10) / 100) + ((parseInt(availability.default_start_time, 10) % 100) / 60)) * 3600
    }
    values = {
      ...values,
      dayEnd: values.dayStart + (availability.calendar_day_length * 3600),
      defaultEnd: values.defaultStart + (availability.default_length * 3600),
      calendarDayEnd: values.dayStart + (availability.calendar_day_length * 3600)
    }
    values = {
      ...values,
      dayLatestStart: values.dayEnd - values.minDuration
    }
    return values
  }
)

const calendarEvents = (availabilities, unavailabilities) => {
  let availableEvents = availabilities.map(a => CalendarEvent.fromAvailability(new Availability(a)));
  let unavailableEvents = unavailabilities.map(a => CalendarEvent.fromUnavailability(new Unavailability(a)));

  return [...availableEvents, ...unavailableEvents];
};

export const getAvailabilityCalendarOptions = createSelector(
  [getTransformedAvailabilityConstants, getAvailabilities, getUnavailabilities],
  (constants, availabilities, unavailabilities) => {
    return ({
      count: availabilities.length + unavailabilities.length,
      height: 'auto',
      defaultView: 'agendaWeek',
      header: false,
      allDaySlot: false,
      slotDuration: moment.duration({seconds: 3600}),
      slotLabelInterval: moment.duration({seconds: 3600}),
      events: calendarEvents(availabilities, unavailabilities),
      minTime: moment.duration({seconds: constants.dayStart}),
      maxTime: moment.duration({seconds: constants.dayEnd}),
      timezone: 'local',
      timeFormat: ' ',
      columnFormat: 'dd\nM/D',
      dayClick: () => {},
      eventClick: () => {},
      eventRender: (event, element) => {
        const container = jQuery('<div>').addClass('c-start-end-date')
          .append(jQuery('<div>').addClass('c-start-end-date__start-date').html(event.startTitle))
          .append(jQuery('<div>').addClass('c-start-end-date__end-date').html(event.endTitle))
        element.append(container)
      }
    })
  }
)

export const getAvailabilityAdd = createSelector(
  [getForm],
  form => form.availabilityAdd
)

export const getAvailabilityAddExists = createSelector(
  [getAvailabilityAdd],
  availabilityAdd => !!availabilityAdd
)

export const getAvailabilityAddEnd = createSelector(
  [getAvailabilityAdd],
  availabilityAdd => availabilityAdd.values.schedule.end
)

export const getAllEvents = createSelector(
  [getAvailabilities, getUnavailabilities],
  (availabilities, unavailabilities) => [...availabilities.map(a => new AvailabilityModel(a)), ...unavailabilities.map(a => new UnavailabilityModel(a))]
)
