import moment from 'moment';
import {createSelector} from 'reselect';
import BookingModel from 'models/Booking';
import {getForm} from 'selectors/form';
import {getParams} from 'selectors/session';
import {getConstants} from 'selectors/settings';
import {getModalOptions} from 'selectors/ui';

export const getBookingsPerPage = createSelector(
  [],
  () => 10
);
export const getBookings = state => state.bookings;

export const getPastBookingsPageCount = createSelector(
  [getBookingsPerPage, getBookings],
  (perPage, bookings) => Math.ceil(bookings.pastBookingsCount / perPage)
);

export const getFutureBookingsPageCount = createSelector(
  [getBookingsPerPage, getBookings],
  (perPage, bookings) => Math.ceil(bookings.futureBookingsCount / perPage)
);

export const getPastBookings = createSelector(
  [getBookings],
  bookings => bookings.pastBookings.map(a => new BookingModel(a))
);

export const getFutureBookings = createSelector(
  [getBookings],
  bookings => bookings.futureBookings.map(a => new BookingModel(a))
);

export const getInProgressBooking = createSelector(
  [getBookings],
  bookings => bookings.inProgress
);

export const getParamsBookingId = createSelector(
  [getParams, getModalOptions],
  (params, modalOptions) => modalOptions.bookingId ? modalOptions.bookingId.toString() : params.bookingId
);

export const getBookingDetails = createSelector(
  [getBookings],
  bookings => bookings.bookingDetails || {}
);

export const getBookingModel = createSelector(
  [getBookingDetails],
  bookings => new BookingModel(bookings)
);

export const getBookingDetailsLoaded = createSelector(
  [getBookingDetails],
  booking => booking !== null
);

export const getSitterParentNotes = createSelector(
  [getBookings],
  bookings => bookings.sitterParentNotes
);

export const getPriceEstimate = createSelector(
  [getBookings],
  bookings => bookings.priceEstimate
);

export const getEstimate = createSelector(
  [getBookings],
  bookings => bookings.estimate
);

export const getMaxChildren = createSelector(
  [],
  () => 3
);

export const getBookingParent = createSelector(
  [getBookingDetails],
  booking => booking.parent || {}
);

export const getBookingParentId = createSelector(
  [getBookingParent],
  parent => parent.id
);

export const getBookingPolicy = createSelector(
  [getBookingDetails],
  booking => booking.policy || {}
);

export const getEndTime = createSelector(
  [getForm],
  form => form.endTime
);

export const getEndTimeTime = createSelector(
  [getEndTime],
  endTime => endTime ? endTime.values.endTime.time : null
);

export const getIsCareAtWork = createSelector(
  [getBookingPolicy],
  policy => {
    if (policy && policy.identifier === 'care_at_work') {
      return true;
    }

    return false;
  }
);

export const getShouldDisplayContactInfo = createSelector(
  [getBookingModel, getConstants],
  (booking, constants) => {
    if (!booking || (!booking.parent && !booking.sitter)) {
      return false;
    }
    if (booking.isInProgress || booking.isConfirmed) {
      return true;
    }

    const contactInfoLeadTime = constants.booking.contact_info_lead_time * 60;
    const bookingDuration = booking.end.diff(booking.start, 'seconds');
    const timeRemaining = booking.start.diff(moment(), 'seconds');
    let withinThreshold = timeRemaining > -bookingDuration && timeRemaining < contactInfoLeadTime;

    if (withinThreshold && !booking.isCancelled) {
      return true;
    }

    if (booking.isEnded && moment() > booking.end) {
      withinThreshold = moment().diff(booking.end, 'seconds') < contactInfoLeadTime;
      if (withinThreshold) {
        return true;
      }
    }

    return false;
  }
);
