import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {track} from 'utils/track';
import {setTitle} from 'actions/navigation';
import StreamChannelPreviewLastMessage from 'components/StreamChannelPreviewLastMessage';

import * as streamUtils from 'utils/stream';

import { Chat, Channel, ChannelList, Window } from 'stream-chat-react';
import { ChannelHeader, MessageList } from 'stream-chat-react';
import { MessageInput, Thread } from 'stream-chat-react';

const sort = { last_message_at: -1 }; // eslint-disable-line camelcase
export class Messages extends React.Component {
  static propTypes = {
    session: PropTypes.object.isRequired,
  };

  state = {
    streamClient: null,
    streamFilter: null
  };

  componentWillMount () {
    if (this.props.session.user) {
      this.setupClient(this.props.session.authToken, this.props.session.user);
    }
  }

  componentWillReceiveProps (newProps) {
    if (newProps.session.user && !this.props.session.user) {
      this.setupClient(newProps.session.authToken, newProps.session.user);
    }
  }

  componentDidMount () {
    this.props.dispatch(setTitle('Messages'));
    track('Messages Page');
  }

  async setupClient (authToken, user) {
    const client = await streamUtils.makeClient(authToken, user, this.props.dispatch);
    this.setState({ // eslint-disable-line react/no-set-state
      streamClient: client,
      streamFilter: { type: 'messaging', members: { $in: [streamUtils.streamIdfor(user)] } }
    });
  }

  buildConversations () {
    return (
      <Chat client={this.state.streamClient}
          theme={'messaging light'}
      >
        <ChannelList
            Preview={StreamChannelPreviewLastMessage}
            filters={this.state.streamFilter}
            sort={sort}
        />
        <Channel>
          <Window>
            <ChannelHeader title="Messages" />
            <MessageList />
            <MessageInput />
          </Window>
          <Thread />
        </Channel>
      </Chat>
    );
  }

  render () {
    return (
      <div className="o-layout">
        <div className="o-layout__item">
          {this.state.streamClient ? this.buildConversations() : null}
        </div>
      </div>
    );
  }
}

export default connect(state => {
  const {
    session
  } = state;
  return {
    session
  };
})(Messages);
