import {CALL_API} from 'middleware/api';
import {settings as settingsSchema} from 'schemas/settings';
import {notificationPreferences as notificationPreferencesSchema} from 'schemas/settings';
import {
  CONSTANTS_REQUEST,
  CONSTANTS_SUCCESS,
  CONSTANTS_FAILURE,
  SECRET_KEY,
  LOAD_NOTIFICATION_SETTINGS_REQUEST,
  LOAD_NOTIFICATION_SETTINGS_SUCCESS,
  LOAD_NOTIFICATION_SETTINGS_FAILURE,
  SAVE_NOTIFICATION_SETTINGS_REQUEST,
  SAVE_NOTIFICATION_SETTINGS_SUCCESS,
  SAVE_NOTIFICATION_SETTINGS_FAILURE
} from 'constants/settings';

export function loadConstants (authToken) {
  const headers = {
    'X-Identity-Key': SECRET_KEY
  };
  if (authToken) {
    headers['X-Identity-Token'] = authToken;
  }

  const role = process.env.APP_ROLE === 'parent' ? 'parent' : 'provider';
  return {
    [CALL_API]: {
      endpoint: `settings?key=all&app=${role}`,
      headers,
      method: 'GET',
      schema: settingsSchema,
      types: [
        CONSTANTS_REQUEST,
        CONSTANTS_SUCCESS,
        CONSTANTS_FAILURE
      ]
    }
  };
}

export function loadNotificationSettings (authToken) {
  const role = process.env.APP_ROLE === 'parent' ? 'parent' : 'sitter';
  return {
    [CALL_API]: {
      endpoint: `${role}/notification_preferences`,
      headers: {
        'X-Identity-Key': SECRET_KEY,
        'X-Identity-Token': authToken
      },
      method: 'GET',
      schema: notificationPreferencesSchema,
      types: [
        LOAD_NOTIFICATION_SETTINGS_REQUEST,
        LOAD_NOTIFICATION_SETTINGS_SUCCESS,
        LOAD_NOTIFICATION_SETTINGS_FAILURE
      ]
    }
  };
}

export function saveNotificationSettings (authToken, settings) {
  const role = process.env.APP_ROLE === 'parent' ? 'parent' : 'sitter';
  return {
    [CALL_API]: {
      endpoint: `${role}/notification_preferences`,
      headers: {
        'X-Identity-Key': SECRET_KEY,
        'X-Identity-Token': authToken
      },
      data: settings,
      method: 'POST',
      schema: notificationPreferencesSchema,
      types: [
        SAVE_NOTIFICATION_SETTINGS_REQUEST,
        SAVE_NOTIFICATION_SETTINGS_SUCCESS,
        SAVE_NOTIFICATION_SETTINGS_FAILURE
      ]
    }
  };
}
