import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import NavLink from 'components/NavLink';
import {
  ALL_SEARCHES_PAGE,
  AVAILABLE_SEARCHES_PAGE,
  ONE_TIME_SEARCHES_PAGE,
  RECURRING_SEARCHES_PAGE,
  INTERESTED_SEARCHES_PAGE
} from 'constants/sitter/navigation';
import {
  getAuthToken,
  getCurrentPage,
  getGeolocation,
  getUser
} from 'selectors/session';
import {
  getFeatures,
  getCopy
} from 'selectors/settings';
import {track} from 'utils/track';
import {
  openModal as openModalAction
} from 'actions/modal';
import {
  setTitle as setTitleAction,
} from 'actions/navigation';
import {
  getInterestedCopy,
  getSearches,
  getResultsPerPage,
  getEmptySearchOptions,
  getHrefBuilder,
  getPageCount,
  getShowInTouchModal,
  getShowIntroModal
} from 'selectors/sitter/search';
import SearchList from 'components/sitter/SearchList';
import {
  searchInterest as searchInterestSubmission
} from 'submissions/sitter/search';
import {
  oneTimeSearchResponse,
  recurringSearchResponse,
  interestedSearchResponse,
  showIntroModal as showIntroModalAction,
  openInTouchModal as openInTouchModalAction,
  searchToBooking as searchToBookingAction,
  allSearchResponse,
  loadAllSearches as loadAllSearchesAction,
  loadOneTimeSearches as loadOneTimeSearchesAction,
  loadRecurringSearches as loadRecurringSearchesAction,
  loadInterestedSearches as loadInterestedSearchesAction
} from 'actions/sitter/search';
import EmptySearch from 'components/EmptySearch';
import {Tooltip} from 'pivotal-ui/react/tooltip';
import {OverlayTrigger} from 'pivotal-ui/react/overlay-trigger';

export class Searches extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    pageCount: PropTypes.number.isRequired,
    hrefBuilder: PropTypes.func.isRequired,
    currentPage: PropTypes.number.isRequired,
    searchResponse: PropTypes.func.isRequired,
    authToken: PropTypes.string.isRequired,
    setTitle: PropTypes.func.isRequired,
    searches: PropTypes.array.isRequired,
    geolocation: PropTypes.object.isRequired,
    showIntroModal: PropTypes.func.isRequired,
    openInTouchModal: PropTypes.func.isRequired,
    shouldShowIntroModal: PropTypes.bool.isRequired,
    shouldShowInTouchModal: PropTypes.bool.isRequired,
    interestedCopy: PropTypes.object.isRequired,
    interested: PropTypes.bool.isRequired,
    scope: PropTypes.string.isRequired,
    copy: PropTypes.object.isRequired,
    features: PropTypes.object.isRequired,
    loadSearch: PropTypes.func.isRequired
  }

  componentDidMount () {
    this.props.setTitle('Open Requests');
    track('Open Requests Page - Enter');

    this.props.loadSearch(this.props.authToken, this.props.resultsPerPage, this.props.currentPage)
    if (this.props.shouldShowIntroModal) {
      this.props.showIntroModal();
    }
  }

  handleSearchSubmit = values => {
    const isBooking = this.props.features.search_to_book && values.search.instantBook;
    const callback = isBooking && values.search.interested ? this.props.searchToBooking : this.props.searchResponse;
    searchInterestSubmission(
      this.props.authToken,
      this.props.openModal,
      callback,
      this.props.openInTouchModal,
      this.props.shouldShowInTouchModal,
      isBooking,
      this.props.copy.searches.respond,
      this.props.history)(values);
  }

  handlePageChange = pageIndex => {
    this.props.history.push(this.props.hrefBuilder(pageIndex))

    this.props.loadSearch(this.props.authToken, this.props.resultsPerPage, pageIndex)
  }

  render () {
    const showSubNav = !this.props.location.pathname.match(INTERESTED_SEARCHES_PAGE);
    return (
      <div className="o-layout u-1/1 u-1/2@desktop u-push-1/4@desktop">
        <div className="o-layout__item u-1/1">
          <h1 className="c-h1 c-page-title">{'Open Requests'}</h1>
          <ul className="o-list-inline o-list-inline--simple u-margin-bottom-tiny">
            <li className="o-list-inline__item u-display-block u-center-text">
              <div className="c-button-group">
                <NavLink
                    fuzzy={AVAILABLE_SEARCHES_PAGE}
                    to={`/${ALL_SEARCHES_PAGE}`}
                >{'Available'}</NavLink>
                <NavLink
                    fuzzy={INTERESTED_SEARCHES_PAGE}
                    to={`/${INTERESTED_SEARCHES_PAGE}`}
                >
                  <OverlayTrigger
                      overlay={<Tooltip size="md">{this.props.interestedCopy.pointer}</Tooltip>}
                      placement="bottom"
                  >
                    <span>{'Interested'}</span>
                  </OverlayTrigger>
                </NavLink>
              </div>
            </li>
          </ul>
          {showSubNav && (
            <ul className="o-list-inline o-list-inline--simple u-margin-bottom o-list-inline--bare">
              <li className="o-list-inline__item u-display-block u-center-text">
                <div className="c-button-group">
                  <NavLink
                      fuzzy={ALL_SEARCHES_PAGE}
                      to={`/${ALL_SEARCHES_PAGE}`}
                  >{'All'}</NavLink>
                  <NavLink
                      fuzzy={ONE_TIME_SEARCHES_PAGE}
                      to={`/${ONE_TIME_SEARCHES_PAGE}`}
                  >{'One-Time'}</NavLink>
                  <NavLink
                      fuzzy={RECURRING_SEARCHES_PAGE}
                      to={`/${RECURRING_SEARCHES_PAGE}`}
                  >{'Recurring'}</NavLink>
                </div>
              </li>
            </ul>
          )}
          {!!this.props.searches.length && (
            <SearchList
                copy={this.props.copy}
                currentPage={this.props.currentPage}
                features={this.props.features}
                geolocation={this.props.geolocation}
                hrefBuilder={this.props.hrefBuilder}
                interested={this.props.interested}
                onPageChange={this.handlePageChange}
                onSubmit={this.handleSearchSubmit}
                pageCount={this.props.pageCount}
                scope={this.props.scope}
                searches={this.props.searches}
                user={this.props.user}
            />
          )}
          {!this.props.searches.length && (
            <EmptySearch {...this.props.emptySearchOptions[this.props.scope]} />
          )}
        </div>
      </div>
    );
  }
}

export const makeMapStateToProps = scope => {
  return (state, ownProps) => {
    return {
      user: getUser(state, ownProps),
      resultsPerPage: getResultsPerPage(state, ownProps),
      currentPage: getCurrentPage(state, ownProps),
      hrefBuilder: getHrefBuilder(state, ownProps)[scope],
      pageCount: getPageCount(state, ownProps)[scope],
      shouldShowIntroModal: getShowIntroModal(state, ownProps),
      searches: getSearches(state, ownProps)[scope],
      authToken: getAuthToken(state, ownProps),
      geolocation: getGeolocation(state, ownProps),
      interestedCopy: getInterestedCopy(state, ownProps),
      emptySearchOptions: getEmptySearchOptions(state, ownProps),
      shouldShowInTouchModal: getShowInTouchModal(state, ownProps),
      features: getFeatures(state, ownProps),
      copy: getCopy(state, ownProps),
      interested: scope === 'interested',
      scope,
    };
  };
};

export const makeMapDispatchToProps = (loadSearch, searchResponse) => ({
  setTitle: setTitleAction,
  openModal: openModalAction,
  showIntroModal: showIntroModalAction,
  openInTouchModal: openInTouchModalAction,
  searchToBooking: searchToBookingAction,
  searchResponse,
  loadSearch
});

export const SearchesWithRouter = withRouter(Searches);

export const OneTimeSearches = connect(makeMapStateToProps('oneTime'), makeMapDispatchToProps(loadOneTimeSearchesAction, oneTimeSearchResponse))(SearchesWithRouter);
export const RecurringSearches = connect(makeMapStateToProps('recurring'), makeMapDispatchToProps(loadRecurringSearchesAction, recurringSearchResponse))(SearchesWithRouter);
export const InterestedSearches = connect(makeMapStateToProps('interested'), makeMapDispatchToProps(loadInterestedSearchesAction, interestedSearchResponse))(SearchesWithRouter);
export const AllSearches = connect(makeMapStateToProps('all'), makeMapDispatchToProps(loadAllSearchesAction, allSearchResponse))(SearchesWithRouter);
