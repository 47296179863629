import {
  LOAD_PARENT_SITTER_NOTES_REQUEST,
  LOAD_PARENT_SITTER_NOTES_SUCCESS,
  LOAD_PARENT_SITTER_NOTES_FAILURE,
  OPEN_BOOKING_DETAILS_MODAL,
  PHONE_CALL,
  PAST_BOOKINGS_REQUEST,
  PAST_BOOKINGS_SUCCESS,
  PAST_BOOKINGS_FAILURE,
  FUTURE_BOOKINGS_REQUEST,
  FUTURE_BOOKINGS_SUCCESS,
  FUTURE_BOOKINGS_FAILURE,
  CONFIRM_BOOKING_REQUEST,
  CONFIRM_BOOKING_SUCCESS,
  CONFIRM_BOOKING_FAILURE,
  END_BOOKING_REQUEST,
  END_BOOKING_SUCCESS,
  END_BOOKING_FAILURE,
  LOAD_BOOKING_DETAILS_REQUEST,
  LOAD_BOOKING_DETAILS_SUCCESS,
  LOAD_BOOKING_DETAILS_FAILURE,
  ADD_BOOKING_NOTE_REQUEST,
  ADD_BOOKING_NOTE_SUCCESS,
  ADD_BOOKING_NOTE_FAILURE,
  PARENT_PHONE_REQUEST,
  PARENT_PHONE_SUCCESS,
  PARENT_PHONE_FAILURE
} from 'constants/sitter/bookings';
import {
  bookings as bookingsSchema,
  booking as bookingSchema,
  phone as phoneSchema,
} from 'schemas/booking';
import {parentSitterNotes as parentSitterNotesSchema} from 'schemas/note';
import {CALL_API} from 'middleware/api';

export function noteAdd (authToken, parentId, body) {
  return {
    [CALL_API]: {
      endpoint: `sitter/parents/${parentId}/sitter_notes`,
      headers: {
        'X-Identity-Token': authToken,
        'X-Identity-Key': process.env.SECRET_KEY
      },
      data: {
        body
      },
      method: 'POST',
      schema: parentSitterNotesSchema,
      types: [
        ADD_BOOKING_NOTE_REQUEST,
        ADD_BOOKING_NOTE_SUCCESS,
        ADD_BOOKING_NOTE_FAILURE
      ]
    }
  };
}

export function phoneCall (authToken, parentId) {
  return {
    type: PHONE_CALL,
    authToken,
    parentId
  };
}

export function getParentPhoneNumber (authToken, parentId) {
  return {
    [CALL_API]: {
      endpoint: `sitter/parents/${parentId}/bridge_number`,
      headers: {
        'X-Identity-Token': authToken,
        'X-Identity-Key': process.env.SECRET_KEY
      },
      method: 'GET',
      schema: phoneSchema,
      types: [
        PARENT_PHONE_REQUEST,
        PARENT_PHONE_SUCCESS,
        PARENT_PHONE_FAILURE
      ]
    }
  };
}

export function openBookingDetailsModal (bookingId) {
  return {
    type: OPEN_BOOKING_DETAILS_MODAL,
    bookingId
  };
}

export function loadPastBookings (authToken, perPage, pageIndex) {
  return {
    [CALL_API]: {
      endpoint: `sitter/bookings?scope=past&per_page=${perPage}&page=${pageIndex}`,
      headers: {
        'X-Identity-Token': authToken,
        'X-Identity-Key': process.env.SECRET_KEY
      },
      method: 'GET',
      schema: bookingsSchema,
      types: [
        PAST_BOOKINGS_REQUEST,
        PAST_BOOKINGS_SUCCESS,
        PAST_BOOKINGS_FAILURE
      ]
    }
  };
}

export function loadFutureBookings (authToken, perPage, pageIndex) {
  return {
    [CALL_API]: {
      endpoint: `sitter/bookings?scope=future&per_page=${perPage}&page=${pageIndex}`,
      headers: {
        'X-Identity-Token': authToken,
        'X-Identity-Key': process.env.SECRET_KEY
      },
      method: 'GET',
      schema: bookingsSchema,
      types: [
        FUTURE_BOOKINGS_REQUEST,
        FUTURE_BOOKINGS_SUCCESS,
        FUTURE_BOOKINGS_FAILURE
      ]
    }
  };
}

export function loadBookingDetails (authToken, bookingId) {
  return {
    [CALL_API]: {
      endpoint: `sitter/bookings/${bookingId}`,
      headers: {
        'X-Identity-Token': authToken,
        'X-Identity-Key': process.env.SECRET_KEY
      },
      method: 'GET',
      schema: bookingSchema,
      types: [
        LOAD_BOOKING_DETAILS_REQUEST,
        LOAD_BOOKING_DETAILS_SUCCESS,
        LOAD_BOOKING_DETAILS_FAILURE
      ]
    }
  };
}

export function confirmBooking (authToken, bookingId, resolve, reject) {
  return {
    [CALL_API]: {
      endpoint: `sitter/bookings/${bookingId}`,
      data: {
        status: 'confirmed'
      },
      headers: {
        'X-Identity-Token': authToken,
        'X-Identity-Key': process.env.SECRET_KEY
      },
      resolve,
      reject,
      method: 'PUT',
      schema: bookingSchema,
      types: [
        CONFIRM_BOOKING_REQUEST,
        CONFIRM_BOOKING_SUCCESS,
        CONFIRM_BOOKING_FAILURE
      ]
    }
  };
}

export function endBooking (authToken, bookingId, endTime, resolve, reject) {
  return {
    [CALL_API]: {
      endpoint: `sitter/bookings/${bookingId}`,
      data: {
        status: 'ended',
        end_time: endTime // eslint-disable-line camelcase
      },
      headers: {
        'X-Identity-Token': authToken,
        'X-Identity-Key': process.env.SECRET_KEY
      },
      resolve,
      reject,
      method: 'PUT',
      schema: bookingSchema,
      types: [
        END_BOOKING_REQUEST,
        END_BOOKING_SUCCESS,
        END_BOOKING_FAILURE
      ]
    }
  };
}

export function loadParentSitterNotes (authToken, parentId) {
  return {
    [CALL_API]: {
      endpoint: `sitter/parents/${parentId}/sitter_notes`,
      headers: {
        'X-Identity-Token': authToken,
        'X-Identity-Key': process.env.SECRET_KEY
      },
      method: 'GET',
      schema: parentSitterNotesSchema,
      types: [
        LOAD_PARENT_SITTER_NOTES_REQUEST,
        LOAD_PARENT_SITTER_NOTES_SUCCESS,
        LOAD_PARENT_SITTER_NOTES_FAILURE
      ]
    }
  };
}
