import React from 'react';

import FormButton from '../controls/Button';

export default class SaveButton extends React.Component {
  render () {
    return (
      <FormButton
          className="c-form-button--primary c-form-button--block"
          disabled={this.props.disabled}
          label="Save"
          type="submit"
      />
    );
  }
}
