import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {MESSAGE_PAGE} from 'constants/sitter/navigation';
import * as SVGIcon from 'components/SVGIcon';

export default class ProfileLineView extends React.Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
    hideMessages: PropTypes.bool,
    showPhoneNumber: PropTypes.bool.isRequired,
    onPhone: PropTypes.func,
    badgeUrl: PropTypes.string
  }

  render () {
    return (
      <div className="c-profile-line">
        <img
            className="c-profile-line__avatar"
            src={this.props.profile.getPhotoUrl(70)}
        />
        <div className="c-profile-line__name">
          {this.props.profile.firstName}
        </div>
        {this.props.showPhoneNumber && (
          <a
              className="c-profile-line__icon"
              onClick={() => this.props.onPhone()}
          >
            <SVGIcon.Phone active />
          </a>
        )}
        {!this.props.hideMessages && (
          <Link
              className="c-profile-line__icon"
              onClick={() => this.props.closeModal()}
              to={`/${MESSAGE_PAGE}/${this.props.profile.id}`}
          >
            <SVGIcon.ChatBubble active />
          </Link>
        )}
        {this.props.badgeUrl && (
          <img
            className="c-profile-line__badge"
            src={this.props.badgeUrl}
          />
        )}
      </div>
    );
  }
}
