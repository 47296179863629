import React from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import MapMarker from 'components/MapMarker';

export default class MapView extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    openMapPage: PropTypes.func.isRequired
  }

  render () {
    return (
      <div className='c-location__map-view'>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.GOOGLE_PLACES_API_KEY }}
        defaultCenter={this.props.location.geolocation}
        defaultZoom={15}
      >
        <MapMarker
          lat={this.props.location.geolocation.lat}
          lng={this.props.location.geolocation.lng}
          location={this.props.location}
        />
      </GoogleMapReact>
      </div>
    );
  }
}
