import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

export default class EmptySearch extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    linkTo: PropTypes.string.isRequired,
    linkCopy: PropTypes.string.isRequired
  };

  render () {
    return (
      <div>
        <h1 className="c-h1 u-center-text">{this.props.title}</h1>
        <p className="u-center-text">{this.props.body}</p>
        <p className="u-center-text"><Link to={this.props.linkTo}>{this.props.linkCopy}</Link></p>
      </div>
    );
  }
}
