import React from 'react';
import {
  FormSection,
  reduxForm
} from 'redux-form';

import FormButton from 'forms/controls/FormButton';
import PasswordConfirmFields from 'forms/fields/PasswordConfirm';
import {
  validateFields,
  validatePasswordRecover
} from 'utils/formValidations';

export class PasswordChangeComponent extends React.Component {
  static propTypes = {
  };

  render () {
    const {
      handleSubmit
    } = this.props;
    return (
      <div>
        <form onSubmit={handleSubmit}>
          <FormSection name="passwordChange">
            <PasswordConfirmFields />
          </FormSection>
          <div>
            <FormButton
                className="c-form-button--primary c-form-button--block"
                label="Submit"
                type="submit"
            />
          </div>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'passwordChange',
  validate: validateFields({
    passwordChange: validatePasswordRecover
  })
})(PasswordChangeComponent);
