import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {track} from 'utils/track';
import {setTitle as setTitleAction} from 'actions/navigation';
import PasswordChangeForm from 'forms/PasswordChange';
import {passwordChange as passwordChangeSubmission} from 'submissions/session';
import {changePassword as changePasswordAction} from 'actions/session';
import {getParamsResetToken} from 'selectors/session';

export class PasswordChange extends React.Component {
  static propTypes = {
    changePassword: PropTypes.func.isRequired,
    setTitle: PropTypes.func.isRequired,
    resetToken: PropTypes.string.isRequired
  };

  componentDidMount () {
    track('Password Change Page');
    this.props.setTitle('Change Password');
  }

  render () {
    return ( /* eslint-disable camelcase */
      <div className="o-layout">
        <div className="o-layout__item u-1/2 u-push-1/4">
          <h1 className="c-h1 u-center-text c-search-header">{'Change Password'}</h1>
          <p className="u-center-text">{'Enter your new password below.'}</p>
          <PasswordChangeForm
              initialValues={{}}
              onSubmit={passwordChangeSubmission(this.props.resetToken, this.props.changePassword)}
          />
        </div>
      </div>
    ); /* eslint-enable camelcase */
  }
}

export const makeMapStateToProps = () => {
  return (state, ownProps) => {
    return {
      resetToken: getParamsResetToken(state, ownProps)
    };
  };
};

const mapDispatchToProps = {
  setTitle: setTitleAction,
  changePassword: changePasswordAction
};

export default connect(makeMapStateToProps, mapDispatchToProps)(PasswordChange);
