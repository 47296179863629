import React from 'react';
import PropTypes from 'prop-types';

export default class AvatarNameView extends React.Component {
  static propTypes = {
    profile: PropTypes.object.isRequired
  }

  render () {
    return (
      <div className="c-avatar-name-view">
        <img
            className="c-avatar-name-view__avatar"
            src={this.props.profile.getPhotoUrl(160, 160)}
        />
        <h2>{this.props.profile.firstName}</h2>
      </div>
    );
  }
}
