import React from 'react';
import PropTypes from 'prop-types';

export default class BookingNoteView extends React.Component {
  static propTypes = {
    booking: PropTypes.object.isRequired
  }

  render () {
    return (
      <div className="c-booking-note">
        <div className="c-booking-note__title">{'Note'}</div>
        <div className="c-booking-note__body">{this.props.booking.note}</div>
      </div>
    );
  }
}
