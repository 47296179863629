import {CALL_API} from 'middleware/api';
import {SECRET_KEY} from 'constants/settings';
import {user as userSchema} from 'schemas/user';
import {
  FACEBOOK_AUTH_REQUEST,
  FACEBOOK_SIGNUP_REQUEST,
  FACEBOOK_SIGNUP_SUCCESS,
  FACEBOOK_SIGNUP_FAILURE,
} from 'constants/facebook';

export function auth (history) {
  return {type: FACEBOOK_AUTH_REQUEST, context: { history }};
}

export function facebookSignUp (accessToken, zip_code, history) { // eslint-disable-line camelcase
  const role = process.env.APP_ROLE === 'parent' ? 'parent' : 'sitter';

  return {
    [CALL_API]: {
      data: {
        facebook_access_token: accessToken, // eslint-disable-line camelcase
        zip_code // eslint-disable-line camelcase
      },
      endpoint: `${role}/registration`,
      headers: {
        role: 'parent',
        'X-Identity-Key': SECRET_KEY
      },
      method: 'POST',
      schema: userSchema,
      types: [
        FACEBOOK_SIGNUP_REQUEST,
        FACEBOOK_SIGNUP_SUCCESS,
        FACEBOOK_SIGNUP_FAILURE
      ],
      context: {
        history
      }
    }
  };
}

export const facebookLogin = facebookSignUp;
