import createSagaMiddleware from 'redux-saga';
import {compose, createStore, applyMiddleware} from 'redux';

import api from 'middleware/api';
import reducer from 'reducers/sitter';
import sagas from 'sagas/sitter';

const sagaMiddleware = createSagaMiddleware();

const enhancer = (process.env.NODE_ENV === 'production') ?
  compose(applyMiddleware(api, sagaMiddleware)) :
  compose(
    applyMiddleware(api, sagaMiddleware),
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );

export function configureStore (initialState) {
  const store = createStore(
    reducer,
    initialState,
    enhancer
  );
  sagaMiddleware.run(sagas);
  return store;
}
