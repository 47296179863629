import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export default class CoverageZones extends React.Component {
  static propTypes = {
    coverageZones: PropTypes.array,
    isEditable: PropTypes.bool.isRequired
  }

  renderEditView () {
    const showError = this.props.meta.touched && !!this.props.meta.error;
    const lookup = this.props.coverageZones.reduce((p, c) => ({...p, [c.id]: c}), {});
    const topLevel = this.props.coverageZones.filter(z => !lookup[z.parent_id]);
    const organized = topLevel.map(z => ({...z, children: this.props.coverageZones.filter(c => c.parent_id === z.id)})).sort((a, b) => a.coverage_zone.localeCompare(b.coverage_zone));
    const className = classNames({
      'o-layout': true,
      'u-margin-bottom': true,
      'c-form-field-options': true,
      'c-form-field-options--error': showError
    });
    return (
      <div {...{className}}>
        <div className="o-layout__item u-1/5 u-right-text">
          <label className="c-time-picker-label">{'Coverage Zones'}</label>
        </div>
        <div className="o-layout__item u-4/5">
          <ul className="o-list-bare c-form-field-options__list o-layout">
            {organized.map((z, i) => (
              <li
                  className="o-layout__item u-1/1 u-1/2@tablet u-1/3@desktop"
                  key={i}
              >
                <label>
                  <dl>
                    <dt>
                      <input
                          checked={this.props.input.value.indexOf(z.id) !== -1}
                          className="c-form-field-options__input"
                          id={`checkbox-${this.props.input.name}`}
                          name={`${this.props.input.name}[${z.id}]`}
                          onChange={event => {
                            const newValue = [...this.props.input.value];
                            if (event.target.checked) {
                              newValue.push(z.id);
                            } else {
                              newValue.splice(newValue.indexOf(z.id), 1);
                            }
                            this.props.input.onChange(newValue);
                          }}
                          type="checkbox"
                          value={z.id}
                      />
                      <span>{z.coverage_zone}</span>
                    </dt>
                    {z.children.map((c, j) => (
                      <dd key={j}>{c.coverage_zone}</dd>
                    ))}
                  </dl>
                </label>
              </li>
            ))}
          </ul>
          <div className="c-form-field-options__hint">
            {showError && (
              this.props.meta.error
            )}
          </div>
        </div>
      </div>
    );
  }

  renderReadView () {
    let coverageZone = '';
    if (this.props.input.value) {
      coverageZone = this.props.input.value.map(i => i.coverage_zone).join(', ');
    }
    return (
      <div className="o-layout u-margin-bottom c-form-field-options">
        <div className="o-layout__item u-1/5 u-right-text">
          <label className="c-time-picker-label">{'Coverage Zones'}</label>
        </div>
        <div className="o-layout__item u-4/5">
          <ul className="o-list-bare o-list-inline c-form-field-options__list">
            <li>
              <label>
                <dl>
                  <dt>
                    <span>{coverageZone}</span>
                  </dt>
                </dl>
              </label>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  render () {
    if (this.props.isEditable) {
      return this.renderEditView();
    } else {
      return this.renderReadView();
    }
  }
}
