import {CALL_API} from 'middleware/api';
import {braintree as braintreeSchema} from 'schemas/braintree';
import {paymentMethods as paymentMethodsSchema} from 'schemas/paymentMethod';
import {user as userSchema} from 'schemas/user';
import {location as locationSchema} from 'schemas/location';
import {SECRET_KEY} from 'constants/settings';
import {
  PAYMENT_CHANGE_REQUEST,
  PAYMENT_CHANGE_SUCCESS,
  PAYMENT_CHANGE_FAILURE,
  ADD_CHILD_PROFILE_REQUEST,
  ADD_CHILD_PROFILE_SUCCESS,
  ADD_CHILD_PROFILE_FAILURE,
  UPDATE_HOME_FAILURE,
  UPDATE_HOME_REQUEST,
  UPDATE_HOME_SUCCESS,
  BRAINTREE_TOKEN_FAILURE,
  BRAINTREE_TOKEN_REQUEST,
  BRAINTREE_TOKEN_SUCCESS,
  CREATE_CHILD_PROFILE_FAILURE,
  CREATE_CHILD_PROFILE_REQUEST,
  CREATE_CHILD_PROFILE_SUCCESS,
  PAYMENT_SUBMIT_FAILURE,
  PAYMENT_SUBMIT_REQUEST,
  PAYMENT_SUBMIT_SUCCESS,
  REGISTER_STEP,
  REGISTRATION_FAILURE,
  REGISTRATION_REQUEST,
  REGISTRATION_SUCCESS,
  SET_HOME_FAILURE,
  SET_HOME_REQUEST,
  SET_HOME_SUCCESS,
  SET_PROFILE_FORM,
  SUBMIT_PROFILE_FAILURE,
  SUBMIT_PROFILE_REQUEST,
  SUBMIT_PROFILE_SUCCESS,
  TOKENIZE_CARD_REQUEST,
  VALIDATE_STATUS,
  WAIT_LIST,
  WAIT_LIST_SIGNUP_FAILURE,
  WAIT_LIST_SIGNUP_REQUEST,
  WAIT_LIST_SIGNUP_SUCCESS,
  SET_ZIP,
  PAYMENT_REQUEST,
  PAYMENT_SUCCESS,
  PAYMENT_FAILURE,
  PAYMENT_DELETE_REQUEST,
  PAYMENT_DELETE_SUCCESS,
  PAYMENT_DELETE_FAILURE,
  PAYMENT_UPDATE_REQUEST,
  PAYMENT_UPDATE_SUCCESS,
  PAYMENT_UPDATE_FAILURE,
  ZIP_REQUEST,
  ZIP_SUCCESS,
  ZIP_FAILURE
} from 'constants/parent/signUpFlow';

export function tokenizeCard (shouldRedirect, callback) { // refactor, `shouldRedirect` is a hack
  return {
    type: TOKENIZE_CARD_REQUEST,
    shouldRedirect,
    callback,
  };
}

export function setZip (zip) {
  return {
    type: SET_ZIP,
    zip
  };
}

export function setProfileForm (name, email, phoneNumber) {
  return {
    type: SET_PROFILE_FORM,
    email,
    name,
    phoneNumber
  };
}

export function validateStatus (status, pathname, history) {
  return {
    type: VALIDATE_STATUS,
    status,
    pathname,
    context: {history}
  };
}

export function moveToRegisterStep (zipCode, history) {
  return {
    type: REGISTER_STEP,
    zipCode,
    context: {history}
  };
}

export function moveToWaitList (zipCode, history) {
  return {
    type: WAIT_LIST,
    zipCode,
    context: {history}
  };
}

export function checkZip (zip) {
  return {
    [CALL_API]: {
      endpoint: `zip_codes/${zip}`,
      headers: {
        'X-Identity-Key': SECRET_KEY
      },
      method: 'GET',
      schema: locationSchema,
      types: [
        ZIP_REQUEST,
        ZIP_SUCCESS,
        ZIP_FAILURE
      ]
    }
  };
}

export function registration (email, firstLastName, password, phoneNumber, zipCode) { // refactor, hardcoded values
  const role = process.env.APP_ROLE === 'parent' ? 'parent' : 'sitter';
  const [first_name, ...last_name] = firstLastName.split(' '); // eslint-disable-line camelcase
  return {
    [CALL_API]: {
      data: {
        email,
        password,
        first_name, // eslint-disable-line camelcase
        last_name: last_name.join(' '), // eslint-disable-line camelcase
        phone_number: phoneNumber, // eslint-disable-line camelcase
        role: role,
        zip_code: zipCode // eslint-disable-line camelcase
      },
      endpoint: `${role}/registration`,
      headers: {
        'X-Identity-Key': SECRET_KEY
      },
      method: 'POST',
      schema: userSchema,
      types: [
        REGISTRATION_REQUEST,
        REGISTRATION_SUCCESS,
        REGISTRATION_FAILURE
      ]
    }
  };
}

export function waitListRegistration (email, zip_code, history) { // eslint-disable-line camelcase
  const role = process.env.APP_ROLE === 'parent' ? 'parent' : 'sitter';
  return {
    [CALL_API]: {
      data: {
        email,
        password: Math.floor(Math.random() * 0xffffff).toString(16),
        first_name: '', // eslint-disable-line camelcase
        last_name: '', // eslint-disable-line camelcase
        role,
        zip_code // eslint-disable-line camelcase
      },
      endpoint: `${role}/registration`,
      headers: {
        'X-Identity-Key': SECRET_KEY
      },
      method: 'POST',
      schema: userSchema,
      types: [
        WAIT_LIST_SIGNUP_REQUEST,
        WAIT_LIST_SIGNUP_SUCCESS,
        WAIT_LIST_SIGNUP_FAILURE
      ],
      context: {history}
    }
  };
}

export function submitProfile (authToken, name, email, phoneNumber, password, photoUrl, history) {
  const role = process.env.APP_ROLE === 'parent' ? 'parent' : 'sitter';
  const [first_name, ...restName] = name.split(' '); // eslint-disable-line camelcase
  const last_name = restName.join(' '); // eslint-disable-line camelcase
  return {
    [CALL_API]: {
      data: { /* eslint-disable camelcase */
        email,
        first_name,
        last_name,
        password,
        phone_number: phoneNumber,
        photo_url: photoUrl
      }, /* eslint-enable camelcase */
      endpoint: `${role}/profile`,
      headers: {
        'X-Identity-Key': SECRET_KEY,
        'X-Identity-Token': authToken
      },
      method: 'POST',
      schema: userSchema,
      types: [
        SUBMIT_PROFILE_REQUEST,
        SUBMIT_PROFILE_SUCCESS,
        SUBMIT_PROFILE_FAILURE
      ],
      context: {
        history
      }
    }
  };
}

export function setHomeAddress (authToken, location, history) {
  const {
    city,
    lat,
    lng,
    state,
    streetAddress,
    zip
  } = location;
  return {
    [CALL_API]: {
      data: { /* eslint-disable camelcase */
        apartment: '',
        city,
        lat,
        lng,
        state,
        street: streetAddress,
        zip_code: zip
      }, /* eslint-enable camelcase */
      endpoint: 'parent/locations',
      headers: {
        'X-Identity-Key': SECRET_KEY,
        'X-Identity-Token': authToken
      },
      method: 'POST',
      schema: locationSchema,
      types: [
        SET_HOME_REQUEST,
        SET_HOME_SUCCESS,
        SET_HOME_FAILURE
      ],
      context: {
        history
      }
    }
  };
}

export function createChildrenProfiles (authToken, child_profiles, history) { // eslint-disable-line camelcase
  return {
    [CALL_API]: {
      data: {child_profiles}, // eslint-disable-line camelcase
      endpoint: 'parent/child_profiles',
      headers: {
        'X-Identity-Key': SECRET_KEY,
        'X-Identity-Token': authToken
      },
      method: 'POST',
      schema: userSchema,
      types: [
        CREATE_CHILD_PROFILE_REQUEST,
        CREATE_CHILD_PROFILE_SUCCESS,
        CREATE_CHILD_PROFILE_FAILURE
      ],
      context: {
        history
      }
    }
  };
}

export function requestBraintreeToken (authToken) {
  return {
    [CALL_API]: {
      endpoint: 'braintree_token',
      headers: {
        'X-Identity-Key': SECRET_KEY,
        'X-Identity-Token': authToken
      },
      method: 'GET',
      schema: braintreeSchema,
      types: [
        BRAINTREE_TOKEN_REQUEST,
        BRAINTREE_TOKEN_SUCCESS,
        BRAINTREE_TOKEN_FAILURE
      ]
    }
  };
}

export function submitPayment (authToken, nonce, shouldRedirect) {
  return {
    [CALL_API]: {
      data: {nonce},
      endpoint: 'parent/payment_methods',
      method: 'POST',
      headers: {
        'X-Identity-Key': SECRET_KEY,
        'X-Identity-Token': authToken
      },
      schema: braintreeSchema,
      types: [
        shouldRedirect ? PAYMENT_SUBMIT_REQUEST : PAYMENT_CHANGE_REQUEST,
        shouldRedirect ? PAYMENT_SUBMIT_SUCCESS : PAYMENT_CHANGE_SUCCESS,
        shouldRedirect ? PAYMENT_SUBMIT_FAILURE : PAYMENT_CHANGE_FAILURE
      ]
    }
  };
}

export function requestPayments (authToken) {
  return {
    [CALL_API]: {
      endpoint: 'parent/payment_methods',
      headers: {
        'X-Identity-Key': SECRET_KEY,
        'X-Identity-Token': authToken
      },
      method: 'GET',
      schema: paymentMethodsSchema,
      types: [
        PAYMENT_REQUEST,
        PAYMENT_SUCCESS,
        PAYMENT_FAILURE
      ]
    }
  };
}

export function updatePayments (authToken, id) {
  return {
    [CALL_API]: {
      endpoint: `parent/payment_methods/${id}`,
      headers: {
        'X-Identity-Key': SECRET_KEY,
        'X-Identity-Token': authToken
      },
      method: 'PUT',
      schema: paymentMethodsSchema,
      types: [
        PAYMENT_UPDATE_REQUEST,
        PAYMENT_UPDATE_SUCCESS,
        PAYMENT_UPDATE_FAILURE
      ]
    }
  };
}

export function deletePayments (authToken, id, callback) {
  return {
    [CALL_API]: {
      endpoint: `parent/payment_methods/${id}`,
      headers: {
        'X-Identity-Key': SECRET_KEY,
        'X-Identity-Token': authToken
      },
      method: 'DELETE',
      schema: paymentMethodsSchema,
      types: [
        PAYMENT_DELETE_REQUEST,
        PAYMENT_DELETE_SUCCESS,
        PAYMENT_DELETE_FAILURE
      ]
    },
    callback
  };
}

export function updateHomeAddress (authToken, location) {
  const {
    city,
    lat,
    lng,
    state,
    streetAddress,
    zip
  } = location;
  return {
    [CALL_API]: {
      data: { /* eslint-disable camelcase */
        apartment: '',
        city,
        lat,
        lng,
        state,
        street: streetAddress,
        zip_code: zip
      }, /* eslint-enable camelcase */
      endpoint: 'parent/locations',
      headers: {
        'X-Identity-Key': SECRET_KEY,
        'X-Identity-Token': authToken
      },
      method: 'POST',
      schema: locationSchema,
      types: [
        UPDATE_HOME_REQUEST,
        UPDATE_HOME_SUCCESS,
        UPDATE_HOME_FAILURE
      ]
    }
  };
}

export function addChildrenProfiles (authToken, child_profiles) { // eslint-disable-line camelcase
  return {
    [CALL_API]: {
      data: {child_profiles}, // eslint-disable-line camelcase
      endpoint: 'parent/child_profiles',
      headers: {
        'X-Identity-Key': SECRET_KEY,
        'X-Identity-Token': authToken
      },
      method: 'POST',
      schema: userSchema,
      types: [
        ADD_CHILD_PROFILE_REQUEST,
        ADD_CHILD_PROFILE_SUCCESS,
        ADD_CHILD_PROFILE_FAILURE
      ]
    }
  };
}
