export default function (Model, responseKey) {
  class Collection {
    constructor (json) {
      let values = [];
      if (Array.isArray(json)) {
        values = json;
      } else if (json && json[responseKey]) {
        values = json[responseKey].map(j => new Model(j));
      }
      this.isFetching = false;
      this.values = values;
    }
  }
  return Collection;
}
