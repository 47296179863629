import moment from 'moment';

export const ageAtDate = (birthday, date) => {
  const diff = moment(date).diff(birthday);
  const duration = moment.duration(diff);
  let years = duration.years();
  let months = years * 12 + duration.months();
  if (duration.days() > 21) {
    months += 1;
  }
  if (months === 24) {
    years = 2;
  }
  if (years >= 2) {
    return `${years} ${years === 1 ? 'year' : 'years'}`;
  }
  if (months >= 2) {
    return `${months} ${months === 1 ? 'month' : 'months'}`;
  }
  const weeks = Math.ceil(diff / 6.048e8);
  if (weeks >= 1) {
    return `${weeks} ${weeks === 1 ? 'week' : 'weeks'}`;
  }
  return 'age';
};
