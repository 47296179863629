export const CONSTANTS_REQUEST = 'CONSTANTS_REQUEST';
export const CONSTANTS_SUCCESS = 'CONSTANTS_SUCCESS';
export const CONSTANTS_FAILURE = 'CONSTANTS_FAILURE';
export const SECRET_KEY = process.env.WEB_SECRET_KEY;
export const DEFAULT_PARENT_PHOTO_URL = 'https://res.cloudinary.com/trusted/image/upload/default_parent_photo_url.png';
export const LOAD_NOTIFICATION_SETTINGS_REQUEST = 'LOAD_NOTIFICATION_SETTINGS_REQUEST';
export const LOAD_NOTIFICATION_SETTINGS_SUCCESS = 'LOAD_NOTIFICATION_SETTINGS_SUCCESS';
export const LOAD_NOTIFICATION_SETTINGS_FAILURE = 'LOAD_NOTIFICATION_SETTINGS_FAILURE';
export const SAVE_NOTIFICATION_SETTINGS_REQUEST = 'SAVE_NOTIFICATION_SETTINGS_REQUEST';
export const SAVE_NOTIFICATION_SETTINGS_SUCCESS = 'SAVE_NOTIFICATION_SETTINGS_SUCCESS';
export const SAVE_NOTIFICATION_SETTINGS_FAILURE = 'SAVE_NOTIFICATION_SETTINGS_FAILURE';
