import React from 'react';

import FormButton from '../controls/Button';
import PropTypes from 'prop-types';

export default class ContinueButton extends React.Component {
  static propTypes = {
    checkCopy: PropTypes.string.isRequired
  };

  render () {
    return (
      <FormButton
          className="c-form-button--primary c-form-button--block"
          label={this.props.checkCopy}
          type="submit"
      />
    );
  }
}
