import {CALL_API} from 'middleware/api';
import {coverageZones as coverageZonesSchema} from 'schemas/coverageZone';
import {SECRET_KEY} from 'constants/settings';
import {
  PROVIDER_ALL_COVERAGE_ZONES_REQUEST,
  PROVIDER_ALL_COVERAGE_ZONES_SUCCESS,
  PROVIDER_ALL_COVERAGE_ZONES_FAILURE,
  PROVIDER_COVERAGE_ZONES_REQUEST,
  PROVIDER_COVERAGE_ZONES_SUCCESS,
  PROVIDER_COVERAGE_ZONES_FAILURE,
  PROVIDER_DELETE_AVAILABILITY_REQUEST,
  PROVIDER_DELETE_AVAILABILITY_SUCCESS,
  PROVIDER_DELETE_AVAILABILITY_FAILURE,
  PROVIDER_ADD_AVAILABILITY_REQUEST,
  PROVIDER_ADD_AVAILABILITY_SUCCESS,
  PROVIDER_ADD_AVAILABILITY_FAILURE,
  PROVIDER_ADD_UNAVAILABILITY_REQUEST,
  PROVIDER_ADD_UNAVAILABILITY_SUCCESS,
  PROVIDER_ADD_UNAVAILABILITY_FAILURE,
  AVAILABILITIES_REQUEST,
  AVAILABILITIES_SUCCESS,
  AVAILABILITIES_FAILURE,
  UNAVAILABILITIES_REQUEST,
  UNAVAILABILITIES_SUCCESS,
  UNAVAILABILITIES_FAILURE,
  PROVIDER_LOAD_SCHEDULE
} from 'constants/sitter/availability';
import {
  availabilities as availabilitiesSchema,
  unavailabilities as unavailabilitiesSchema
} from 'schemas/availability';

export function loadSchedule () {
  return {
    type: PROVIDER_LOAD_SCHEDULE
  };
}

export function loadAvailabilities (authToken) {
  return {
    [CALL_API]: {
      endpoint: 'sitter/availabilities',
      headers: {
        'X-Identity-Key': SECRET_KEY,
        'X-Identity-Token': authToken
      },
      method: 'GET',
      schema: availabilitiesSchema,
      types: [
        AVAILABILITIES_REQUEST,
        AVAILABILITIES_SUCCESS,
        AVAILABILITIES_FAILURE
      ]
    }
  };
}

export function loadUnvailabilities (authToken) {
  return {
    [CALL_API]: {
      endpoint: 'sitter/unavailabilities',
      headers: {
        'X-Identity-Key': SECRET_KEY,
        'X-Identity-Token': authToken
      },
      method: 'GET',
      schema: unavailabilitiesSchema,
      types: [
        UNAVAILABILITIES_REQUEST,
        UNAVAILABILITIES_SUCCESS,
        UNAVAILABILITIES_FAILURE
      ]
    }
  };
}

export function addAvailability (authToken, start, end, coverageZones, resolve, reject) {
  return {
    [CALL_API]: {
      endpoint: 'sitter/availabilities',
      resolve,
      reject,
      data: {
        start_time: start, // eslint-disable-line
        end_time: end, // eslint-disable-line
        coverage_zones: coverageZones // eslint-disable-line
      },
      headers: {
        'X-Identity-Token': authToken,
        'X-Identity-Key': SECRET_KEY,
      },
      method: 'POST',
      schema: availabilitiesSchema,
      types: [
        PROVIDER_ADD_AVAILABILITY_REQUEST,
        PROVIDER_ADD_AVAILABILITY_SUCCESS,
        PROVIDER_ADD_AVAILABILITY_FAILURE
      ]
    }
  };
}

export function addUnavailability (authToken, start, end, resolve, reject) {
  return {
    [CALL_API]: {
      endpoint: 'sitter/unavailabilities',
      resolve,
      reject,
      data: {
        start_time: start, // eslint-disable-line
        end_time: end, // eslint-disable-line
      },
      headers: {
        'X-Identity-Token': authToken,
        'X-Identity-Key': SECRET_KEY,
      },
      method: 'POST',
      schema: unavailabilitiesSchema,
      types: [
        PROVIDER_ADD_UNAVAILABILITY_REQUEST,
        PROVIDER_ADD_UNAVAILABILITY_SUCCESS,
        PROVIDER_ADD_UNAVAILABILITY_FAILURE
      ]
    }
  };
}

export function loadAllCoverageZones (authToken) {
  return {
    [CALL_API]: {
      endpoint: 'sitter/utility/coverage_zones',
      headers: {
        'X-Identity-Token': authToken,
        'X-Identity-Key': SECRET_KEY,
      },
      method: 'GET',
      schema: coverageZonesSchema,
      types: [
        PROVIDER_ALL_COVERAGE_ZONES_REQUEST,
        PROVIDER_ALL_COVERAGE_ZONES_SUCCESS,
        PROVIDER_ALL_COVERAGE_ZONES_FAILURE
      ]
    }
  };
}

export function loadCoverageZones (authToken) {
  return {
    [CALL_API]: {
      endpoint: 'sitter/coverage_zones',
      headers: {
        'X-Identity-Token': authToken,
        'X-Identity-Key': SECRET_KEY,
      },
      method: 'GET',
      schema: coverageZonesSchema,
      types: [
        PROVIDER_COVERAGE_ZONES_REQUEST,
        PROVIDER_COVERAGE_ZONES_SUCCESS,
        PROVIDER_COVERAGE_ZONES_FAILURE
      ]
    }
  };
}

export function deleteAvailability (authToken, id, resolve, reject) {
  return {
    [CALL_API]: {
      endpoint: `sitter/availabilities/${id}`,
      headers: {
        'X-Identity-Token': authToken,
        'X-Identity-Key': SECRET_KEY,
      },
      method: 'DELETE',
      resolve,
      reject,
      schema: availabilitiesSchema,
      types: [
        PROVIDER_DELETE_AVAILABILITY_REQUEST,
        PROVIDER_DELETE_AVAILABILITY_SUCCESS,
        PROVIDER_DELETE_AVAILABILITY_FAILURE
      ]
    }
  };
}

export function deleteUnavailability (authToken, id, resolve, reject) {
  return {
    [CALL_API]: {
      endpoint: `sitter/unavailabilities/${id}`,
      headers: {
        'X-Identity-Token': authToken,
        'X-Identity-Key': SECRET_KEY,
      },
      method: 'DELETE',
      resolve,
      reject,
      schema: unavailabilitiesSchema,
      types: [
        PROVIDER_DELETE_AVAILABILITY_REQUEST,
        PROVIDER_DELETE_AVAILABILITY_SUCCESS,
        PROVIDER_DELETE_AVAILABILITY_FAILURE
      ]
    }
  };
}
