export const PROVIDER_SCHEDULE_FAILURE = 'PROVIDER_SCHEDULE_FAILURE';
export const PROVIDER_SCHEDULE_REQUEST = 'PROVIDER_SCHEDULE_REQUEST';
export const PROVIDER_SCHEDULE_SUCCESS = 'PROVIDER_SCHEDULE_SUCCESS';
export const PROFILE_SITTER_FAILURE = 'PROFILE_SITTER_FAILURE';
export const PROFILE_SITTER_REQUEST = 'PROFILE_SITTER_REQUEST';
export const PROFILE_SITTER_SUCCESS = 'PROFILE_SITTER_SUCCESS';
export const PROFILE_SITTER_REVIEWS_FAILURE = 'PROFILE_SITTER_REVIEWS_FAILURE';
export const PROFILE_SITTER_REVIEWS_REQUEST = 'PROFILE_SITTER_REVIEWS_REQUEST';
export const PROFILE_SITTER_REVIEWS_SUCCESS = 'PROFILE_SITTER_REVIEWS_SUCCESS';
export const PROFILE_SITTER_REFERENCES_FAILURE = 'PROFILE_SITTER_REFERENCES_FAILURE';
export const PROFILE_SITTER_REFERENCES_REQUEST = 'PROFILE_SITTER_REFERENCES_REQUEST';
export const PROFILE_SITTER_REFERENCES_SUCCESS = 'PROFILE_SITTER_REFERENCES_SUCCESS';
export const LOAD_FAVORITES_FAILURE = 'LOAD_FAVORITES_FAILURE';
export const LOAD_FAVORITES_REQUEST = 'LOAD_FAVORITES_REQUEST';
export const LOAD_FAVORITES_SUCCESS = 'LOAD_FAVORITES_SUCCESS';
export const PROFILE_SITTER_FAVORITE_REQUEST = 'PROFILE_SITTER_FAVORITE_REQUEST';
export const PROFILE_SITTER_FAVORITE_SUCCESS = 'PROFILE_SITTER_FAVORITE_SUCCESS';
export const PROFILE_SITTER_FAVORITE_FAILURE = 'PROFILE_SITTER_FAVORITE_FAILURE';
export const PROFILE_SITTER_UNFAVORITE_REQUEST = 'PROFILE_SITTER_UNFAVORITE_REQUEST';
export const PROFILE_SITTER_UNFAVORITE_SUCCESS = 'PROFILE_SITTER_UNFAVORITE_SUCCESS';
export const PROFILE_SITTER_UNFAVORITE_FAILURE = 'PROFILE_SITTER_UNFAVORITE_FAILURE';
