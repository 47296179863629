import {createSelector} from 'reselect';
import Cookies from 'js-cookie';

import {
  IN_TOUCH_MODAL_SEEN,
  INTERESTED_SEARCHES_PAGE,
  AVAILABILITY_PAGE,
  ONE_TIME_SEARCHES_PAGE,
  RECURRING_SEARCHES_PAGE,
  ALL_SEARCHES_PAGE
} from 'constants/sitter/navigation';
import {getCopy} from 'selectors/settings';
import {INTRO_MODAL_SEEN} from 'constants/sitter/search';

export const getSearch = state => state.search;

export const getAllSearches = createSelector(
  [getSearch],
  search => search.all
);

export const getOneTimeSearches = createSelector(
  [getSearch],
  search => search.oneTime
);

export const getRecurringSearches = createSelector(
  [getSearch],
  search => search.recurring
);

export const getInterestedSearches = createSelector(
  [getSearch],
  search => search.interested
);

export const getSearches = createSelector(
  [getOneTimeSearches, getRecurringSearches, getInterestedSearches, getAllSearches],
  (oneTime, recurring, interested, all) => ({
    oneTime,
    all,
    recurring,
    interested
  })
);

export const getEmptySearchOptions = () => ({
  all: {
    title: 'No open requests',
    linkTo: `/${AVAILABILITY_PAGE}`,
    linkCopy: 'Add Availability',
    body: 'Come back later or add some availability to your calendar to get booked.'
  },
  oneTime: {
    title: 'No open one-time requests',
    linkTo: `/${AVAILABILITY_PAGE}`,
    linkCopy: 'Add Availability',
    body: 'Come back later or add some availability to your calendar to get booked.'
  },
  recurring: {
    title: 'No open reucrring requests',
    linkTo: `/${ONE_TIME_SEARCHES_PAGE}`,
    linkCopy: 'View one-time requests',
    body: 'Come back later or find an open one-time booking request.'
  },
  interested: {
    title: 'No interested requests',
    linkTo: `/${ONE_TIME_SEARCHES_PAGE}`,
    linkCopy: 'Find an open booking request',
    body: 'All open one-time and recurring booking requests that you mark as \'interested\' will appear here.'
  }
});

export const getHrefBuilder = () => ({
  all: pageIndex => `/${ALL_SEARCHES_PAGE}/${pageIndex}`,
  oneTime: pageIndex => `/${ONE_TIME_SEARCHES_PAGE}/${pageIndex}`,
  recurring: pageIndex => `/${RECURRING_SEARCHES_PAGE}/${pageIndex}`,
  interested: pageIndex => `/${INTERESTED_SEARCHES_PAGE}/${pageIndex}`
});

export const getResultsPerPage = () => 5;

export const calculatePageCount = (resultsPerPage, count) => Math.ceil(count / resultsPerPage);

export const getPageCount = createSelector(
  [getSearch, getResultsPerPage],
  (search, resultsPerPage) => {
    return ({
      all: calculatePageCount(resultsPerPage, search.allCount),
      oneTime: calculatePageCount(resultsPerPage, search.oneTimeCount),
      recurring: calculatePageCount(resultsPerPage, search.recurringCount),
      interested: calculatePageCount(resultsPerPage, search.interestedCount)
    });
  }
);

export const getShowInTouchModal = () => {
  return !Cookies.get(IN_TOUCH_MODAL_SEEN);
};

export const getShowIntroModal = () => {
  return !Cookies.get(INTRO_MODAL_SEEN);
};

export const getSearchesCopy = createSelector(
  [getCopy],
  copy => copy.searches ? copy.searches.guide || '' : ''
);

export const getIntroModalCopy = createSelector(
  [getSearchesCopy],
  copy => copy.intro ? copy.intro.prompt || '' : ''
);

export const getInTouchModalCopy = createSelector(
  [getSearchesCopy],
  copy => copy.after_interested ? copy.after_interested.prompt || '' : ''
);

export const getInterestedCopy = createSelector(
  [getSearchesCopy],
  copy => copy.searches_button || {}
);
