import React from 'react';

import FormField from 'forms/controls/FormField';

export default class PasswordResetFields extends React.Component {
  render () {
    return (
      <div>
        <FormField
            fields={[
              {
                name: 'email',
                placeholder: 'Email',
                type: 'text'
              }
            ]}
            icon="Email"
        />
      </div>
    );
  }
}
