import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {setSize} from 'utils/cloudinaryFormats';

export default class PortraitFrame extends React.Component {
  static propTypes = {
    src: PropTypes.string,
    classes: PropTypes.array
  };

  render () {
    const className = classNames((this.props.classes || []).reduce((p, c) => ({
      ...p,
      [c]: true
    }), {
      'c-portrait-frame': true
    }));
    const style = {
      backgroundImage: `url(${setSize(this.props.src || '', 512, 512)}), url(/portrait-placeholder-512.png)` // refactor, this value should probably dynamic but I can't think of a solution that's responsive
    };
    return (
      <div {...{className, style}} />
    );
  }
}
